// Obesity Research site structural styling

@use '../tools' as *; // Use site-specific tools

.dk-breadcrumb-row {
  margin: 0;
  z-index: 1;

  .dk-breadcrumbs {
    margin: 0.5rem 0;

    li {
        --page-breadcrumb-separator: ' » ';
        font-family: $droid-sans;
    }
  }

  @include desktop {
    & + .dk-main-container {
      margin-top: 0;
    }
  }
}

.banner-cta .button-ghost::after {
  padding-left: 0;
}

@include print {
  .dk-main-container .dk-content {
    padding: 0;

    .dk-pagination form > span.current {
      color: $blue;
    }
  }

  .button-ghost {
    display: none;
  }
}

// Hide NIDDK-only content if it is shared in the Obesity Research site
.ws-obesity.ws-obesity.ws-obesity .ws-niddk-only {
  display: none;
}
