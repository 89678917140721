@use 'sass:list';
@use 'sass:map';
@forward '../../tools/variables' with (
  // Obesity Research site-specific variables
  $bg-outer-body:     #dbdad3,
  $color-themes: (
    blue:     (#1568af, #336b9c, #0a1c2b, 0.85),
    green:    (#5f8038, #5d8339, #181e12, 0.85)
  )
);
@forward '../../tools/functions';
@forward '../../tools/mixins';
@forward '../../tools/utility';
@forward '../../tools/elements';
@forward '../../tools/images-figures';
@forward '../../tools/forms';
@forward '../../tools/anchor-margin';
@forward '../../tools/themable-pages';
@forward '../../tools/accent-colors';
@forward '../../tools/icons';

// Additional variables
$bg-nih-blue:       #20558a;
$link-secondary:    #666;
$droid-sans:     "Droid Sans", "Droid Sans-local", "Droid Sans-fallback", "Trebuchet MS", Arial, Helvetica, sans-serif;
