@use './tools' as *;

// Add accent color classes;
@include enable-accent-color-classes;

// Ensure that page theming is enabled
@include enable-themable-pages;

main {
  display: block;
  font-family: $font-serif;
  color: $text-general;

  @include enable-body-elements;
  @include enable-images-figures;
}

@include enable-icons;
@include enable-utility-classes;
