@use '../tools' as *; // Use site-specific tools
@use '../../../common/structure/site-nav' as *;

.dk-site-nav {
  @include desktop {
    @include dk-site-nav-desktop($site-nav, $site-nav-current);
    @include dk-site-nav-desktop-sticky;

    &.dk-site-nav {
      background: $white;

      .sn-sections, .sn-section {
        transition: none;
      }
    }

    .sn-section-title > a {
      margin-top: 0;
      font-family: $droid-sans;
      font-size: rem(15.84);
      line-height: rem(40);
    }

    &:not(.stuck) {
      .sn-bar {
        width: 100%;
        transform: none;
      }

      .sn-sections {
        border-spacing: 0;
        border-collapse: separate;
        background: transparent;
      }

      .sn-section {
        background: #00518f linear-gradient(#2f78c0, #0f4071 70%);
        border-left: 1px solid #577ca2;
        border-right: 1px solid #122d4a;

        &:first-child {
          border-left: 0;

          &, .sn-section-title > a {
            border-top-left-radius: rem(7);
          }
        }

        &:last-child {
          border-right: 0;

          &, .sn-section-title > a {
            border-top-right-radius: rem(7);
          }
        }

        &.focused, &.current {
          background: #00518f linear-gradient(#002c5b, #003972 70%);
        }

        &.current .sn-section-title > a::after {
          content: normal;
        }

        &.focused, &:hover {
          .sn-section-title > a {
            color: $white;
            background-color: transparent;
          }
        }
      }

      .sn-section-title > a {
        &:hover {
          color: $white;
          background: #00518f linear-gradient(#002c5b, #003972 70%);
        }

        span {
          font-size: inherit;
        }
      }
    }

    // Sticky header overrides

    &:not(.no-transition) {
      &.stuck .sn-home {
        transition: width 400ms ease-out;
      }
    }

    .sn-home a {
      width: rem(150) - 0.5rem;
      margin: 0 0.5rem 0 0;

      &.dk-logo {
        background: url(../images/obesity-logo-abbrev.svg) no-repeat 0 50%;
      }
    }

    &.stuck ~ .balloon,
    .sn-home {
      height: rem(40);
    }

    &.stuck {
      .sn-home {
        width: rem(190);
        min-width: 0;
      }

      .sn-section.current .sn-section-title {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: 41;
          border-top: 3px solid $bg-nih-blue;
        }
      }

      .sn-section-title > a:hover span {
        text-decoration: underline;
      }
    }

    .sn-home a.dk-logo {
      display: block;
      position: relative;
      width: calc(100% - 10px);
      height: rem(40);
      margin-right: 10px;
      background-image: none;
      overflow: hidden;

      &::before {
        content: normal;
      }

      .sn-logo-wrap {
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: rem(150);
        height: rem(32.44);
        padding: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @include tablet {
    .sn-section-title > a span {
      vertical-align: baseline;
    }
  }

  @include mobile {
    @include dk-site-nav-mobile;

    .sn-home .sn-logo-wrap {
      display: none;
    }
  }

  @include print { display: none; }
}
