@charset "UTF-8";
.usa-banner__inner .usa-banner__header-action::after, .usa-banner__inner button::after, .dk-tabs .scroll_tabs_container div:is(.scroll_tab_left_button, .scroll_tab_right_button)::before, .tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance a.tablesaw-nav-btn::before, .dk-expandable .expandable-toggle::after, .result-items.staff :is(.email, .phone) a::before, .result-items.meetings .date::before, .result-items.meetings .location::before, .result-items.meetings .webinar::before, .previous-next > * > div::before, a.email::before, a.phone::before, .social-contact-links > a.email::before, .social-contact-links > a.phone::before, .social-contact-links > a.add-contact::before, .social-contact-links > a.linkedin::before, .social-contact-links > a.researchgate::before, .social-contact-links > a.google-scholar::before, .social-contact-links > a.orcid::before, .collapse-list li input[type=checkbox] + label::before, .alert.info:not(.no-icon)::before, .alert.warning:not(.no-icon)::before, .alert.error:not(.no-icon)::before, .alert.success:not(.no-icon)::before, .fs-return-link a::before, .expanding-alert.alert-icon::before, .expanding-alert .expand-above::before, .expanding-alert .expand-above::after, #contact .follow-us a.twitter::before, #contact .follow-us a.facebook::before, #contact .follow-us a.youtube::before, #contact .follow-us a.instagram::before, #contact .follow-us a.linkedin::before, #contact .follow-us a.x-social::before, .site-header .dk-site-search input[type=text] + button::before,
.sr-refine .dk-site-search input[type=text] + button::before, :is([class^=i-], [class*=" i-"], [data-icon])::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 0.0714285714rem;
}

.action-link a:not(.no-arrow)::after, .action-link.end-arrow::after {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e000";
  position: relative;
  margin-left: 0.2142857143rem;
}

.action-link::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e00a";
  padding-left: 0.5em;
  font-size: 1.2em;
}

.angle-right::after, .button-ghost::after {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e006";
  padding-left: 0.5em;
  font-size: 1.2em;
  vertical-align: middle;
}

.dk-tabs.dk-scrollable, .dk-tabs .scroll_tabs_container div.scroll_tab_inner span:not([class*=_finisher]), .dk-tabs .dk-tabs-content > div, .dk-share-buttons > *, .accordion, .accordion > h2:first-child, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]), .accordion > h3:first-child, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]), .accordion > h4:first-child, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]), .accordion > h5:first-child, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]), .accordion > h6:first-child, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]), .dk-accdn .acc-main, .dk-accdn .acc-title, blockquote:where(:not(.roundbox)), blockquote.center:where(:not(.roundbox)), q:is(.left, .right, .center), q.center:is(.left, .right, .center), .promo-banner, .promo-hero, .dk-pagination .dk-pages .current, .dk-box.border-thin, .dk-box.border-thin-accent, a:hover.detailed-download .detail::before, a:hover .detailed-download .detail::before, .thin.box, .box, .boxes, .boxes > .thin, main hr, .thin-box {
  border-color: #5f8038;
  border-color: var(--accent-border-color, #5f8038);
}

.dk-share-buttons > :hover, .dk-share-buttons.expanded .shr-more, .steps-list > *::before, .promo-banner, .promo-hero, .dk-pagination .dk-pages .current, .dk-box.border-thick[class*=accent-] h2, a:hover.detailed-download .icon, a:hover .detailed-download .icon, [class*=accent-].thick.box :is(h1, h2, h3), [class*=accent-].filled.box, .boxes > [class*=accent-].thick :is(h1, h2, h3), .boxes > [class*=accent-].filled, .box-filled {
  background-color: #5f8038;
  background-color: var(--accent-bg-color, #5f8038);
}

.dk-share-buttons > *, .dk-share-buttons > :visited, .dk-accdn .acc-title button::after, .stack-icon::before, .stack-icon.blank::after, .previous-next > * > div::before {
  color: #5f8038;
  color: var(--accent-fg-color, #5f8038);
}

.accent-green {
  --accent-fg-color: #5f8038;
  --accent-bg-color: #5f8038;
  --accent-border-color: #5f8038;
  --accent-bg-shade-color: rgb(80.75, 108.8, 47.6);
  --accent-border-shade-color: rgb(80.75, 108.8, 47.6);
  --accent-alpha30-color: rgba(95, 128, 56, 0.3);
}

.accent-indigo {
  --accent-fg-color: #24325f;
  --accent-bg-color: #24325f;
  --accent-border-color: #24325f;
  --accent-bg-shade-color: rgb(30.6, 42.5, 80.75);
  --accent-border-shade-color: rgb(30.6, 42.5, 80.75);
  --accent-alpha30-color: rgba(36, 50, 95, 0.3);
}

.accent-blue {
  --accent-fg-color: #1568af;
  --accent-bg-color: #1568af;
  --accent-border-color: #1568af;
  --accent-bg-shade-color: rgb(17.85, 88.4, 148.75);
  --accent-border-shade-color: rgb(17.85, 88.4, 148.75);
  --accent-alpha30-color: rgba(21, 104, 175, 0.3);
}

.accent-orange {
  --accent-fg-color: #a76903;
  --accent-bg-color: #a76903;
  --accent-border-color: #a76903;
  --accent-bg-shade-color: rgb(141.95, 89.25, 2.55);
  --accent-border-shade-color: rgb(141.95, 89.25, 2.55);
  --accent-alpha30-color: rgba(167, 105, 3, 0.3);
}

.accent-gray {
  --accent-fg-color: #757575;
  --accent-bg-color: #757575;
  --accent-border-color: #808080;
  --accent-bg-shade-color: rgb(99.45, 99.45, 99.45);
  --accent-border-shade-color: rgb(108.8, 108.8, 108.8);
  --accent-alpha30-color: rgba(117, 117, 117, 0.3);
}

.accent-teal {
  --accent-fg-color: #307682;
  --accent-bg-color: #45a9ba;
  --accent-border-color: #45a9ba;
  --accent-bg-shade-color: rgb(58.65, 143.65, 158.1);
  --accent-border-shade-color: rgb(58.65, 143.65, 158.1);
  --accent-alpha30-color: rgba(69, 169, 186, 0.3);
}

.accent-mauve {
  --accent-fg-color: #a05972;
  --accent-bg-color: #bd899b;
  --accent-border-color: #bd899b;
  --accent-bg-shade-color: rgb(160.65, 116.45, 131.75);
  --accent-border-shade-color: rgb(160.65, 116.45, 131.75);
  --accent-alpha30-color: rgba(189, 137, 155, 0.3);
}

.accent-lavender {
  --accent-fg-color: #444b62;
  --accent-bg-color: #636e8f;
  --accent-border-color: #636e8f;
  --accent-bg-shade-color: rgb(84.15, 93.5, 121.55);
  --accent-border-shade-color: rgb(84.15, 93.5, 121.55);
  --accent-alpha30-color: rgba(99, 110, 143, 0.3);
}

.accent-teal-dark {
  --accent-fg-color: #307682;
  --accent-bg-color: #307682;
  --accent-border-color: #307682;
  --accent-bg-shade-color: rgb(40.8, 100.3, 110.5);
  --accent-border-shade-color: rgb(40.8, 100.3, 110.5);
  --accent-alpha30-color: rgba(48, 118, 130, 0.3);
}

.accent-mauve-dark {
  --accent-fg-color: #a05972;
  --accent-bg-color: #a05972;
  --accent-border-color: #a05972;
  --accent-bg-shade-color: rgb(136, 75.65, 96.9);
  --accent-border-shade-color: rgb(136, 75.65, 96.9);
  --accent-alpha30-color: rgba(160, 89, 114, 0.3);
}

.accent-lavender-dark {
  --accent-fg-color: #444b62;
  --accent-bg-color: #444b62;
  --accent-border-color: #444b62;
  --accent-bg-shade-color: rgb(57.8, 63.75, 83.3);
  --accent-border-shade-color: rgb(57.8, 63.75, 83.3);
  --accent-alpha30-color: rgba(68, 75, 98, 0.3);
}

.accent-cyan {
  --accent-fg-color: #547988;
  --accent-bg-color: #547988;
  --accent-border-color: #547988;
  --accent-bg-shade-color: rgb(71.4, 102.85, 115.6);
  --accent-border-shade-color: rgb(71.4, 102.85, 115.6);
  --accent-alpha30-color: rgba(84, 121, 136, 0.3);
}

.accent-dirblue {
  --accent-fg-color: #0072bc;
  --accent-bg-color: #0072bc;
  --accent-border-color: #0072bc;
  --accent-bg-shade-color: rgb(0, 96.9, 159.8);
  --accent-border-shade-color: rgb(0, 96.9, 159.8);
  --accent-alpha30-color: rgba(0, 114, 188, 0.3);
}

.accent-sea {
  --accent-fg-color: #55b9ad;
  --accent-bg-color: #55b9ad;
  --accent-border-color: #55b9ad;
  --accent-bg-shade-color: rgb(72.25, 157.25, 147.05);
  --accent-border-shade-color: rgb(72.25, 157.25, 147.05);
  --accent-alpha30-color: rgba(85, 185, 173, 0.3);
}

.accent-bondi {
  --accent-fg-color: #03a2c2;
  --accent-bg-color: #03a2c2;
  --accent-border-color: #03a2c2;
  --accent-bg-shade-color: rgb(2.55, 137.7, 164.9);
  --accent-border-shade-color: rgb(2.55, 137.7, 164.9);
  --accent-alpha30-color: rgba(3, 162, 194, 0.3);
}

.accent-orchid {
  --accent-fg-color: #6f7da4;
  --accent-bg-color: #6f7da4;
  --accent-border-color: #6f7da4;
  --accent-bg-shade-color: rgb(94.35, 106.25, 139.4);
  --accent-border-shade-color: rgb(94.35, 106.25, 139.4);
  --accent-alpha30-color: rgba(111, 125, 164, 0.3);
}

.accent-gold {
  --accent-fg-color: #e19a0f;
  --accent-bg-color: #e19a0f;
  --accent-border-color: #e19a0f;
  --accent-bg-shade-color: rgb(191.25, 130.9, 12.75);
  --accent-border-shade-color: rgb(191.25, 130.9, 12.75);
  --accent-alpha30-color: rgba(225, 154, 15, 0.3);
}

.accent-wine {
  --accent-fg-color: #9e6c7d;
  --accent-bg-color: #9e6c7d;
  --accent-border-color: #9e6c7d;
  --accent-bg-shade-color: rgb(134.3, 91.8, 106.25);
  --accent-border-shade-color: rgb(134.3, 91.8, 106.25);
  --accent-alpha30-color: rgba(158, 108, 125, 0.3);
}

.grid {
  max-width: 73.2142857143rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.1428571429rem;
}
.grid::after {
  content: "";
  display: block;
  clear: both;
}
@media screen and (max-width: 649px) {
  .grid {
    padding: 0 0rem;
  }
}

.result-items.staff tr::after, .box::after, .boxes > ::after, .dk-facet-search .fs-link-facets .tic-tacs::after, .clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.steps-list > *::before, .dk-pagination .dk-pages, .collapse-list li input[type=checkbox] + label, .dk-facet-search .fs-link-facets > a, .dk-facet-search .fs-link-facets span, .dk-facet-search .fs-link-facets .tic-tacs li a, .unselectable {
  -webkit-user-select: none;
  user-select: none;
}

.result-items.staff .role p:first-child, .result-items.research-materials div > p:first-child, :is(ul, ol).no-bullets li:first-child, :is(ul, ol).no-bullets-shallow > li:first-child, :is(ul, ol).loose li:first-child, :is(ul, ol).loose-shallow > li:first-child, .site-alert :first-child, .no-bookends:first-child, main :is(ul, ol) li:first-child, main :is(ul, ol) li > p:first-child, .dk-tabs .dk-tabs-content > div > :first-child, table caption > :first-child, .mfp-content > * > :first-child, .dk-expandable .expandable > :first-child, .dk-accdn .acc-content > :first-child, .promo-banner > .text > * > :first-child, .promo-hero > .text > * > :first-child, .left-image > :first-child, .left-image * > :first-child, .dk-box .dk-box-content > :first-child, .detailed-download .detail > :first-child, .box > :first-child, .box > .body > :first-child, .boxes > * > :first-child, .boxes > * > .body > :first-child, .alert > :first-child, .hero-unit .inner > :first-child, .hero-unit .inner * > :first-child, .no-child-bookends > :first-child, .mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit) > :first-child, main :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit) > :first-child, .thin-box > :first-child {
  margin-top: 0;
}
.result-items.staff .role p:last-child, .result-items.research-materials div > p:last-child, :is(ul, ol).no-bullets li:last-child, :is(ul, ol).no-bullets-shallow > li:last-child, :is(ul, ol).loose li:last-child, :is(ul, ol).loose-shallow > li:last-child, .site-alert :last-child, .no-bookends:last-child, main :is(ul, ol) li:last-child, main :is(ul, ol) li > p:last-child, .dk-tabs .dk-tabs-content > div > :last-child, table caption > :last-child, .mfp-content > * > :last-child, .dk-expandable .expandable > :last-child, .dk-accdn .acc-content > :last-child, .promo-banner > .text > * > :last-child, .promo-hero > .text > * > :last-child, .left-image > :last-child, .left-image * > :last-child, .dk-box .dk-box-content > :last-child, .detailed-download .detail > :last-child, .box > :last-child, .box > .body > :last-child, .boxes > * > :last-child, .boxes > * > .body > :last-child, .alert > :last-child, .hero-unit .inner > :last-child, .hero-unit .inner * > :last-child, .no-child-bookends > :last-child, .mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit) > :last-child, main :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit) > :last-child, .thin-box > :last-child {
  margin-bottom: 0;
}

.clear {
  clear: both;
}

.mfp-content a.print::before, .result-items.staff :is(.email, .phone) a::before, a.email::before, a.phone::before, .social-contact-links > a.email::before, .social-contact-links > a.phone::before, .social-contact-links > a.add-contact::before, .social-contact-links > a.linkedin::before, .social-contact-links > a.researchgate::before, .social-contact-links > a.google-scholar::before, .social-contact-links > a.orcid::before, :is(.cta, .dk-cta):where(a):is(:hover, :active)::after, .action-link a:not(.no-arrow)::after, .action-link.end-arrow::after, .dk-box.fg-white :is(h2, .dk-box-content) a:is(.cta, .dk-cta, .button, .button-ghost)::after, .fs-return-link a::before {
  text-decoration: underline;
}
body .mfp-content a.print::before, .mfp-content body a.print::before, body .result-items.staff :is(.email, .phone) a::before, .result-items.staff :is(.email, .phone) body a::before, body a.email::before, body a.phone::before, body .social-contact-links > a.email::before, body .social-contact-links > a.phone::before, body .social-contact-links > a.add-contact::before, body .social-contact-links > a.linkedin::before, body .social-contact-links > a.researchgate::before, body .social-contact-links > a.google-scholar::before, body .social-contact-links > a.orcid::before, body :is(.cta, .dk-cta):where(a):is(:hover, :active)::after, body .action-link a:not(.no-arrow)::after, .action-link body a:not(.no-arrow)::after, body .action-link.end-arrow::after, body .dk-box.fg-white :is(h2, .dk-box-content) a:is(.cta, .dk-cta, .button, .button-ghost)::after, .dk-box.fg-white :is(h2, .dk-box-content) body a:is(.cta, .dk-cta, .button, .button-ghost)::after, body .fs-return-link a::before, .fs-return-link body a::before {
  text-decoration: none;
}

.show {
  display: initial;
  visibility: visible;
}

.hide {
  display: none;
  visibility: hidden;
}

.sr-only, svg.svg-defn {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.text-centered {
  text-align: center;
}

.strong {
  font-weight: 700;
}

.semibold {
  font-weight: 600;
}

.unbold {
  font-weight: 400;
}

.text-dark {
  color: #333;
}

.sans-serif {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}

.text-small, .text-small p {
  font-size: 0.8928571429rem;
}

.word-wrap {
  overflow-wrap: break-word;
}

.float-left {
  display: block;
  float: left;
}

.float-right {
  display: block;
  float: right;
}

.align-center {
  text-align: center;
}

.thin-box {
  border-width: 1px;
  border-style: solid;
  margin: 1rem 0;
  padding: 1rem;
}
.fg-white.thin-box, .fg-white.thin-box a:is(:link, :visited), .fg-white.thin-box a::after {
  color: #fff;
}

@media screen and (max-width: 649px) {
  .mobile-swap > :not([class^=swap-]):not([class*=" swap-"]):first-child, .mobile-swap .swap-full {
    display: none;
  }
}

@media screen and (min-width: 650px) {
  .mobile-swap > :not([class^=swap-]):not([class*=" swap-"]):last-child, .mobile-swap .swap-mobile {
    display: none;
  }
}

#contact h2 {
  color: #333;
  font: normal 1.5em/2.5rem "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
}

.centered.ratio > *, .centered.youtube-container > * {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  transform: translateX(-50%) translateY(-50%);
}

.ratio, .youtube-container {
  display: block;
  position: relative;
}
.fit-horizontal.fit-horizontal.ratio > *, .fit-horizontal.fit-horizontal.youtube-container > * {
  max-width: 100%;
  max-height: none;
}
.fit-vertical.fit-vertical.ratio > *, .fit-vertical.fit-vertical.youtube-container > * {
  max-width: none;
  max-height: 100%;
}
.fit.fit.ratio > *, .fit.fit.youtube-container > * {
  max-width: 100%;
  max-height: 100%;
}
.ratio iframe, .youtube-container iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio .ratio, .youtube-container .ratio {
  width: 100%;
}
.ratio img, .youtube-container img {
  width: auto;
  height: auto;
}
.bg-black.ratio, .bg-black.youtube-container {
  background-color: #000;
}

.dk-tag {
  display: inline-block;
  border-radius: 0.2142857143rem;
  min-width: 5rem;
  color: #fff;
  font-size: 0.8571428571rem;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  text-align: center;
}

.dk-main-container .dk-content .dk-review-date, main :is(h1, h2, h3, h4, h5, h6), main :is(.heading1, .heading2, .heading3, .heading4, .heading5, .heading6), main p, main :is(ul, ol), dl.term-definition dd {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

main h1, main .heading1 {
  font-size: 2.1428571429rem;
}
@media screen and (max-width: 649px) {
  main h1, main .heading1 {
    font-size: 2rem;
  }
}

dl.term-definition dt, .box-filled :is(h1, h2, h3), main h2, main .heading2 {
  font-size: 1.8571428571rem;
}

.dk-box h2, .thick.box :is(h1, h2, h3), .boxes > .thick :is(h1, h2, h3), main .sr-results h2, main h3, main .heading3, .intro-text {
  font-size: 1.5rem;
}

.thin.box :is(h1, h2, h3), .bg-gray.box :is(h1, h2, h3), .boxes > .thin :is(h1, h2, h3), .boxes > .bg-gray :is(h1, h2, h3), main h4, main .heading4 {
  font-size: 1.2857142857rem;
}

main h5, main .heading5 {
  font-size: 1.0714285714rem;
}

main h6, main .heading6 {
  font-size: 1rem;
}

main :is(h1, h2, h3, h4, h5, h6), main :is(.heading1, .heading2, .heading3, .heading4, .heading5, .heading6) {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.3;
}

.intro-text {
  margin: 1rem 0;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  font-weight: bold;
  line-height: 1.3;
  color: #575757;
}

.dk-main-container .dk-content .dk-review-date, main p, dl.term-definition dd {
  font-size: 1rem;
  line-height: 1.8;
}

ul.thumbnail-list > li ul, ul.stack-icon-blocks > li ul, ul > .stack-icon-block > li ul, ol.thumbnail-list > li ul, ol.stack-icon-blocks > li ul, ol > .stack-icon-block > li ul, .steps-list > li ul {
  list-style-type: disc;
}

ul.thumbnail-list > li :is(ul, ol) ul, ul.stack-icon-blocks > li :is(ul, ol) ul, ul > .stack-icon-block > li :is(ul, ol) ul, ol.thumbnail-list > li :is(ul, ol) ul, ol.stack-icon-blocks > li :is(ul, ol) ul, ol > .stack-icon-block > li :is(ul, ol) ul, .steps-list > li :is(ul, ol) ul {
  list-style-type: circle;
}

ul.thumbnail-list > li :is(ul, ol) :is(ul, ol) ul, ul.stack-icon-blocks > li :is(ul, ol) :is(ul, ol) ul, ul > .stack-icon-block > li :is(ul, ol) :is(ul, ol) ul, ol.thumbnail-list > li :is(ul, ol) :is(ul, ol) ul, ol.stack-icon-blocks > li :is(ul, ol) :is(ul, ol) ul, ol > .stack-icon-block > li :is(ul, ol) :is(ul, ol) ul, .steps-list > li :is(ul, ol) :is(ul, ol) ul {
  list-style-type: square;
}

ul.thumbnail-list > li ol, ul.stack-icon-blocks > li ol, ul > .stack-icon-block > li ol, ol.thumbnail-list > li ol, ol.stack-icon-blocks > li ol, ol > .stack-icon-block > li ol, .steps-list > li ol {
  list-style-type: decimal;
}

ul.thumbnail-list > li :is(ul, ol) ol, ul.stack-icon-blocks > li :is(ul, ol) ol, ul > .stack-icon-block > li :is(ul, ol) ol, ol.thumbnail-list > li :is(ul, ol) ol, ol.stack-icon-blocks > li :is(ul, ol) ol, ol > .stack-icon-block > li :is(ul, ol) ol, .steps-list > li :is(ul, ol) ol, main :is(ul, ol) ol {
  list-style-type: lower-alpha;
}

ul.thumbnail-list > li :is(ul, ol) :is(ul, ol) ol, ul.stack-icon-blocks > li :is(ul, ol) :is(ul, ol) ol, ul > .stack-icon-block > li :is(ul, ol) :is(ul, ol) ol, ol.thumbnail-list > li :is(ul, ol) :is(ul, ol) ol, ol.stack-icon-blocks > li :is(ul, ol) :is(ul, ol) ol, ol > .stack-icon-block > li :is(ul, ol) :is(ul, ol) ol, .steps-list > li :is(ul, ol) :is(ul, ol) ol, main :is(ul, ol) ol ol {
  list-style-type: lower-roman;
}

main :is(ul, ol) {
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
}
main :is(ul, ol) li {
  list-style-position: outside;
}
main :is(ul, ol) li, main :is(ul, ol) li > :is(ul, ol) {
  margin: 0.5em 0 0.5em 1.3em;
}
@media screen and (min-width: 650px) {
  main :is(ul, ol) li, main :is(ul, ol) li > :is(ul, ol) {
    margin-block: 0.3em;
  }
}
main :is(ul, ol) li > p {
  margin: 0.3em 0;
  line-height: inherit;
}
main a.unvisited, main a.unvisited:visited, main a {
  text-decoration: none;
  color: #0072bc;
}

main a.visited, main a:visited {
  color: #c35522;
}

main a.unvisited:hover, main a:hover, main a:active {
  color: #004b8d;
  text-decoration: underline;
}

main a.url-ellipsis {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
main a.url-break {
  word-wrap: break-word;
  word-break: break-all;
}

a.button {
  display: inline-block;
  font-size: 1rem;
  margin: 0.8rem 0;
  color: #fff;
  text-decoration: none;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  background-color: #1568af;
  padding: 0.5rem 2rem;
  border: 1px solid #1568af;
  border-radius: 0.2142857143rem;
}
a.button:visited {
  color: #fff;
}
a.button:hover {
  border-color: #24325f;
  background-color: #24325f;
  color: #fff;
  text-decoration: none;
}
a.angle-right.button {
  position: relative;
  padding-right: 2.5rem;
}
a.angle-right.button:after {
  position: absolute;
  top: calc(50% - 0.6rem);
  right: 1.5rem;
  margin: 0;
}
a.white-fill.button {
  color: #1568af;
  background: #fff;
  border: 1px solid #1568af;
}
a.white-fill.button:hover, a.white-fill.active.button {
  background: rgba(21, 104, 175, 0.1);
}

.button-ghost {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1;
  background: rgba(0, 0, 0, 0.15);
  padding: 1rem 3rem;
  border: 1px solid #fff;
  border-radius: 0.2142857143rem;
}
.narrow-pad.button-ghost {
  padding: 1rem;
}
.button-ghost:visited {
  color: #fff;
  text-decoration: none;
}
.button-ghost:hover, .button-ghost:active {
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  text-decoration: none;
}
.button-ghost::after {
  margin-top: -0.1428571429rem;
}

.button-row {
  margin: 1rem 0;
}
.button-row a.button, .button-row .button-ghost {
  margin: 0.5rem 1rem 0.5rem 0;
}
.button-row a.button:last-child, .button-row .button-ghost:last-child {
  margin-right: 0;
}

.mfp-content img, main img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border: 0;
}
.mfp-content img.center, main img.center {
  display: block;
}

.mfp-content :is(.left, .right, .center):is(img, figure, :where(.figure)), main :is(.left, .right, .center):is(img, figure, :where(.figure)) {
  margin: 1rem auto;
}
@media screen and (min-width: 650px) {
  .mfp-content :is(img, figure, :where(.figure)), main :is(img, figure, :where(.figure)) {
    float: none;
    clear: both;
  }
  .mfp-content .xsmall:is(img, figure, :where(.figure)), main .xsmall:is(img, figure, :where(.figure)) {
    max-width: 25%;
  }
  .mfp-content .small:is(img, figure, :where(.figure)), main .small:is(img, figure, :where(.figure)) {
    max-width: 50%;
  }
  .mfp-content .medium:is(img, figure, :where(.figure)), main .medium:is(img, figure, :where(.figure)) {
    max-width: 75%;
  }
  .mfp-content .large:is(img, figure, :where(.figure)), main .large:is(img, figure, :where(.figure)) {
    max-width: 100%;
  }
  .mfp-content :is(.left, .right):is(img, figure, :where(.figure)), main :is(.left, .right):is(img, figure, :where(.figure)) {
    max-width: 80%;
  }
  .mfp-content :is(.left, .right).xsmall:is(img, figure, :where(.figure)), main :is(.left, .right).xsmall:is(img, figure, :where(.figure)) {
    max-width: 10%;
  }
  .mfp-content :is(.left, .right).small:is(img, figure, :where(.figure)), main :is(.left, .right).small:is(img, figure, :where(.figure)) {
    max-width: 25%;
  }
  .mfp-content :is(.left, .right).medium:is(img, figure, :where(.figure)), main :is(.left, .right).medium:is(img, figure, :where(.figure)) {
    max-width: 40%;
  }
  .mfp-content :is(.left, .right).large:is(img, figure, :where(.figure)), main :is(.left, .right).large:is(img, figure, :where(.figure)) {
    max-width: 75%;
  }
  .mfp-content .left:is(img, figure, :where(.figure)), main .left:is(img, figure, :where(.figure)) {
    float: left;
    clear: left;
    margin: 0.5rem 1rem 0.5rem 0;
  }
  .mfp-content .right:is(img, figure, :where(.figure)), main .right:is(img, figure, :where(.figure)) {
    float: right;
    clear: right;
    margin: 0.5rem 0 0.5rem 1rem;
  }
}

.mfp-content :is(figure, :where(.figure)), main :is(figure, :where(.figure)) {
  display: table;
  position: relative;
  max-width: 100%;
  margin: 1rem 0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  text-align: center;
}
.mfp-content .fill:is(figure, :where(.figure)), main .fill:is(figure, :where(.figure)) {
  width: 100%;
}
.mfp-content .fill:is(figure, :where(.figure)) img.fill, main .fill:is(figure, :where(.figure)) img.fill {
  width: 100%;
}
.mfp-content .no-margin:is(figure, :where(.figure)), main .no-margin:is(figure, :where(.figure)) {
  margin: 0;
}
.mfp-content :is(figure, :where(.figure)) img, main :is(figure, :where(.figure)) img {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.mfp-content :is(figure, :where(.figure)) a.expand, main :is(figure, :where(.figure)) a.expand {
  display: block;
  position: absolute;
  top: 0.3571428571rem;
  right: 0.3571428571rem;
  width: 3.2142857143rem;
  height: 3.2142857143rem;
  border-radius: 0.3571428571rem;
  background-color: rgba(95, 128, 56, 0.3);
  background-color: var(--accent-alpha30-color, rgba(95, 128, 56, 0.3));
  color: #000;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  transition: background-color linear 150ms;
  font-size: 0;
}
.mfp-content :is(figure, :where(.figure)) a.expand::before, main :is(figure, :where(.figure)) a.expand::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e00e";
  font-size: 1.7142857143rem;
  line-height: 1.875;
  color: #000;
  transition: color linear 150ms;
}
.mfp-content :is(figure, :where(.figure)) a.expand > *, main :is(figure, :where(.figure)) a.expand > * {
  display: none;
}
.mfp-content :hover:is(figure, :where(.figure)) a.expand, main :hover:is(figure, :where(.figure)) a.expand, .mfp-content :is(figure, :where(.figure)) a.expand:focus, main :is(figure, :where(.figure)) a.expand:focus {
  background-color: #5f8038;
  background-color: var(--accent-bg-color, #5f8038);
  transition: background-color linear 100ms;
  text-shadow: none;
}
.mfp-content :hover:is(figure, :where(.figure)) a.expand::before, main :hover:is(figure, :where(.figure)) a.expand::before, .mfp-content :is(figure, :where(.figure)) a.expand:focus::before, main :is(figure, :where(.figure)) a.expand:focus::before {
  color: #fff;
  transition: color linear 100ms;
}
.mfp-content :is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit), main :is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit) {
  display: table-caption;
  caption-side: bottom;
  min-width: 10.7142857143rem;
  padding: 0.5rem;
  background-color: #f2f2f2;
}
.mfp-content :is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit).white, main :is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit).white {
  background-color: #fff;
}
.mfp-content :is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit).white:not(.pad), main :is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit).white:not(.pad) {
  padding-left: 0;
  padding-right: 0;
}
.mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit), main :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit) {
  text-align: left;
}
.mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit), main :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit), .mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit) > *, main :is(figure, :where(.figure)) :is(figcaption, .figcaption, .caption, .credit) > * {
  line-height: 1.4;
}
.mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption) :is(.caption, .credit), main :is(figure, :where(.figure)) :is(figcaption, .figcaption) :is(.caption, .credit) {
  display: block;
}
.mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption) :is(.caption, .credit).hide, main :is(figure, :where(.figure)) :is(figcaption, .figcaption) :is(.caption, .credit).hide {
  display: none;
}
.mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption) .credit, main :is(figure, :where(.figure)) :is(figcaption, .figcaption) .credit {
  margin-top: -0.25rem;
}
.mfp-content :is(figure, :where(.figure)) :is(figcaption, .figcaption) .credit:not(.hide) + .caption, main :is(figure, :where(.figure)) :is(figcaption, .figcaption) .credit:not(.hide) + .caption {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: solid 1px #c1c2c2;
}
.mfp-content :is(figure, :where(.figure)) .credit, main :is(figure, :where(.figure)) .credit {
  font-size: 10.5px;
}
.mfp-content :is(figure, :where(.figure)) > .credit, main :is(figure, :where(.figure)) > .credit {
  padding-top: 0.25rem;
}
.mfp-content :is(figure, :where(.figure)) > .credit.white + :is(figcaption, .figcaption, .caption), main :is(figure, :where(.figure)) > .credit.white + :is(figcaption, .figcaption, .caption) {
  background-color: #fff;
  padding-top: 0;
}
.mfp-content :is(figure, :where(.figure)) > .credit.white:not(.pad) + :is(figcaption, .figcaption, .caption), main :is(figure, :where(.figure)) > .credit.white:not(.pad) + :is(figcaption, .figcaption, .caption) {
  padding-left: 0;
  padding-right: 0;
}
.mfp-content :is(figure, :where(.figure)) > .credit:not(.white) + :is(figcaption, .figcaption, .caption):not(.white), main :is(figure, :where(.figure)) > .credit:not(.white) + :is(figcaption, .figcaption, .caption):not(.white) {
  position: relative;
}
.mfp-content :is(figure, :where(.figure)) > .credit:not(.white) + :is(figcaption, .figcaption, .caption):not(.white)::before, main :is(figure, :where(.figure)) > .credit:not(.white) + :is(figcaption, .figcaption, .caption):not(.white)::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0.5rem;
  right: 0.5rem;
  border-top: solid 1px #c1c2c2;
}
.mfp-content .youtube-figure:is(figure, :where(.figure)), main .youtube-figure:is(figure, :where(.figure)), .mfp-content .youtube-figure:is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit), main .youtube-figure:is(figure, :where(.figure)) > :is(figcaption, .figcaption, .caption, .credit) {
  display: block;
}
.mfp-content .youtube-figure:is(figure, :where(.figure)) > .expand, main .youtube-figure:is(figure, :where(.figure)) > .expand {
  display: none;
}
.mfp-content .youtube-figure:is(figure, :where(.figure)) .youtube-container, main .youtube-figure:is(figure, :where(.figure)) .youtube-container {
  width: 100%;
}
.mfp-content .youtube-figure:is(figure, :where(.figure)) iframe, main .youtube-figure:is(figure, :where(.figure)) iframe {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

[id],
a[name]:where(:not([href])) {
  scroll-margin-top: 5.2rem;
}
@supports (scroll-snap-margin-top: 5.2rem) {
  [id],
  a[name]:where(:not([href])) {
    scroll-snap-margin-top: 5.2rem;
  }
}

.themable .accordion, .themable .dk-accdn .acc-main, .dk-accdn .themable .acc-main, .themable .theme-border.theme-border {
  border-color: #000;
  border-color: var(--theme-border-color, #000);
}
.themable .theme-bg-gradient {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8));
  background-image: linear-gradient(to bottom, var(--theme-grad-start-color, rgba(0, 0, 0, 0.5)), var(--theme-grad-end-color, rgba(0, 0, 0, 0.8)));
}
.themable .theme-bg {
  background-color: #000;
  background-color: var(--theme-color, #000);
}
.themable a.theme-bg:hover, .themable a.theme-bg-hover:hover {
  background-color: #333333;
  background-color: var(--theme-bg-hover-color, #333333);
}
.themable .dk-accdn .acc-title button::after, .dk-accdn .acc-title .themable button::after, .themable .previous-next.themed > * > div::before, .themable .theme-forecolor {
  color: #000;
  color: var(--theme-color, #000);
}
.themable .theme-vidcorner .corner .crn-bg {
  fill: #000;
  fill: var(--theme-color, #000);
}
.themable .lab-news-image:empty {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(../images/lab-news.jpg);
  background-image: linear-gradient(to bottom, var(--theme-grad-start-color, rgba(0, 0, 0, 0.5)), var(--theme-grad-end-color, rgba(0, 0, 0, 0.8))), url(../images/lab-news.jpg);
  background-size: cover;
}
.themable:not([class*=accent-]) {
  --theme-color: #000;
  --theme-bg-hover-color: #333333;
  --theme-border-color: #000;
  --theme-grad-start-color: rgba(0, 0, 0, 0.5);
  --theme-grad-end-color: rgba(0, 0, 0, 0.8);
}
.accent-blue.themable {
  --theme-color: #1568af;
  --theme-bg-hover-color: rgb(15.5357142857, 76.9387755102, 129.4642857143);
  --theme-border-color: #1568af;
  --theme-grad-start-color: rgba(51, 107, 156, 0.85);
  --theme-grad-end-color: rgba(10, 28, 43, 0.85);
}
.accent-green.themable {
  --theme-color: #5f8038;
  --theme-bg-hover-color: rgb(68.6684782609, 92.5217391304, 40.4782608696);
  --theme-border-color: #5f8038;
  --theme-grad-start-color: rgba(93, 131, 57, 0.85);
  --theme-grad-end-color: rgba(24, 30, 18, 0.85);
}

/* droid-sans-regular - latin */
@font-face {
  font-family: "Droid Sans";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/droid-sans-v18-latin-regular.woff2") format("woff2"), url("fonts/droid-sans-v18-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* droid-sans-700 latin */
@font-face {
  font-family: "Droid Sans";
  font-style: normal;
  font-weight: 700;
  src: url("fonts/droid-sans-v18-latin-700.woff2") format("woff2"), url("fonts/droid-sans-v18-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  size-adjust: 97.9%;
  src: local("Verdana");
}
@font-face {
  font-family: "Droid Sans-fallback";
  size-adjust: 100.2%;
  src: local("Trebuchet MS");
}
@font-face {
  font-family: "Droid Sans-local";
  src: local("Droid Sans");
}
@font-face {
  font-family: "Merriweather-fallback";
  size-adjust: 111%;
  src: local("Georgia");
}
@font-face {
  font-family: "Open Sans-fallback";
  size-adjust: 104.7%;
  src: local("Arial");
}
@font-face {
  font-family: "Montserrat-fallback";
  size-adjust: 97.9%;
  src: local("Verdana");
}
@font-face {
  font-family: "Merriweather-local";
  src: local("Merriweather");
}
@font-face {
  font-family: "Open Sans-local";
  src: local("Open Sans");
}
@font-face {
  font-family: "Montserrat-local";
  src: local("Montserrat");
}
/* merriweather-regular - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/merriweather-v30-latin-regular.woff2") format("woff2"), url("fonts/merriweather-v30-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* merriweather-700 - latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/merriweather-v30-latin-700.woff2") format("woff2"), url("fonts/merriweather-v30-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/montserrat-v24-latin-regular.woff2") format("woff2"), url("fonts/montserrat-v24-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/montserrat-v24-latin-700.woff2") format("woff2"), url("fonts/montserrat-v24-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/open-sans-v29-latin-regular.woff2") format("woff2"), url("fonts/open-sans-v29-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/open-sans-v29-latin-600.woff2") format("woff2"), url("fonts/open-sans-v29-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/open-sans-v29-latin-700.woff2") format("woff2"), url("fonts/open-sans-v29-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html, body {
  margin: 0;
  font-size: 14px;
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  html, body {
    font-size: 11.9px;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
  }
}

body {
  background-color: #dbdad3;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  color: #333;
}

#contact {
  background-image: linear-gradient(rgb(253.2, 253, 253), #fff 0%, #f6f5f5);
  padding-bottom: 1.5rem;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  color: #575757;
}
#contact h2 {
  border-bottom: 1px #c1c2c2 solid;
  padding-top: 1em;
  margin-top: 0;
  color: #575757;
}
#contact h3 {
  margin-top: 0;
}
#contact address {
  color: #575757;
  font-style: normal;
}
#contact address a {
  color: #575757;
  text-decoration: none;
}
#contact address a .lnk {
  color: #0072bc;
}
#contact address a:hover, #contact address a:active {
  text-decoration: none;
}
#contact address a:hover .lnk, #contact address a:active .lnk {
  color: #004b8d;
  text-decoration: underline;
}
#contact address [class*=i-] {
  font-size: 1.2em;
  display: inline-block;
  width: 1.7857142857rem;
  text-align: center;
  vertical-align: middle;
}
#contact address .i-mobile {
  font-size: 1.4em;
}
#contact .contact-flex {
  display: flex;
  flex-flow: wrap row;
}
#contact .contact-flex:not(.v2) address i {
  margin-right: 0.3571428571rem;
}
#contact .contact-flex.v2 address > * {
  display: grid;
  grid-template-columns: 2.1428571429rem 1fr;
}
#contact .contact-flex.v2 address > * > :last-child {
  align-self: center;
}
#contact .contact-flex > * {
  flex: 1 0 auto;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
}
#contact .contact-flex > *:last-child {
  margin-right: 0;
}
@media screen and (max-width: 649px) {
  #contact .contact-flex > * {
    min-width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}
#contact .contact-flex address {
  max-width: 24.2857142857rem;
}
#contact .contact-flex address > .print-social {
  display: none;
}
#contact .contact-flex .live-chat {
  max-width: 19.2857142857rem;
}
#contact .contact-flex .follow-us {
  justify-self: flex-end;
  max-width: 300px;
}
#contact .footer-live-chat {
  display: flex;
  align-items: flex-start;
  gap: 0.7142857143rem;
}
#contact .footer-live-chat > :last-child {
  margin-left: 0.5rem;
  line-height: 1.1;
}
#contact .follow-us h3 a {
  text-decoration: none;
}
#contact .follow-us h3 a, #contact .follow-us h3 a:visited, #contact .follow-us h3 a:hover {
  color: inherit;
}
#contact .follow-us h3 a:hover {
  text-decoration: underline;
}
#contact .follow-us a.x-social, #contact .follow-us a.linkedin, #contact .follow-us a.instagram, #contact .follow-us a.youtube, #contact .follow-us a.facebook, #contact .follow-us a.twitter {
  font-size: 0;
  text-indent: 5000rem;
  text-decoration: none;
}
#contact .follow-us a.x-social::before, #contact .follow-us a.linkedin::before, #contact .follow-us a.instagram::before, #contact .follow-us a.youtube::before, #contact .follow-us a.facebook::before, #contact .follow-us a.twitter::before {
  text-indent: 0;
  font-size: 3.5714285714rem;
}
#contact .follow-us a.x-social + a, #contact .follow-us a.linkedin + a, #contact .follow-us a.instagram + a, #contact .follow-us a.youtube + a, #contact .follow-us a.facebook + a, #contact .follow-us a.twitter + a {
  margin-left: 0.5rem;
}
#contact .follow-us a.twitter {
  color: #1d9bf0;
}
#contact .follow-us a.twitter:hover {
  color: rgb(12.9336099585, 121.57593361, 194.8663900415);
}
#contact .follow-us a.twitter::before {
  content: "\e90e";
}
#contact .follow-us a.facebook {
  color: #1877f2;
}
#contact .follow-us a.facebook:hover {
  color: rgb(10.9114754098, 90.6491803279, 193.8885245902);
}
#contact .follow-us a.facebook::before {
  content: "\e90d";
}
#contact .follow-us a.youtube {
  color: #ff0000;
}
#contact .follow-us a.youtube:hover {
  color: rgb(193.8, 0, 0);
}
#contact .follow-us a.youtube::before {
  content: "\e90f";
}
#contact .follow-us a.instagram {
  color: #bf2f98;
}
#contact .follow-us a.instagram:hover {
  color: rgb(141.8857142857, 34.9142857143, 112.9142857143);
}
#contact .follow-us a.instagram::before {
  content: "\e910";
}
#contact .follow-us a.linkedin {
  color: #0a66c2;
}
#contact .follow-us a.linkedin:hover {
  color: rgb(7, 71.4, 135.8);
}
#contact .follow-us a.linkedin::before {
  content: "\e91f";
}
#contact .follow-us a.x-social {
  color: #2f3743;
}
#contact .follow-us a.x-social:hover {
  color: rgb(72.2315789474, 84.5263157895, 102.9684210526);
}
#contact .follow-us a.x-social::before {
  content: "\e920";
}
@media screen and (max-width: 649px) {
  #contact {
    padding: 0 1rem 1rem;
  }
}

/** footer **/
#main-footer {
  background-image: linear-gradient(#474945, #3e403d);
  color: #fff;
}
#main-footer .tagline {
  text-align: center;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
#main-footer .sub-footer {
  background: #2a2b28;
  color: #fff;
  text-align: center;
  padding: 1.5rem 0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
#main-footer .sub-footer a {
  color: #fff;
  text-decoration: none;
}
#main-footer .sub-footer a:hover, #main-footer .sub-footer a:active {
  text-decoration: underline;
}
#main-footer nav {
  margin-top: 2rem;
}
#main-footer nav > section {
  padding-bottom: 2rem;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
#main-footer nav > section {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 23.2317609825%;
}
#main-footer nav > section:last-child {
  margin-right: 0;
}
#main-footer nav > section a {
  text-decoration: none;
  color: #fff;
}
#main-footer nav > section a:hover, #main-footer nav > section a:active {
  text-decoration: underline;
}
#main-footer nav > section h3 {
  border-bottom: #787a77 2px solid;
  font-size: 1.3rem;
  font-weight: normal;
  padding-bottom: 1.2rem;
  margin-bottom: 0.8rem;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
}
#main-footer nav > section h3 [class*=i-] {
  display: none;
}
#main-footer nav > section ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  line-height: 1.2rem;
}
#main-footer nav > section ul li {
  padding: 0.3rem;
}
@media screen and (max-width: 649px) {
  #main-footer nav {
    margin: 1rem 0 0;
    padding: 0;
  }
  #main-footer nav .tagline {
    margin-bottom: 1rem;
  }
  #main-footer nav > section {
    padding: 0;
  }
  #main-footer nav > section {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  #main-footer nav > section:last-child {
    margin-right: 0;
  }
  #main-footer nav > section {
    margin-right: 0;
  }
  #main-footer nav > section:first-of-type {
    border-top: #787a77 2px solid;
  }
  #main-footer nav > section h3 {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    padding-right: 4rem;
    font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
    cursor: pointer;
  }
  #main-footer nav > section h3::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 4rem;
    background-color: rgb(83.75, 86.1091549296, 81.3908450704);
  }
  #main-footer nav > section h3 > a {
    display: block;
    padding: 1rem;
  }
  #main-footer nav > section h3 > i[class*=i-] {
    display: block;
    position: absolute;
    top: calc(50% - 0.6428571429rem);
    right: 0;
    width: 4rem;
    text-align: center;
    font-size: 1.2857142857rem;
    line-height: 1;
  }
  #main-footer nav > section ul {
    display: none;
    background-color: rgb(83.75, 86.1091549296, 81.3908450704);
  }
  #main-footer nav > section ul.show {
    display: block;
  }
  #main-footer nav > section ul li {
    border-bottom: solid 1px #787a77;
    padding: 0;
  }
  #main-footer nav > section ul li:last-child {
    border-bottom-width: 2px;
  }
  #main-footer nav > section ul li a {
    display: block;
    padding: 0.7rem 1rem 0.7rem 2rem;
  }
  #main-footer .sub-footer {
    margin-top: 0;
  }
  #main-footer .sub-footer ul {
    text-align: left;
    left: 1rem;
    border-top: rgba(120, 122, 119, 0.4) 0.5rem double;
    margin-bottom: 2rem;
  }
  #main-footer .sub-footer ul li {
    padding: 0.5rem 1rem;
    border-bottom: rgba(120, 122, 119, 0.4) 0.15rem solid;
  }
  #main-footer .sub-footer ul li {
    display: block;
  }
  #main-footer .sub-footer ul li::after {
    content: "";
    display: block;
    clear: both;
  }
  #main-footer .sub-footer ul li {
    margin-right: 0;
  }
}

.skip-link {
  display: block;
  text-align: center;
  padding: 0.3571428571rem 0.7142857143rem;
  height: auto;
  border: 0.3571428571rem;
  color: #0072bc;
  background-color: #fff;
  text-decoration: none;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1rem;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.skip-link:active, .skip-link:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
.skip-link:active, .skip-link:focus {
  position: absolute;
  top: 0;
  left: calc(50% - 6.0714285714rem);
  width: 12.1428571429rem;
  margin-top: 1.5rem;
  z-index: 5;
}

.site-alert {
  padding: 0.5rem 0;
  background-color: #a20000;
  color: #fff;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.6;
}
.site-alert.urgent {
  background-color: #a20000;
  color: #fff;
}
.site-alert.info {
  background-color: #eacb9a;
  color: #333;
}
@media screen and (max-width: 649px) {
  .site-alert {
    padding: 0.5rem;
  }
}
.site-alert p {
  margin: 0.3rem 0;
}
.site-alert a, .site-alert a:visited {
  color: inherit;
  text-decoration: underline;
}
.site-alert a:hover, .site-alert a:active {
  color: inherit;
  text-decoration: none;
}
.site-alert a.angle-right::after {
  margin-top: -0.1428571429rem;
}
.site-alert ul.horizontal, .site-alert ol.horizontal {
  display: block;
  padding: 0;
}
.site-alert ul.horizontal > li, .site-alert ol.horizontal > li {
  display: block;
}
@media screen and (min-width: 650px) {
  .site-alert ul.horizontal, .site-alert ol.horizontal {
    display: flex;
    flex-wrap: wrap;
  }
  .site-alert ul.horizontal > li, .site-alert ol.horizontal > li {
    margin-right: 1.5rem;
  }
}
.site-alert a.invert-hover, .site-alert .invert-hover a {
  text-decoration: none;
}
.site-alert a.invert-hover:hover, .site-alert .invert-hover a:hover {
  text-decoration: underline;
}
@media print {
  .site-alert {
    display: none;
  }
}

.expanding-alert {
  display: block;
}
.expanding-alert.alert-icon::before, .expanding-alert .expand-above::before {
  content: normal;
}
@media screen and (min-width: 650px) {
  .expanding-alert.alert-icon {
    position: relative;
    padding-left: 1.2857142857rem;
  }
  .expanding-alert.alert-icon::before {
    content: "\e044";
    display: inline-block;
    position: absolute;
    top: 0.5rem;
    left: 0;
    z-index: 1;
  }
}
@media screen and (max-width: 649px) {
  .expanding-alert ul, .expanding-alert ol {
    list-style: none;
    padding: 0.5rem 0;
  }
  .expanding-alert ul li, .expanding-alert ol li {
    margin: 0.5rem 0;
  }
  .expanding-alert .expand-above::before {
    content: "\e044";
    display: inline-block;
    margin-right: 0.5rem;
  }
}
.expanding-alert .expand-above {
  padding: 0.2142857143rem 0;
  cursor: pointer;
}
.expanding-alert .expand-above::after {
  content: "\e003";
  display: inline-block;
  margin-left: 0.5rem;
}
.expanding-alert.collapsed .expand-above::after {
  content: "\e002";
}
.expanding-alert .expand-below {
  padding-top: 0.2142857143rem;
}
@media screen and (max-width: 649px) {
  .expanding-alert .expand-below {
    margin-top: 0.5rem;
    padding: 0.8rem 0 0.5rem;
    border-top: 1px solid #333;
  }
}
.expanding-alert:focus, .expanding-alert .expand-below:focus {
  outline: none;
}

.stripe-brand {
  background-color: #fff;
  padding: 2rem 0;
}
@media screen and (max-width: 649px) {
  .stripe-brand {
    padding: 0;
    box-shadow: 0 0.1071428571rem 1.1428571429rem rgba(0, 0, 0, 0.1);
    transition: box-shadow 150ms linear, right 0.5s;
  }
  .stripe-brand.stuck {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 1px 0.7142857143rem #666;
  }
  .site-wrap.pushed .stripe-brand.stuck {
    right: calc(100% - 2.8571428571rem);
  }
  .search-overlay .stripe-brand.stuck {
    position: static;
  }
  .search-overlay .stripe-brand.stuck ~ .balloon {
    height: 0;
  }
  .site-wrap.subscribe-overlay .stripe-brand.stuck {
    z-index: 35;
  }
  .stripe-brand.stuck ~ .balloon {
    width: 100%;
    height: 4rem;
  }
}
.stripe-brand .dk-brand-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stripe-brand .dk-brand-wrap::after {
  content: normal;
}
.stripe-brand .dk-brand-wrap > :first-child:not(.dk-site-logos) {
  display: block;
  margin-right: auto;
}
@media screen and (min-width: 650px) {
  .stripe-brand .dk-brand-wrap {
    max-width: 73.2142857143rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2.1428571429rem;
  }
  .stripe-brand .dk-brand-wrap::after {
    content: "";
    display: block;
    clear: both;
  }
}
@media screen and (min-width: 650px) and (max-width: 649px) {
  .stripe-brand .dk-brand-wrap {
    padding: 0 0rem;
  }
}
.stripe-brand .dk-site-logos {
  display: grid;
  max-width: 14rem;
  aspect-ratio: 196/34;
  grid-template-columns: 30fr 7fr;
  gap: 0.7857142857rem;
  align-items: center;
  margin-inline: 0.5714285714rem 0.7857142857rem;
}
.stripe-brand .dk-site-logos:has(> :only-child) {
  grid-template-columns: 1fr;
}
.stripe-brand .dk-site-logos > :nth-child(n+3) {
  display: none;
}
.stripe-brand .dk-site-logos > *, .stripe-brand .dk-site-logos img {
  max-width: 100%;
  max-height: 100%;
}
.stripe-brand .dk-site-logos img {
  display: none;
}
.stripe-brand .dk-site-logos > *, .stripe-brand .dk-site-logos img:last-child {
  display: block;
}
@media print, screen and (min-width: 650px) {
  .stripe-brand .dk-site-logos {
    max-width: 26.7142857143rem;
    aspect-ratio: 374/67;
    grid-template-columns: 100fr 21fr;
    gap: 2.0714285714rem;
    margin-inline: 0 2.0714285714rem;
  }
  .stripe-brand .dk-site-logos img:first-child {
    display: block;
  }
  .stripe-brand .dk-site-logos img:last-child {
    display: none;
  }
}
.stripe-brand .dk-site-logo, .stripe-brand .dk-site-logo-addl {
  display: inline-block;
}
.stripe-brand .dk-site-logo img, .stripe-brand .dk-site-logo-addl img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
@media print, screen and (min-width: 650px) {
  .stripe-brand .dk-site-logo {
    width: 21.4285714286rem;
    height: 4.8571428571rem;
  }
  .stripe-brand .dk-site-logo img:not(.desktop):not(.desktop-only) {
    display: none;
  }
}
@media screen and (max-width: 649px) {
  .stripe-brand .dk-site-logo {
    width: 11.8571428571rem;
    height: 100%;
    padding: 0.7857142857rem 0.5714285714rem;
  }
  .stripe-brand .dk-site-logo img:not(.mobile) {
    display: none;
  }
}
.stripe-brand .dk-site-logo-addl {
  margin-left: 1.2rem;
}
.stripe-brand .dk-site-logo-addl img {
  display: none;
}
@media screen and (min-width: 810px) {
  .stripe-brand .dk-site-logo-addl {
    width: 12.5714285714rem;
    height: 100%;
  }
  .stripe-brand .dk-site-logo-addl img {
    display: block;
  }
}
.stripe-brand .dk-site-search {
  position: relative;
  z-index: 35;
}
@media screen and (min-width: 650px) {
  .stripe-brand .dk-site-search {
    margin-left: auto;
  }
}
@media print {
  .stripe-brand .dk-site-search {
    display: none;
  }
}
.stripe-brand .dk-site-mobile-toolbar {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  height: 4rem;
  font-size: 0;
}
@media print, screen and (min-width: 650px) {
  .stripe-brand .dk-site-mobile-toolbar {
    display: none;
  }
}
.stripe-brand .dk-site-mobile-toolbar button {
  display: table-cell;
  cursor: pointer;
  border: solid 0 #c1c2c2;
  border-width: 0 0 0 1px;
  text-align: center;
  height: 4rem;
  min-width: 4.7142857143rem;
  color: #333;
  background: #fff;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 0.8rem;
  vertical-align: middle;
}
.stripe-brand .dk-site-mobile-toolbar button i {
  display: block;
  font-size: 1.6rem;
  line-height: 1.2;
  color: #575757;
}
.stripe-brand .dk-site-mobile-toolbar button:last-child {
  border-right: 0;
}

html:not(.editmode) :is(#___hispec, .editor-only, .meta-edit) {
  display: none;
}

main > section {
  padding: 0.7142857143rem 0;
}

section.centered-white {
  color: #fff;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  text-align: center;
  padding: 2rem 0 3rem;
}
section.centered-white h2 {
  font-weight: normal;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  font-size: 1.5rem;
  border-bottom: 0.1rem solid #f2f2f2;
  display: inline-block;
  padding-bottom: 1.2rem;
  margin: 0 0.7142857143rem;
}
section.centered-white p {
  font-size: 1.245rem;
  margin: 1.2rem;
  color: #fff;
}
@media print {
  section.centered-white {
    color: #333;
  }
  section.centered-white p {
    color: #333;
  }
}

main.left-nav nav {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 23.2317609825%;
}
main.left-nav nav:last-child {
  margin-right: 0;
}
main.left-nav .main-content {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 74.4105869942%;
}
main.left-nav .main-content:last-child {
  margin-right: 0;
}

.thirds {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 31.7615653177%;
}
.thirds:last-child {
  margin-right: 0;
}

.half {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}
.half:last-child {
  margin-right: 0;
}

.two-thirds {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 65.8807826589%;
}
.two-thirds:last-child {
  margin-right: 0;
}

.dk-breadcrumb-row {
  display: block;
  margin: 1rem 0;
  position: relative;
  z-index: 27;
}
.dk-breadcrumb-row::after {
  content: "";
  display: block;
  clear: both;
}
@media print, screen and (min-width: 650px) {
  .dk-breadcrumb-row {
    display: flex;
  }
}
.dk-breadcrumb-row .dk-breadcrumbs {
  display: none;
  flex: 6;
  margin-top: 0.6rem;
}
@media print, screen and (min-width: 650px) {
  .dk-breadcrumb-row .dk-breadcrumbs {
    display: block;
  }
}
.dk-breadcrumb-row .dk-breadcrumbs ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dk-breadcrumb-row .dk-breadcrumbs ol li {
  display: inline-block;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1rem;
  margin: 0;
  color: #343638;
}
.dk-breadcrumb-row .dk-breadcrumbs ol li a {
  text-decoration: none;
  font-weight: bold;
}
.dk-breadcrumb-row .dk-breadcrumbs ol li a, .dk-breadcrumb-row .dk-breadcrumbs ol li a:visited {
  color: #004b8d;
}
.dk-breadcrumb-row .dk-breadcrumbs ol li a:is(:hover, :active) {
  text-decoration: underline;
}
.dk-breadcrumb-row .dk-breadcrumbs ol li:not(:last-child)::after {
  content: var(--page-breadcrumb-separator, " \\ ");
}

.dk-main-container {
  position: relative;
  background: #fff;
  box-shadow: 0 0.1071428571rem 1.1428571429rem rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  padding: 0 1.4285714286rem;
}
.dk-main-container {
  display: block;
}
.dk-main-container::after {
  content: "";
  display: block;
  clear: both;
}
@media screen and (max-width: 649px) {
  .dk-main-container {
    padding: 0 0.7142857143rem 2.5rem;
  }
}
@media print {
  .dk-main-container {
    box-shadow: none;
    padding: 0;
  }
}
.dk-main-container .dk-leftnav {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.dk-main-container .dk-leftnav {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 23.2317609825%;
}
.dk-main-container .dk-leftnav:last-child {
  margin-right: 0;
}
.dk-main-container .landing-hero {
  display: block;
}
.dk-main-container .landing-hero::after {
  content: "";
  display: block;
  clear: both;
}
@media screen and (min-width: 650px) {
  .dk-main-container .landing-hero > :first-child {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 65.8807826589%;
  }
  .dk-main-container .landing-hero > :first-child:last-child {
    margin-right: 0;
  }
  .dk-main-container .landing-hero > :first-child > :first-child {
    margin-top: 0;
  }
  .dk-main-container .landing-hero > :last-child {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 31.7615653177%;
  }
  .dk-main-container .landing-hero > :last-child:last-child {
    margin-right: 0;
  }
  .dk-main-container .landing-hero > :last-child.right {
    margin-top: 0;
    margin-left: 0;
  }
  .dk-main-container .landing-hero > figure:last-child {
    display: table;
  }
  .dk-main-container .landing-hero > :first-child:last-child {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .dk-main-container .landing-hero > :first-child:last-child:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 650px) {
  .dk-main-container .dk-leftnav + .dk-content {
    max-width: 49.1428571429rem;
  }
  .dk-main-container .dk-leftnav + .dk-content {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 74.4105869942%;
  }
  .dk-main-container .dk-leftnav + .dk-content:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 649px) {
  .dk-main-container .dk-leftnav + .dk-content {
    max-width: 44.8571428571rem;
  }
}
.dk-main-container .dk-content {
  padding: 1rem;
  min-height: 28.5714285714rem;
}
.dk-main-container .dk-content {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 100%;
}
.dk-main-container .dk-content:last-child {
  margin-right: 0;
}
.dk-main-container .dk-content p.dk-intro {
  font-weight: bold;
}
.dk-main-container .dk-content .dk-review-date {
  font-weight: bold;
}
.dk-main-container .dk-content > article {
  border-bottom: solid 1px #c1c2c2;
}

.site-wrap {
  position: relative;
}
.site-wrap .page-mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -100;
  height: 100%;
  width: 100%;
  transition: background-color 0.25s, z-index 0s 0.25s;
}

@media screen and (max-width: 649px) {
  .site-wrap.overlay .page-mask {
    position: absolute;
    z-index: 60;
    background-color: rgba(51, 51, 51, 0.7);
    transition: background-color 0.25s;
  }
  .site-wrap.search-overlay .page-mask {
    position: absolute;
    z-index: 60;
    background-color: rgba(0, 0, 0, 0.9);
    transition: background-color 0.25s;
  }
  .site-wrap.popup-overlay .page-mask {
    position: absolute;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.25s;
  }
  body.modal-open {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
.site-wrap.subscribe-overlay .page-mask {
  position: absolute;
  z-index: 40;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.25s;
}

.hero-unit {
  width: 100%;
  padding: 0;
  position: relative;
  background-size: 100% 23rem;
  background-repeat: no-repeat;
  background-position: center top;
}
@media screen and (max-width: 649px) {
  .hero-unit {
    background-size: cover;
  }
}

.fs-return-link.hide-no-ref {
  display: none;
}
.fs-return-link a {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.fs-return-link a:is(:link, :visited, :hover, :active) {
  color: #575757;
}
.fs-return-link a::before {
  content: "\e001";
  position: relative;
  top: 2px;
  margin-right: 0.3571428571rem;
}

.alert.success, .alert.error, .alert.warning, .alert.info {
  padding-left: 4rem;
}
.alert.success:not(.no-icon)::before, .alert.error:not(.no-icon)::before, .alert.warning:not(.no-icon)::before, .alert.info:not(.no-icon)::before {
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  font-size: 2rem;
}

.alert {
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
  padding-left: 1.8rem;
  border-left: 0.5rem solid #adadad;
  background-color: #f0f0f0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.alert p {
  line-height: 1.5;
}
.alert.info {
  background-color: #e7f6f8;
  border-left-color: #00bde3;
}
.alert.info:not(.no-icon)::before {
  content: "\e044";
  transform: rotate(180deg);
}
.alert.warning {
  background-color: #faf3d1;
  border-left-color: #ffbe2e;
}
.alert.warning:not(.no-icon)::before {
  content: "\e044";
}
.alert.error {
  background-color: #f4e3db;
  border-left-color: #d54309;
}
.alert.error:not(.no-icon)::before {
  content: "\e012";
}
.alert.success {
  background-color: #ecf3ec;
  border-left-color: #00a91c;
}
.alert.success:not(.no-icon)::before {
  content: "\e043";
}

.boxes > .thin, .thin.box {
  border-style: solid;
  border-width: 1px;
}
.boxes > .accented.thin, .accented.thin.box {
  border-top-width: 0.5714285714rem;
}
.boxes > .thin :is(h1, h2, h3), .thin.box :is(h1, h2, h3) {
  font-weight: bold;
}

.boxes > .thick, .thick.box {
  position: relative;
  border: 0.7142857143rem solid #f5f5f5;
}
.boxes > .thick > *, .thick.box > * {
  position: relative;
  z-index: 1;
}
.boxes > .thick::after, .thick.box::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 0.3571428571rem 0.1428571429rem rgb(231.525, 231.525, 231.525);
}
.boxes > .thick :is(h1, h2, h3), .thick.box :is(h1, h2, h3) {
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  font-weight: normal;
}
.boxes > .thick :is(h1, h2, h3):not(:last-child), .thick.box :is(h1, h2, h3):not(:last-child) {
  padding-bottom: 0.7142857143rem;
  border-bottom: solid 1px #c1c2c2;
}
.boxes > [class*=accent-].thick :is(h1, h2, h3), [class*=accent-].thick.box :is(h1, h2, h3), .boxes > [class*=accent-].thick :is(h1, h2, h3) a, [class*=accent-].thick.box :is(h1, h2, h3) a {
  border: 0;
  padding: 0.7142857143rem;
  margin: -0.7142857143rem;
  color: #fff;
}
.boxes > [class*=accent-].thick :not(:last-child):is(h1, h2, h3), [class*=accent-].thick.box :not(:last-child):is(h1, h2, h3) {
  margin-bottom: 0;
}
.boxes > [class*=accent-].thick :is(h1, h2, h3) a, [class*=accent-].thick.box :is(h1, h2, h3) a {
  display: block;
  text-decoration: underline;
}
.boxes > [class*=accent-].thick :is(h1, h2, h3) a:hover, [class*=accent-].thick.box :is(h1, h2, h3) a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.boxes > .filled, .filled.box {
  border: 0;
}
.boxes > .filled:not([class*=bg-]):not([class*=accent-]), .filled.box:not([class*=bg-]):not([class*=accent-]) {
  background-color: #f2f2f2;
}
.boxes > [class*=accent-].filled *, [class*=accent-].filled.box * {
  color: #fff;
}
.boxes > [class*=accent-].filled a, [class*=accent-].filled.box a {
  text-decoration: underline;
}
.boxes > [class*=accent-].filled a:hover, [class*=accent-].filled.box a:hover {
  text-decoration: none;
}

.boxes > *, .box {
  display: block;
  margin: 0 0 1.5rem 0;
  padding: 0.7142857143rem;
}
.boxes > :not(:nth-child(1)), .box:not(:nth-child(1)) {
  margin-top: 0;
}
.boxes > * :is(h1, h2, h3), .box :is(h1, h2, h3) {
  margin: 0.7142857143rem 0;
}
.boxes > .bg-gray :is(h1, h2, h3), .bg-gray.box :is(h1, h2, h3) {
  font-weight: bold;
}
.boxes > * img, .box img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.boxes > * figure img, .box figure img, .boxes > * .figure img, .box .figure img {
  width: 100%;
}
.boxes > * p, .box p {
  margin-top: 0.7142857143rem;
  margin-bottom: 0.7142857143rem;
}
.boxes > * > *, .box > *, .boxes > * > .body > *, .box > .body > * {
  margin-top: 0.7142857143rem;
  margin-bottom: 0.7142857143rem;
}
.boxes > * > *:empty, .box > *:empty, .boxes > * > .body > *:empty, .box > .body > *:empty {
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (min-width: 650px) {
  .box + .box, .box + .boxes {
    margin-top: -0.7857142857rem;
  }
}

.boxes {
  display: flex;
  flex-wrap: wrap;
}
.boxes > * {
  display: block;
  width: 100%;
  margin: 0;
}
.boxes section {
  margin-bottom: 0.7142857143rem;
}
.boxes section > :first-child {
  margin: 0;
}

@media screen and (min-width: 650px) {
  .boxes {
    margin: 0.7142857143rem 0;
    margin-bottom: 1.5rem;
  }
  .boxes.boxes > * {
    flex: 1;
    margin: 0;
    margin-right: 0.7142857143rem;
  }
  .boxes.boxes > *:last-child {
    margin-right: 0;
  }
  .boxes.boxes > * img.right, .boxes.boxes > * img.left {
    width: 40%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .boxes.boxes > * img.bottom-margin {
    margin-bottom: 0.7142857143rem;
  }
  .boxes.boxes + .boxes.boxes {
    margin-top: -0.7142857143rem;
  }
  .boxes > :first-child:nth-last-child(2).two-thirds, .boxes > :first-child:nth-last-child(2) ~ .two-thirds {
    flex: none;
    width: calc(66.6666666667% - 0.2380952381rem);
    float: none;
  }
  .boxes > :first-child:nth-last-child(2).three-quarters, .boxes > :first-child:nth-last-child(2) ~ .three-quarters {
    flex: none;
    width: calc(75% - 0.1785714286rem);
    float: none;
  }
  .boxes > :first-child:nth-last-child(3).half, .boxes > :first-child:nth-last-child(3) ~ .half {
    flex: none;
    width: calc(50% - 0.3571428571rem);
    float: none;
  }
}
.thin-box.accented {
  border-top-width: 0.5714285714rem;
}

.box-filled {
  margin: 1rem 0;
  padding: 0.7142857143rem;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}
.box-filled :is(h1, h2, h3) {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.box-filled * {
  color: #fff;
}
.box-filled .button-pair {
  display: flex;
  margin: 2rem;
}
.box-filled .button-pair > * {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 0.5rem 0.5rem;
  margin: 0 0.5rem;
  text-align: center;
  line-height: 1.3;
}
.box-filled .button-pair > * > span {
  width: 100%;
}
.box-filled .button-pair > *.button-ghost::after {
  content: normal;
}
@media screen and (max-width: 649px) {
  .box-filled .button-pair {
    display: block;
  }
  .box-filled .button-pair > * {
    max-width: 21.4285714286rem;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }
  .box-filled .button-pair > *:last-child {
    margin-bottom: 0;
  }
}

a.button.angle-left::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e029";
  margin-top: -0.1428571429rem;
  padding-right: 0.5em;
  font-size: 1.2em;
  vertical-align: middle;
}
a.button.gray {
  background-color: #f5f5f5;
  color: #5b584b;
  border: 1px solid #c0c0c0;
}
a.button.gray:visited {
  color: #5b584b;
}
a.button.gray:hover {
  background: rgb(232.75, 232.75, 232.75);
  color: #5b584b;
  text-decoration: none;
}
a.button.blue-green {
  background-color: #085863;
  border-color: #085863;
}
a.button.blue-green:hover {
  background-color: rgb(4.1869158879, 46.0560747664, 51.8130841121);
}
@media screen and (max-width: 649px) {
  a.button.mobile-full {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 649px) {
  .collapse-list ul {
    margin-left: 0.2857142857rem;
  }
}
.collapse-list li {
  position: relative;
}
.collapse-list li input[type=checkbox] {
  position: absolute;
  z-index: -500;
  left: -5000px;
}
.collapse-list li input[type=checkbox] + label {
  display: block;
  position: absolute;
  top: 0.1428571429rem;
  left: -1.5rem;
  width: 1.1428571429rem;
  height: 1.1428571429rem;
  font-size: 0;
  text-indent: 357.1428571429rem;
  color: transparent;
}
.collapse-list li input[type=checkbox] + label:hover::before {
  background-color: rgb(134.3179347826, 179.0652173913, 81.4347826087);
}
.collapse-list li input[type=checkbox] + label::before {
  content: "\e01a";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.1428571429rem;
  height: 1.1428571429rem;
  text-align: center;
  text-indent: 0;
  border: solid 1px #5f8038;
  border-radius: 0.2142857143rem;
  background-color: #5f8038;
  color: #fff;
  font-size: 0.8rem;
  line-height: 1.4;
  cursor: pointer;
}
.collapse-list li input[type=checkbox]:focus + label::before {
  outline: solid 2px rgba(21, 104, 175, 0.5);
}
.collapse-list li input[type=checkbox] ~ ul {
  display: none;
}
.collapse-list li input[type=checkbox]:checked + label::before {
  content: "\e019";
}
.collapse-list li input[type=checkbox]:checked ~ ul {
  display: block;
}

.columns {
  margin: 1rem 0;
}
.columns {
  display: block;
}
.columns::after {
  content: "";
  display: block;
  clear: both;
}
.columns > :is(ul, ol) {
  margin-top: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 649px) {
  .columns > :is(ul, ol):not(:first-child) {
    margin-top: 0.5em;
  }
  .columns > :is(ul, ol):not(:last-child) {
    margin-bottom: 0.5em;
  }
}
@media screen and (min-width: 650px) {
  .columns > :is(ul, ol) > li:first-of-type {
    margin-top: 0;
  }
  .columns > :is(ul, ol) > li:last-of-type {
    margin-bottom: 0;
  }
  .columns > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  .columns > * > *:first-child {
    margin-top: 0;
  }
  .columns > * > *:last-child {
    margin-bottom: 0;
  }
  .columns > :first-child:nth-last-child(2), .columns > :first-child:nth-last-child(2) ~ * {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .columns > :first-child:nth-last-child(2):last-child, .columns > :first-child:nth-last-child(2) ~ *:last-child {
    margin-right: 0;
  }
  .columns > :first-child:nth-last-child(3), .columns > :first-child:nth-last-child(3) ~ * {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 31.7615653177%;
  }
  .columns > :first-child:nth-last-child(3):last-child, .columns > :first-child:nth-last-child(3) ~ *:last-child {
    margin-right: 0;
  }
  .columns > :first-child:nth-last-child(4), .columns > :first-child:nth-last-child(4) ~ * {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 23.2317609825%;
  }
  .columns > :first-child:nth-last-child(4):last-child, .columns > :first-child:nth-last-child(4) ~ *:last-child {
    margin-right: 0;
  }
  .columns > .one-quarter.one-quarter.one-quarter {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 23.2317609825%;
  }
  .columns > .one-quarter.one-quarter.one-quarter:last-child {
    margin-right: 0;
  }
  .columns > .one-third.one-third.one-third {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 31.7615653177%;
  }
  .columns > .one-third.one-third.one-third:last-child {
    margin-right: 0;
  }
  .columns > .one-half.one-half.one-half {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .columns > .one-half.one-half.one-half:last-child {
    margin-right: 0;
  }
  .columns > .two-thirds.two-thirds.two-thirds {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 65.8807826589%;
  }
  .columns > .two-thirds.two-thirds.two-thirds:last-child {
    margin-right: 0;
  }
  .columns > .three-quarters.three-quarters.three-quarters {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 74.4105869942%;
  }
  .columns > .three-quarters.three-quarters.three-quarters:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 649px) {
  .columns > .one-quarter.one-quarter.one-quarter {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .columns > .one-quarter.one-quarter.one-quarter:last-child {
    margin-right: 0;
  }
  .columns > .one-third.one-third.one-third {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .columns > .one-third.one-third.one-third:last-child {
    margin-right: 0;
  }
  .columns > .one-half.one-half.one-half {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .columns > .one-half.one-half.one-half:last-child {
    margin-right: 0;
  }
  .columns > .two-thirds.two-thirds.two-thirds {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .columns > .two-thirds.two-thirds.two-thirds:last-child {
    margin-right: 0;
  }
  .columns > .three-quarters.three-quarters.three-quarters {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .columns > .three-quarters.three-quarters.three-quarters:last-child {
    margin-right: 0;
  }
}

.column-wrap {
  break-inside: avoid-column;
  columns: 2;
}
.column-wrap.cols-3 {
  columns: 3;
}
.column-wrap.cols-4 {
  columns: 4;
}
@media screen and (max-width: 649px) {
  .column-wrap:not(.wrap-mobile) {
    columns: 1;
  }
}
.column-wrap.margin {
  margin: 1rem 0;
}
.column-wrap.no-break > *, .column-wrap:not(.allow-break) > li {
  page-break-inside: avoid;
}
.column-wrap > p:first-child {
  margin-top: 0;
}
.column-wrap > p:last-child {
  margin-bottom: 0;
}

:is(ul, ol).column-wrap.loose > *:first-child {
  margin-top: 0;
}
:is(ul, ol).column-wrap.loose > *:last-child {
  margin-bottom: 0;
}

.detailed-download {
  display: flex;
  justify-content: flex-end;
  justify-items: stretch;
  align-content: center;
  align-items: stretch;
  min-height: 4.2857142857rem;
  max-height: 14.2857142857rem;
  max-width: 35.7142857143rem;
  margin: 1.2rem auto;
  border-radius: 0.3571428571rem;
  background-color: #f6f5f5;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  overflow: hidden;
}
.detailed-download.full-width {
  max-width: 100%;
}
.detailed-download .detail {
  position: relative;
  flex: 1;
  padding: 0.5rem;
}
.detailed-download .detail.pad {
  padding-right: 1.5714285714rem;
}
.detailed-download .detail::before, .detailed-download .detail::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  border-style: solid;
}
.detailed-download .detail::before {
  transform: translateY(-50%);
  border-width: 7.1428571429rem 0 7.1428571429rem 1.4285714286rem;
  border-color: #ddd #fff;
}
.detailed-download .detail::after {
  transform: translate(-0.1428571429rem, -50%);
  border-width: 7.1428571429rem 0 7.1428571429rem 1.4285714286rem;
  border-color: transparent #f6f5f5;
}
.detailed-download .detail > * {
  position: relative;
  z-index: 1;
}
.detailed-download .title {
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
}
.detailed-download p, .detailed-download .description {
  display: block;
  margin: 0.3rem 0;
  line-height: 1.5;
}
.detailed-download .centered {
  text-align: center;
}
.detailed-download .link {
  font-weight: 600;
}
.detailed-download .icon {
  position: relative;
  width: 4.2857142857rem;
  background-color: #ddd;
  font-size: 2.2rem;
}
@media screen and (max-width: 649px) {
  .detailed-download .icon {
    width: 3.5714285714rem;
  }
}
.detailed-download .icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.detailed-download .dk-tag {
  display: block;
  min-width: auto;
  padding: 0 2px;
  border-radius: 0;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

a.detailed-download, a .detailed-download {
  color: #575757;
  text-decoration: none;
}
a.detailed-download, a.detailed-download :not(i), a.detailed-download .detail::before, a.detailed-download .detail::after, a .detailed-download, a .detailed-download :not(i), a .detailed-download .detail::before, a .detailed-download .detail::after {
  transition: all 250ms ease;
}
a.detailed-download .link, a .detailed-download .link {
  color: #0072bc;
}
a:visited.detailed-download, a:visited .detailed-download {
  color: #575757;
}
a:visited.detailed-download .link, a:visited .detailed-download .link {
  color: #c35522;
}
a:hover.detailed-download, a:hover .detailed-download {
  color: #575757;
  text-decoration: none;
  background-color: rgb(233.9210526316, 231.5789473684, 231.5789473684);
}
a:hover.detailed-download .detail::before, a:hover .detailed-download .detail::before {
  border-left-color: #fff;
}
a:hover.detailed-download .detail::after, a:hover .detailed-download .detail::after {
  border-color: transparent rgb(233.9210526316, 231.5789473684, 231.5789473684);
}
a:hover.detailed-download .icon, a:hover .detailed-download .icon {
  color: #fff;
}
a:hover.detailed-download .link, a:hover .detailed-download .link {
  color: #004b8d;
  text-decoration: underline;
}
a:hover.detailed-download .dk-tag, a:hover .detailed-download .dk-tag {
  text-decoration: none;
}

.dictionary-term, .dictionary-term:visited {
  color: #0072bc;
  text-decoration: none;
  border-bottom: 1px dashed #0072bc;
}
.dictionary-term:hover, .dictionary-term:active {
  color: #004b8d;
  text-decoration: none;
}

dl.term-definition {
  margin: 0 0 1rem;
}
dl.term-definition dfn {
  font-style: normal;
}
dl.term-definition dt {
  margin: 1rem 0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-weight: 600;
}
dl.term-definition dt:first-child {
  margin-top: 0;
}
dl.term-definition dd {
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  font-size: 1rem;
  margin: 1rem 0;
}
dl.term-definition dd:last-child {
  margin-bottom: 0;
}
dl.term-definition dd.pronunciation {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}

.dk-flex-table {
  margin: 0 -0.7142857143rem;
}

@media screen and (max-width: 649px) {
  .dk-content .dk-flex-table {
    margin: 0 -0.2142857143rem 0 -1.1428571429rem;
  }
}

.dk-row {
  position: relative;
  z-index: 0;
  width: 100%;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  font-weight: normal;
}
@media screen and (max-width: 649px) {
  .dk-row {
    display: block;
  }
  .dk-row {
    display: block;
  }
  .dk-row::after {
    content: "";
    display: block;
    clear: both;
  }
  .dk-row .dk-box {
    display: block;
    float: left;
  }
}
.dk-row .dk-box {
  display: block;
}
@media print, screen and (min-width: 650px) {
  .dk-row {
    display: flex;
    flex-wrap: wrap;
  }
  .dk-row .dk-box {
    flex: 1 1 0px;
  }
  .dk-row .dk-two-thirds {
    flex: 2 2;
  }
  .dk-row .dk-one-third {
    flex: 1 1;
  }
  .dk-row .dk-half {
    flex: 2 2;
  }
  .dk-row .dk-one-quarter {
    flex: 1 1;
  }
  .dk-row :is(.dk-two-thirds, .dk-one-third, .dk-half, .dk-one-quarter).border-thick {
    flex-basis: 2.8571428571rem;
  }
  .dk-row :is(.dk-two-thirds, .dk-one-third, .dk-half, .dk-one-quarter).border-thin {
    flex-basis: 1.5714285714rem;
  }
  .dk-row :is(.dk-two-thirds, .dk-one-third, .dk-half, .dk-one-quarter).border-thin:last-child {
    flex-basis: 0.1428571429rem;
  }
}
.dk-row .dk-box .dk-box-content {
  width: 100%;
  padding: 1.4285714286rem;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.dk-row .dk-box .dk-box-content > .dk-box-content {
  padding: 0;
}
.dk-row .dk-box .dk-box-content p {
  color: #575757;
  line-height: 1.5;
}
.dk-row .dk-box .dk-box-content :is(ul, ol) {
  line-height: 1.3;
}
.dk-row .dk-box .dk-box-content img {
  display: block;
  margin: 0 auto 1rem;
  border: 0;
}
.dk-row .dk-box .dk-box-content .image-beside-link {
  margin: -1.0714285714rem;
}
.dk-row .dk-box .dk-box-content .image-beside-link img {
  margin: 0;
}
.dk-row .dk-box .dk-box-content :is(figure, .figure) {
  margin: 1rem 0;
}
.dk-row .dk-box .dk-box-content :is(figure, .figure) img {
  margin: 0 auto;
}
@media screen and (max-width: 649px) {
  .dk-row .dk-box .dk-box-content :is(figure, .figure) {
    margin: auto;
  }
}
.dk-row .dk-box .dk-box-content :is(figure, .figure).left {
  margin: 0 1rem 0 0;
}
.dk-row .dk-box .dk-box-content :is(figure, .figure).right {
  margin: 0 0 0 1rem;
}
.dk-row .dk-box .dk-box-content :is(figure, .figure):is(.left, .right) + p {
  margin-top: 0;
}
@media screen and (max-width: 649px) {
  .dk-row .dk-box .dk-box-content :is(figure, .figure):is(.left, .right) {
    margin: 0 0 1rem;
  }
}
@media screen and (min-width: 650px) {
  .dk-row .dk-box .dk-box-content > figure.large, .dk-row .dk-box .dk-box-content > .figure.large {
    max-width: 49%;
  }
}
.dk-row .image-box {
  flex: 0 0 14.2857142857rem;
}
.dk-row .image-box figure {
  margin: 0 auto;
}

.dk-box.border-thick[class*=accent-] h2 {
  position: relative;
  margin: 0;
  padding: 1rem 1.2857142857rem;
  border: 0;
  color: #fff;
}
.dk-box.border-thick[class*=accent-] h2 a {
  color: inherit;
}
.dk-box.border-thick[class*=accent-] h2 a::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dk-box {
  background: #fff;
  text-decoration: none;
}
@media screen and (min-width: 650px) {
  .dk-box[class*=border-]:not(:last-child) {
    margin-right: 1.4285714286rem;
  }
}
@media screen and (max-width: 649px) {
  .dk-box[class*=border-] {
    margin: 1.4285714286rem;
  }
}
@media screen and (min-width: 650px) {
  .dk-box.border-thick {
    margin-bottom: 1.4285714286rem;
    margin-top: 1.4285714286rem;
  }
  .dk-box.border-thick:not(:last-child) {
    margin-right: 2.1428571429rem;
  }
}
@media screen and (max-width: 649px) {
  .dk-box.border-thick {
    margin: 1.4285714286rem;
    width: calc(100% - 2.8571428571rem);
  }
}
@media print {
  .dk-box.border-thick {
    margin: 0.7142857143rem 0;
  }
  .dk-box.border-thick:not(:last-child) {
    margin-right: 1.4285714286rem;
  }
}
.dk-box.border-thin-accent, .dk-box.border-thin {
  border-width: 1px;
  border-style: solid;
}
@media screen and (min-width: 650px) {
  .dk-box.border-thin-accent, .dk-box.border-thin {
    margin-bottom: 0.7142857143rem;
    margin-top: 0.7142857143rem;
  }
}
.dk-box.border-thin-accent {
  border-top-width: 0.5714285714rem;
}
.dk-box h2 {
  border-bottom: 1px solid #c1c2c2;
  padding: 1rem 0;
  margin: 0 1rem;
  color: #333;
  text-align: left;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  font-weight: normal;
}
.dk-box.dk-box > img, .dk-box.dk-box > a > img {
  display: block;
  padding: 0.7142857143rem;
  margin: 0 auto;
}
.dk-box.dk-box > img.left, .dk-box.dk-box > a > img.left {
  max-width: 50%;
}
@media screen and (max-width: 649px) {
  .dk-box.dk-box > img.left, .dk-box.dk-box > a > img.left {
    float: left;
  }
}
.dk-box > a:first-child > img, .dk-box > img:first-child {
  padding-bottom: 0;
}
.dk-box > h2 + a > img, .dk-box > h2 + img {
  padding-top: 0;
}
.dk-box > h2 + a > img.left, .dk-box > h2 + img.left {
  padding-bottom: 0;
}
.dk-box.border-thick[class*=accent-] h2 > a:hover {
  text-decoration: none;
}
.dk-box.fg-white h2, .dk-box.fg-white h2 a, .dk-box.fg-white .dk-box-content, .dk-box.fg-white .dk-box-content p, .dk-box.fg-white .dk-box-content a, .dk-box.fg-white a:after, .dk-box.fg-white a:visited, .dk-box.fg-white time {
  color: #fff;
}
.dk-box.fg-white :is(h2, .dk-box-content) a {
  text-decoration: underline;
}
.dk-box.fg-white :is(h2, .dk-box-content) a:hover {
  text-decoration: none;
}
.dk-box.fg-white :is(h2, .dk-box-content) a:is(.button, .button-ghost) {
  text-decoration: none;
}
.dk-box .dk-box-content {
  -webkit-hyphens: manual;
  hyphens: manual;
  word-break: break-word;
}
.dk-box .dk-box-content {
  display: block;
}
.dk-box .dk-box-content::after {
  content: "";
  display: block;
  clear: both;
}

.dk-content .promo-hero + .dk-flex-table, .dk-content .promo-banner + .dk-flex-table {
  margin-top: calc(-1 * (1rem - 0.3571428571rem));
}
.dk-content .dk-flex-table {
  margin: 0;
}
.dk-content .dk-flex-table + .action-link {
  margin: 0.3571428571rem 0;
}
.dk-content .dk-flex-table + .box-filled {
  margin: 0.3571428571rem 0;
}
.dk-content .action-link + .box-filled {
  margin-top: 0.7142857143rem;
}
.dk-content .dk-box.border-thin, .dk-content .dk-box.border-thin-accent, .dk-content .dk-box.image-box {
  margin-block: 0.3571428571rem;
  margin-right: 0.7142857143rem;
}
.dk-content .dk-box.border-thin:last-child, .dk-content .dk-box.border-thin-accent:last-child, .dk-content .dk-box.image-box:last-child {
  margin-right: 0;
}
.dk-content .dk-box.border-thin :is(h2, h3), .dk-content .dk-box.border-thin-accent :is(h2, h3), .dk-content .dk-box.image-box :is(h2, h3) {
  margin-inline: 0.7142857143rem;
  border: 0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1.2857142857rem;
}
.dk-content .dk-box.border-thin .dk-box-content, .dk-content .dk-box.border-thin-accent .dk-box-content, .dk-content .dk-box.image-box .dk-box-content {
  padding-inline: 0.7142857143rem;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  color: #575757;
}
.dk-content .dk-box.border-thin .dk-box-content:not(:first-child), .dk-content .dk-box.border-thin-accent .dk-box-content:not(:first-child), .dk-content .dk-box.image-box .dk-box-content:not(:first-child) {
  padding-top: 0;
}
@media screen and (max-width: 649px) {
  .dk-content .dk-row {
    margin-left: 0;
  }
  .dk-content .dk-box.border-thin, .dk-content .dk-box.border-thin-accent, .dk-content .dk-box.image-box {
    width: 100%;
    margin-inline: 0;
  }
}

.border-thick {
  position: relative;
  margin: 0.7142857143rem;
  box-shadow: 0 0 0.3571428571rem 0.1428571429rem rgb(231.525, 231.525, 231.525);
}
.border-thick::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  inset: -0.7142857143rem;
  background: #f5f5f5;
}

:is(.file, [class^=file-], [class*=" file-"]):where(:not(.file-size))::before {
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  margin-top: -0.125em;
  vertical-align: middle;
}

:is(.file, [class^=file-], [class*=" file-"]):where(:not(.file-size))::before {
  content: url("../images/sprite/file-icons.svg#file");
  font-size: 1.01em;
}

:is(.file-audio, .file-mp3, .file-m4a)::before {
  content: url("../images/sprite/file-icons.svg#audio");
}

:is(.file-doc, .file-docx)::before {
  content: url("../images/sprite/file-icons.svg#doc");
}

:is(.file-excel, .file-xls, .file-xlsx)::before {
  content: url("../images/sprite/file-icons.svg#excel");
}

:is(.file-image, .file-gif, .file-jpg, .file-jpeg, .file-png, .file-svg, .file-tif, .file-tiff)::before {
  content: url("../images/sprite/file-icons.svg#image");
}

:is(.file-pdf)::before {
  content: url("../images/sprite/file-icons.svg#pdf");
}

:is(.file-powerpoint, .file-ppt, .file-pptx)::before {
  content: url("../images/sprite/file-icons.svg#powerpoint");
}

:is(.file-video, .file-mov, .file-mp4, .file-wmv)::before {
  content: url("../images/sprite/file-icons.svg#video");
}

:is(.file-zip)::before {
  content: url("../images/sprite/file-icons.svg#zip");
}

:is(.file-files, .file-set, .file-multiple)::before {
  content: url("../images/sprite/file-icons.svg#files");
}

:is(ul, ol).no-bullets li, :is(ul, ol).no-bullets-shallow > li {
  list-style: none;
  margin: 0.7em 0;
}
:is(ul, ol).no-bullets-shallow > li li {
  list-style-type: disc;
}
:is(ul, ol).no-bullets-shallow > li li li {
  list-style-type: circle;
}
:is(ul, ol).loose li, :is(ul, ol).loose li > :is(ul, ol), :is(ul, ol).loose-shallow > li, :is(ul, ol).loose-shallow > li > :is(ul, ol) {
  margin-top: 1em;
  margin-bottom: 1em;
}
:is(ul, ol).float-fix li {
  position: relative;
  overflow: hidden;
  margin-left: 0;
  padding-left: 1.3em;
}
:is(ul, ol).float-fix li::before {
  content: "•";
  position: absolute;
  top: 0.15em;
  left: 0;
  font-size: 1.7857142857rem;
  line-height: 0.56;
}
:is(ul, ol).horizontal-links {
  list-style: none;
  padding: 0;
}
:is(ul, ol).horizontal-links li {
  display: inline;
}
:is(ul, ol).horizontal-links li::after {
  content: " |";
  display: inline;
}
:is(ul, ol).horizontal-links li:last-of-type::after {
  content: "";
}
:is(ul, ol).alternating > li {
  margin-top: 0.8rem;
}
@media screen and (min-width: 650px) {
  :is(ul, ol).alternating::after {
    content: "";
    display: block;
    clear: both;
  }
  :is(ul, ol).alternating > li {
    width: calc(50% - 1.6em);
    margin-left: 1.3em;
  }
  :is(ul, ol).alternating > li:nth-child(odd) {
    float: left;
    clear: left;
  }
  :is(ul, ol).alternating > li:nth-child(even) {
    float: right;
    clear: right;
  }
  :is(ul, ol).alternating > li:nth-child(-n+2) {
    margin-top: 0;
  }
  :is(ul, ol).alternating > li:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
  :is(ul, ol).alternating.rows > li:nth-child(even) {
    margin-left: 1.9em;
    float: left;
    clear: none;
  }
  :is(ul, ol).alternating.rows > li:nth-child(odd) {
    clear: left;
  }
}

.dk-pagination {
  margin: 2rem 0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.dk-pagination {
  display: block;
}
.dk-pagination::after {
  content: "";
  display: block;
  clear: both;
}
.dk-pagination .dk-per-page {
  display: inline-block;
  font-weight: 700;
}
.dk-pagination .dk-per-page {
  display: block;
  float: left;
  margin-right: 2.3576520234%;
  width: 48.8211739883%;
}
.dk-pagination .dk-per-page:last-child {
  margin-right: 0;
}
.dk-pagination .dk-per-page select {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1.1em;
}
.dk-pagination .dk-pages {
  display: block;
  text-align: right;
  cursor: default;
  font-size: 0;
}
.dk-pagination .dk-pages .current, .dk-pagination .dk-pages button {
  border-style: solid;
  border-width: 1px;
  border-radius: 0.1428571429rem;
  min-width: 2rem;
  height: 2rem;
  box-sizing: content-box;
}
.dk-pagination .dk-pages > * {
  display: inline-block;
  padding: 0;
  margin: 0;
  margin-left: 0.2142857143rem;
  height: 2rem;
  text-align: center;
  vertical-align: middle;
  color: #333;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1rem;
}
.dk-pagination .dk-pages > *:first-child {
  margin-left: 0;
}
.dk-pagination .dk-pages .gap {
  width: 0.8rem;
  line-height: 2.25rem;
}
.dk-pagination .dk-pages .gap::before {
  content: "…";
  display: inline;
}
.dk-pagination .dk-pages button {
  border-color: #c0c0c0;
  background-color: #f5f5f5;
}
.dk-pagination .dk-pages button:hover, .dk-pagination .dk-pages button:active {
  background-color: rgb(220.5, 220.5, 220.5);
  text-decoration: none;
  cursor: pointer;
}
.dk-pagination .dk-pages button .i-angle-left {
  position: relative;
  left: -0.0714285714rem;
}
.dk-pagination .dk-pages .current {
  line-height: 2rem;
  color: #fff;
}
.dk-pagination .dk-pages ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dk-pagination .dk-pages ul li {
  display: inline-block;
  background: #f5f5f5;
  border: solid 1px #c0c0c0;
  border-radius: 0.1428571429rem;
  padding: 0;
  margin: 0;
  min-width: 2.1428571429rem;
  text-align: center;
}
.dk-pagination .dk-pages ul li > * {
  display: block;
  padding: 0 0.7142857143rem;
}
.dk-pagination .dk-pages ul li a {
  color: #575757;
}
@media screen and (min-width: 650px) {
  .dk-pagination .dk-per-page + .dk-pages {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .dk-pagination .dk-per-page + .dk-pages:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 649px) {
  .dk-pagination .dk-per-page, .dk-pagination .dk-pages {
    text-align: center;
  }
  .dk-pagination .dk-per-page, .dk-pagination .dk-pages {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .dk-pagination .dk-per-page:last-child, .dk-pagination .dk-pages:last-child {
    margin-right: 0;
  }
  .dk-pagination .dk-pages {
    margin-top: 2rem;
  }
}

.margin-top-2rem.margin-top-2rem.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem.margin-top-3rem.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem.margin-top-4rem.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem.margin-top-5rem.margin-top-5rem {
  margin-top: 5rem;
}

.eld {
  font-size: 0;
  text-indent: 5000rem;
}
.eld::before {
  font-size: 1rem;
  text-indent: 0;
}
.exit-no-break + .eld::before {
  display: inline;
}

.eld:is(.eld, :visited, :hover, :active) {
  color: var(--icon-color, #426924);
  text-decoration: none;
}

.bg-ash {
  background-color: #f6f5f5;
}

.bg-gray {
  background-color: #f2f2f2;
}

.bg-honeydew {
  background-color: #f2f7f5;
}

.bg-sea-foam {
  background-color: #c7dcda;
}

.bg-sea-glass {
  background-color: #ecf4f7;
}

.bg-charcoal {
  background-color: #474945;
}

.bg-dark-cyan {
  background-color: #2a586a;
}

.bg-dark-blue {
  background-color: #23325e;
}

.bg-lavender {
  background-color: #3e4573;
}

.bg-onyx {
  background-color: #2a2b28;
}

.social-contact-links > a.orcid::before, .social-contact-links > a.google-scholar::before, .social-contact-links > a.researchgate::before, .social-contact-links > a.linkedin::before, .social-contact-links > a.add-contact::before, .social-contact-links > a.phone::before, .social-contact-links > a.email::before, a.phone::before, a.email::before {
  color: var(--icon-color, #575757);
  padding: 0 0.2857142857rem 0 0.1428571429rem;
}

a.email::before {
  content: "\e02a";
}
a.phone::before {
  content: "\e022";
}
a.no-icon:is(.email, .phone)::before {
  content: normal;
}

.social-contact-links > a.orcid::before, .social-contact-links > a.google-scholar::before, .social-contact-links > a.researchgate::before, .social-contact-links > a.linkedin::before, .social-contact-links > a.add-contact::before, .social-contact-links > a.phone::before, .social-contact-links > a.email::before {
  display: block;
  position: absolute;
  padding: 0;
  font-size: 1.5em;
  top: 0;
  left: 0;
}

.social-contact-links {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.social-contact-links > a {
  display: block;
  position: relative;
  max-width: 100%;
  margin-bottom: 0.8rem;
  padding-left: 1.7142857143rem;
}
.social-contact-links > a.email::before {
  content: "\e027";
}
.social-contact-links > a.phone::before {
  content: "\e91d";
}
.social-contact-links > a.add-contact::before {
  content: "\e07b";
}
.social-contact-links > a.linkedin::before {
  content: "\e07c";
}
.social-contact-links > a.researchgate::before {
  content: "\e900";
}
.social-contact-links > a.google-scholar::before {
  content: "\e918";
}
.social-contact-links > a.orcid::before {
  content: "\e919";
}
.social-contact-links > a.email {
  word-wrap: break-word;
  word-break: break-all;
}
@media screen and (min-width: 650px) {
  .social-contact-links > a {
    margin-bottom: 0.5rem;
  }
  .social-contact-links.inline > a {
    display: inline-block;
    margin-right: 1.5rem;
  }
}

:is(.cta, .dk-cta) {
  display: block;
  position: relative;
  text-align: right;
  margin-top: 1rem;
  padding-right: 1.3em;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  color: var(--cta-color, #575757);
}
:is(.cta, .dk-cta):where(a) {
  text-decoration: none;
}
:is(.cta, .dk-cta):where(a):is(:link, :visited, :hover, :active) {
  color: var(--cta-color, #575757);
}
:is(.cta, .dk-cta):where(a):is(:hover, :active) {
  text-decoration: underline;
}
:is(.cta, .dk-cta)::after {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e000";
  position: absolute;
  top: calc(50% - 0.5em);
  right: 0;
  color: inherit;
  vertical-align: middle;
  text-decoration: none;
}

.action-link {
  display: block;
  position: relative;
  text-align: left;
  margin: 1rem 0;
  color: #333;
  line-height: 1.6;
}
.action-link, .action-link.thin-box {
  padding: 1rem 0.5rem 1rem 2rem;
}
.action-link::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2rem;
  height: 1.5714285714rem;
  padding: 0;
  transform: translateY(-50%);
  font-size: 1.6rem;
  text-align: center;
}
.action-link a:not(.no-arrow)::after, .action-link.end-arrow::after {
  color: #575757;
  vertical-align: middle;
  text-decoration: none;
  margin-top: -0.1428571429rem;
}
.action-link.end-arrow a::after {
  content: normal;
}
.action-link.no-padding:is(#___hispec, *) {
  padding-left: 2rem;
}
.action-link .eld {
  text-indent: 0;
}

@media screen and (max-width: 649px) {
  .hide-mobile.hide-mobile.hide-mobile.hide-mobile {
    display: none;
  }
  i.hide-mobile {
    font-style: normal;
  }
}
:is(.disclaimer, .caption) p {
  font-size: 0.8rem;
}

.caption p {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}

.abstract, p.abstract {
  font-weight: bold;
  line-height: 1.5;
}

.person-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
}
.person-columns > * {
  margin-bottom: 1.5rem;
}
.person-columns > * strong a i {
  display: inline-block;
  margin-left: 0.3rem;
  color: #575757;
}
.person-columns > * a.email .i-envelope-o {
  display: none;
}
.person-columns > * img {
  margin-left: auto;
  margin-right: auto;
}
.person-columns::after {
  content: "";
}
.person-columns > *, .person-columns::after {
  width: 45%;
}
@media screen and (min-width: 650px) {
  .person-columns > *, .person-columns::after {
    width: 32%;
  }
}
.person-columns::after {
  content: "";
  display: block;
  clear: both;
}

.ratio.r1-1, .ratio.square {
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.ratio.r1-1::after, .ratio.square::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.ratio.r1-1 > *, .ratio.square > * {
  position: absolute;
}
.ratio.r4-3 {
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.ratio.r4-3::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 75%;
}
.ratio.r4-3 > * {
  position: absolute;
}
.ratio.r12-7, .ratio.blog-image {
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.ratio.r12-7::after, .ratio.blog-image::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 58.3333333333%;
}
.ratio.r12-7 > *, .ratio.blog-image > * {
  position: absolute;
}
.ratio.r16-9, .ratio.youtube {
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.ratio.r16-9::after, .ratio.youtube::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56.25%;
}
.ratio.r16-9 > *, .ratio.youtube > * {
  position: absolute;
}
.ratio.golden {
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.ratio.golden::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 61.803398875%;
}
.ratio.golden > * {
  position: absolute;
}

.youtube-container {
  width: 28.5714285714rem;
  display: block;
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.youtube-container::after {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56.25%;
}
.youtube-container > * {
  position: absolute;
}
.youtube-container.full-width {
  width: 100%;
}
.youtube-container.full-width + textarea[readonly] {
  width: 100%;
}
.youtube-container.half {
  width: 50%;
}
.youtube-container.half + textarea[readonly] {
  width: 50%;
}
@media screen and (max-width: 649px) {
  .youtube-container.half {
    width: 100%;
  }
  .youtube-container.half + textarea[readonly] {
    width: 100%;
  }
}
.youtube-container + textarea[readonly] {
  display: block;
  width: 28.5714285714rem;
  margin: 0 0 1rem;
}

.dk-tag {
  margin-right: 0.25rem;
  font-weight: 600;
  line-height: 1.6;
}
.dk-tag.updated {
  background-color: #a76903;
}
.dk-tag.new {
  background-color: #5f8038;
}

.left-image.left-image.left-image h2 {
  margin: 1rem 0;
  padding: 0;
}
.left-image.left-image.left-image h2:first-child {
  margin-top: 0;
}
.left-image.left-image.left-image img {
  display: block;
  margin: 0;
  border: 0;
}
.left-image .shadow img {
  box-shadow: 0 0.1071428571rem 0.3571428571rem rgba(0, 0, 0, 0.13);
}
@media print, screen and (min-width: 650px) {
  .left-image {
    display: flex;
    align-items: flex-start;
  }
  .left-image > :first-child {
    flex: 0 0 auto;
    max-width: calc(30% - 1rem);
    height: auto;
    margin-right: 1rem;
  }
  .left-image > :first-child img {
    width: 100%;
  }
  .left-image > :last-child {
    flex: 1 1 auto;
  }
}
@media screen and (max-width: 649px) {
  .left-image > :first-child:not(:last-child) {
    margin-bottom: 1rem;
  }
  .left-image.float-mobile::after {
    content: "";
    display: block;
    clear: both;
  }
  .left-image.float-mobile > :first-child {
    float: left;
    max-width: calc(50% - 1rem);
    margin: 0.3571428571rem 1rem 0.5rem 0;
  }
}

.previous-next {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin: 3rem 0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  line-height: 1.7;
  border: solid 0 #c0c0c0;
  border-width: 0.1428571429rem 0;
}
.previous-next.no-border {
  border: 0;
}
@media screen and (max-width: 649px) {
  .previous-next {
    font-size: 0.8571428571rem;
  }
}
.previous-next > * {
  display: none;
  padding: 1rem 0.5rem;
  overflow: hidden;
  vertical-align: middle;
  border: solid 0 #c0c0c0;
}
.previous-next > *:first-child, .previous-next > *:last-child {
  display: table-cell;
  width: 50%;
}
.previous-next > *:first-child {
  border-right-width: 2px;
}
.previous-next > *:first-child > div {
  padding-left: 2.5rem;
  margin-left: -0.5rem;
}
.previous-next > *:first-child > div::before {
  content: "\e029";
  left: 0.2142857143rem;
}
@media screen and (max-width: 649px) {
  .previous-next > *:first-child > div {
    padding-left: 1.2857142857rem;
  }
  .previous-next > *:first-child > div::before {
    left: 0;
  }
}
.previous-next > *:last-child {
  border-left-width: 2px;
  text-align: right;
}
.previous-next > *:last-child > div {
  padding-right: 2.5rem;
  margin-right: -0.5rem;
}
.previous-next > *:last-child > div::before {
  content: "\e006";
  right: 0.2142857143rem;
}
@media screen and (max-width: 649px) {
  .previous-next > *:last-child > div {
    padding-right: 1.2857142857rem;
  }
  .previous-next > *:last-child > div::before {
    right: 0;
  }
}
.previous-next > * > div {
  display: block;
  position: relative;
}
.previous-next > * em, .previous-next > * strong {
  display: block;
  font-style: normal;
  font-weight: bold;
}
.previous-next > a, .previous-next > a:visited, .previous-next > a:hover {
  color: #575757;
}
.previous-next > a:hover {
  background-color: #f6f5f5;
  text-decoration: none;
}
.previous-next > * > div::before {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 4rem;
}
@media screen and (max-width: 649px) {
  .previous-next > * > div::before {
    font-size: 2rem;
  }
}

.promo-banner {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: #fff;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2;
  text-shadow: 1px 1px #000;
  margin: 1rem 0;
}
.promo-banner > .text {
  position: relative;
  background-color: inherit;
}
.promo-banner > .text > * > * {
  margin: 0.5rem 0;
}
.promo-banner > .text .medium {
  font-size: 1.2142857143rem;
}
@media screen and (min-width: 320px) and (max-width: 649px) {
  .promo-banner > .text .medium {
    font-size: calc(17px + (21 - 17) * (100vw - 320px) / (649 - 320));
  }
}
@media screen and (min-width: 650px) and (max-width: 889px) {
  .promo-banner > .text .medium {
    font-size: calc(14.45px + (17.85 - 14.45) * (100vw - 650px) / (889 - 650));
  }
}
@media screen and (min-width: 890px) {
  .promo-banner > .text .medium {
    font-size: 1.5rem;
  }
}
.promo-banner > .text .large {
  font-size: 1.8571428571rem;
}
@media screen and (min-width: 320px) and (max-width: 649px) {
  .promo-banner > .text .large {
    font-size: calc(26px + (30 - 26) * (100vw - 320px) / (649 - 320));
  }
}
@media screen and (min-width: 650px) and (max-width: 889px) {
  .promo-banner > .text .large {
    font-size: calc(22.1px + (25.5 - 22.1) * (100vw - 650px) / (889 - 650));
  }
}
@media screen and (min-width: 890px) {
  .promo-banner > .text .large {
    font-size: 2.1428571429rem;
  }
}
.promo-banner > .text p {
  color: inherit;
  line-height: 1.5;
}
.promo-banner img {
  width: auto;
  height: auto;
}
@media screen and (max-width: 649px) {
  .promo-banner {
    max-width: 42.8571428571rem;
  }
  .promo-banner > .text {
    padding: 1rem 5%;
  }
  .promo-banner > .text::before, .promo-banner > .text::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-color: inherit;
  }
  .promo-banner > .text::before {
    height: 1.4285714286rem;
    opacity: 0.2;
  }
  .promo-banner > .text::after {
    height: 0.3571428571rem;
    opacity: 0.5;
  }
  .promo-banner > .text:first-child::before, .promo-banner > .text:first-child::after {
    top: 100%;
  }
  .promo-banner > .text:last-child::before {
    top: -1.4285714286rem;
  }
  .promo-banner > .text:last-child::after {
    top: -0.3571428571rem;
  }
  .promo-banner > .chevron {
    display: none;
  }
  .promo-banner img {
    display: block;
    max-width: 100%;
  }
}
@media screen and (min-width: 650px) {
  .promo-banner {
    height: 15.7142857143rem;
    max-width: 47.1428571429rem;
  }
  .promo-banner > .text, .promo-banner > .image {
    width: 50%;
    height: 15.7142857143rem;
    float: left;
  }
  .promo-banner > .image {
    text-align: center;
  }
  .promo-banner > .image > div {
    width: 150%;
    margin-left: -25%;
    text-align: center;
  }
  .promo-banner > .image img {
    max-width: 42.8571428571rem;
    max-height: 15.7142857143rem;
  }
  .promo-banner > .text {
    display: table;
  }
  .promo-banner > .text:first-child {
    padding-left: 5%;
  }
  .promo-banner > .text:last-child {
    padding: 0 3% 0 5%;
  }
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  .promo-banner > .text:last-child {
    padding: 0 3% 0 2%;
  }
  .promo-banner > .text::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: inherit;
  }
  .promo-banner > .text:first-child::after {
    right: -1px;
  }
  .promo-banner > .text:last-child::after {
    left: -1px;
  }
}
@media screen and (min-width: 650px) {
  .promo-banner > .text > * {
    display: table-cell;
    vertical-align: middle;
  }
  .promo-banner > .chevron {
    top: -2.8571428571rem;
    border-color: inherit;
  }
  .promo-banner > .chevron, .promo-banner > .chevron > * {
    display: block;
    position: absolute;
  }
  .promo-banner > .chevron::before, .promo-banner > .chevron::after, .promo-banner > .chevron > *::before {
    content: "";
    display: block;
    position: absolute;
    border: solid transparent;
  }
  .promo-banner > .chevron.top {
    top: -4.2857142857rem;
  }
  .promo-banner > .chevron.bottom {
    top: -1.4285714286rem;
  }
  .promo-banner > .text + .chevron {
    left: 50%;
  }
  .promo-banner > .text + .chevron::before {
    top: -4.2857142857rem;
    border-width: 15rem 0 15rem 3.9285714286rem;
    border-left-color: inherit;
    opacity: 0.2;
  }
  .promo-banner > .text + .chevron::after {
    border-width: 10.7142857143rem 0 10.7142857143rem 2.5rem;
    border-left-color: inherit;
    opacity: 0.5;
  }
  .promo-banner > .text + .chevron > * {
    top: 1.4285714286rem;
    left: 0;
    border-color: inherit;
  }
  .promo-banner > .text + .chevron > *::before {
    border-width: 9.2857142857rem 0 9.2857142857rem 2.1428571429rem;
    border-left-color: inherit;
  }
  .promo-banner > .image + .chevron {
    left: calc(50% - 2.1428571429rem);
  }
  .promo-banner > .image + .chevron::before {
    top: -4.2857142857rem;
    left: -1.7857142857rem;
    border-width: 15rem 3.9285714286rem 15rem 0;
    border-right-color: inherit;
    opacity: 0.2;
  }
  .promo-banner > .image + .chevron::after {
    left: -0.3571428571rem;
    border-width: 10.7142857143rem 2.5rem 10.7142857143rem 0;
    border-right-color: inherit;
    opacity: 0.5;
  }
  .promo-banner > .image + .chevron > * {
    top: 1.4285714286rem;
    right: 0;
    border-color: inherit;
  }
  .promo-banner > .image + .chevron > *::before {
    border-width: 9.2857142857rem 2.1428571429rem 9.2857142857rem 0;
    border-right-color: inherit;
  }
}

.promo-hero {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: #fff;
  font-size: 1rem;
  line-height: 1.2;
  text-shadow: 1px 1px #000;
  margin: 1rem 0;
}
.promo-hero > .text {
  position: relative;
  background-color: inherit;
}
.promo-hero > .text > * > * {
  margin: 1rem 0;
}
.promo-hero > .text .medium, .promo-hero > .text h3 {
  font-size: 1.2142857143rem;
}
@media screen and (min-width: 320px) and (max-width: 649px) {
  .promo-hero > .text .medium, .promo-hero > .text h3 {
    font-size: calc(17px + (21 - 17) * (100vw - 320px) / (649 - 320));
  }
}
@media screen and (min-width: 650px) and (max-width: 889px) {
  .promo-hero > .text .medium, .promo-hero > .text h3 {
    font-size: calc(14.45px + (17.85 - 14.45) * (100vw - 650px) / (889 - 650));
  }
}
@media screen and (min-width: 890px) {
  .promo-hero > .text .medium, .promo-hero > .text h3 {
    font-size: 1.5rem;
  }
}
.promo-hero > .text .large, .promo-hero > .text :is(h1, h2) {
  font-size: 1.8571428571rem;
}
@media screen and (min-width: 320px) and (max-width: 649px) {
  .promo-hero > .text .large, .promo-hero > .text :is(h1, h2) {
    font-size: calc(26px + (30 - 26) * (100vw - 320px) / (649 - 320));
  }
}
@media screen and (min-width: 650px) and (max-width: 889px) {
  .promo-hero > .text .large, .promo-hero > .text :is(h1, h2) {
    font-size: calc(22.1px + (25.5 - 22.1) * (100vw - 650px) / (889 - 650));
  }
}
@media screen and (min-width: 890px) {
  .promo-hero > .text .large, .promo-hero > .text :is(h1, h2) {
    font-size: 2.1428571429rem;
  }
}
.promo-hero > .text p {
  color: inherit;
  line-height: 1.5;
}
.promo-hero img {
  width: auto;
  height: auto;
}
@media screen and (max-width: 649px) {
  .promo-hero {
    max-width: 42.8571428571rem;
  }
  .promo-hero > .text {
    padding: 1rem 5%;
  }
  .promo-hero > .text::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 0.3571428571rem;
    background-color: inherit;
    opacity: 0.5;
  }
  .promo-hero > .text:first-child::after {
    top: 100%;
  }
  .promo-hero > .text:last-child::after {
    top: -0.3571428571rem;
  }
  .promo-hero > .chevron {
    display: none;
  }
  .promo-hero img {
    display: block;
    max-width: 100%;
  }
}
@media screen and (min-width: 650px) {
  .promo-hero {
    height: 19.2857142857rem;
    max-width: 47.1428571429rem;
  }
  .promo-hero > .text, .promo-hero > .image {
    width: 50%;
    height: 19.2857142857rem;
    float: left;
  }
  .promo-hero > .image {
    text-align: center;
  }
  .promo-hero > .image > div {
    width: 150%;
    margin-left: -25%;
    text-align: center;
  }
  .promo-hero > .image img {
    max-width: 42.8571428571rem;
    max-height: 19.2857142857rem;
  }
  .promo-hero > .text {
    display: table;
    padding: 0 3% 0 5%;
  }
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  .promo-hero > .text::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: inherit;
  }
  .promo-hero > .text:first-child::after {
    right: -1px;
  }
  .promo-hero > .text:last-child::after {
    left: -1px;
  }
}
@media screen and (min-width: 650px) {
  .promo-hero > .text > * {
    display: table-cell;
    vertical-align: middle;
  }
  .promo-hero > .chevron {
    top: -3.5714285714rem;
  }
  .promo-hero > .chevron, .promo-hero > .chevron > * {
    display: block;
    position: absolute;
    bottom: 0;
    background-color: inherit;
    border-color: inherit;
  }
  .promo-hero > .chevron > * {
    top: 0;
    width: 0.3571428571rem;
    opacity: 0.5;
  }
  .promo-hero > .chevron::before, .promo-hero > .chevron::after {
    content: "";
    display: block;
    position: absolute;
    border: solid transparent;
  }
  .promo-hero > .chevron.top {
    top: -7.1428571429rem;
  }
  .promo-hero > .chevron.bottom {
    top: 0;
  }
  .promo-hero > .text + .chevron {
    left: 50%;
  }
  .promo-hero > .text + .chevron::before, .promo-hero > .text + .chevron::after {
    border-width: 13.2142857143rem 2.5rem 13.2142857143rem 0;
    border-top-color: inherit;
    border-bottom-color: inherit;
  }
  .promo-hero > .text + .chevron::before {
    left: 0.3571428571rem;
    opacity: 0.5;
  }
  .promo-hero > .image + .chevron {
    left: calc(50% - 2.5rem);
  }
  .promo-hero > .image + .chevron::before, .promo-hero > .image + .chevron::after {
    border-width: 13.2142857143rem 0 13.2142857143rem 2.5rem;
    border-top-color: inherit;
    border-bottom-color: inherit;
  }
  .promo-hero > .image + .chevron::before {
    left: -0.3571428571rem;
    opacity: 0.5;
  }
  .promo-hero > .image + .chevron > * {
    left: 2.1428571429rem;
  }
}

q:is(.left, .right, .center), blockquote:where(:not(.roundbox)), q.center:is(.left, .right, .center), blockquote.center:where(:not(.roundbox)) {
  display: block;
  float: none;
  border: 0 solid #5f8038;
  border-left-width: 0.5rem;
  margin: 1rem 0;
  padding-left: 1.5rem;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  font-size: 1.1428571429rem;
  line-height: 1.8;
}
q.left:is(.left, .right, .center), blockquote.left:where(:not(.roundbox)) {
  margin-right: 1.5rem;
  padding: 0 1.5rem 0 0;
  border-width: 0 0.5rem 0 0;
}
q.left:is(.left, .right, .center), blockquote.left:where(:not(.roundbox)) {
  display: block;
  float: left;
  margin-right: 5.8515062746%;
  width: 36.4890962352%;
}
q.left:last-child:is(.left, .right, .center), blockquote.left:last-child:where(:not(.roundbox)) {
  margin-right: 0;
}
q.left:is(.left, .right, .center), blockquote.left:where(:not(.roundbox)) {
  float: left;
  clear: left;
}
q.right:is(.left, .right, .center), blockquote.right:where(:not(.roundbox)) {
  margin-left: 1.5rem;
}
q.right:is(.left, .right, .center), blockquote.right:where(:not(.roundbox)) {
  display: block;
  float: left;
  margin-right: 5.8515062746%;
  width: 36.4890962352%;
}
q.right:last-child:is(.left, .right, .center), blockquote.right:last-child:where(:not(.roundbox)) {
  margin-right: 0;
}
q.right:is(.left, .right, .center), blockquote.right:where(:not(.roundbox)) {
  margin-right: 0;
}
q.right:is(.left, .right, .center), blockquote.right:where(:not(.roundbox)) {
  float: right;
  clear: right;
}
@media screen and (max-width: 649px) {
  q:is(.left, .right):is(.left, .right, .center), blockquote:is(.left, .right):where(:not(.roundbox)) {
    margin: 1rem 0;
    border-width: 0 0 0 0.5rem;
    padding: 0 0 0 1.5rem;
  }
  q:is(.left, .right):is(.left, .right, .center), blockquote:is(.left, .right):where(:not(.roundbox)) {
    display: block;
    float: left;
    margin-right: 38.1982152132%;
    width: 100%;
  }
  q:is(.left, .right):last-child:is(.left, .right, .center), blockquote:is(.left, .right):last-child:where(:not(.roundbox)) {
    margin-right: 0;
  }
  q:is(.left, .right):is(.left, .right, .center), blockquote:is(.left, .right):where(:not(.roundbox)) {
    float: none;
    clear: both;
  }
}

q::before {
  content: "“";
}
q::after {
  content: "”";
}
q:is(.left, .right, .center) {
  display: block;
}
q:is(.left, .right, .center)::before, q:is(.left, .right, .center)::after {
  content: none;
}

article.dk-content aside:not(.references), article.dk-content .callout, article.dk-content .right.callout {
  padding: 1.5rem;
  background-color: #f2f2f2;
  margin: 1rem;
  margin-right: 0;
}
article.dk-content aside:not(.references), article.dk-content .callout, article.dk-content .right.callout {
  display: block;
  float: left;
  margin-right: 5.8515062746%;
  width: 36.4890962352%;
}
article.dk-content aside:last-child:not(.references), article.dk-content .callout:last-child {
  margin-right: 0;
}
article.dk-content aside:not(.references), article.dk-content .callout, article.dk-content .right.callout {
  float: right;
  clear: right;
}
article.dk-content aside.left:not(.references), article.dk-content .left.callout {
  float: left;
  clear: left;
  margin-right: 1rem;
  margin-left: 0;
}
article.dk-content aside.center:not(.references), article.dk-content .center.callout {
  float: none;
  width: 100%;
  margin-left: 0;
}
@media screen and (max-width: 649px) {
  article.dk-content aside.left:not(.references), article.dk-content .left.callout, article.dk-content aside.right:not(.references), article.dk-content .right.callout {
    margin: 1rem 0;
  }
  article.dk-content aside.left:not(.references), article.dk-content .left.callout, article.dk-content aside.right:not(.references), article.dk-content .right.callout {
    display: block;
    float: left;
    margin-right: 38.1982152132%;
    width: 100%;
  }
  article.dk-content aside.left:last-child:not(.references), article.dk-content .left.callout:last-child, article.dk-content aside.right:last-child:not(.references), article.dk-content .right.callout:last-child {
    margin-right: 0;
  }
  article.dk-content aside.left:not(.references), article.dk-content .left.callout, article.dk-content aside.right:not(.references), article.dk-content .right.callout {
    float: none;
    clear: both;
  }
}

article.dk-content aside:not(.references) :first-child, article.dk-content .callout :first-child {
  margin-top: 0;
}
article.dk-content aside:not(.references) :last-child, article.dk-content .callout :last-child {
  margin-bottom: 0;
}
article.dk-content aside:not(.references) {
  margin-top: 0;
}
article.dk-content .references > * {
  font-size: 0.8571428571rem;
  line-height: 1.6;
}

:is(ul, ol).result-items > li {
  list-style: none;
  margin: 0;
  padding: 0.7142857143rem;
  font: normal 1rem "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
}
:is(ul, ol).result-items > li:nth-child(2n+1) {
  background-color: #f2f2f2;
}
:is(ul, ol).result-items > li:first-child:last-child {
  background-color: transparent;
}
:is(ul, ol).result-items > li .top {
  padding-bottom: 0.3rem;
  border-bottom: solid 1px #c0c0c0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
:is(ul, ol).result-items > li .top .right {
  float: right;
}
:is(ul, ol).result-items > li .top :is(h3, h4) {
  margin: 0;
  font-weight: bold;
}
:is(ul, ol).result-items > li :is(h3, h4) {
  margin: 0.5rem 0;
  font: inherit;
}
:is(ul, ol).result-items > li ul {
  list-style-type: disc;
  margin: 0.3em 0;
}
:is(ul, ol).result-items > li ul ul {
  list-style-type: circle;
}
:is(ul, ol).result-items > li > div {
  margin: 0.3em 0;
}
:is(ul, ol).result-items > li > div p {
  line-height: 1.5;
  margin: 0.3em 0;
}

table.result-items {
  margin: 1rem 0;
}
table.result-items, table.result-items :is(thead, tbody, tfoot, caption, tr, th, td) {
  display: block;
  border: 0;
}
table.result-items :is(thead, tfoot) {
  position: absolute;
  top: -5000px;
  left: -5000px;
  z-index: -100;
}
table.result-items caption {
  margin: 0 0 1rem;
}
table.result-items tr {
  padding: 0.5rem;
}
table.result-items tr:nth-child(2n+1) {
  background-color: #f2f2f2;
}
table.result-items tr:first-child:last-child, table.result-items tr:first-child:last-child td {
  background-color: transparent;
}
table.result-items td[data-prefix]::before {
  content: attr(data-prefix) ": ";
}

.result-items.staff {
  line-height: 1.5;
}
.result-items.staff tr {
  padding-bottom: 0;
}
.result-items.staff tr::before, .result-items.staff tr::after {
  content: "";
  display: table;
}
.result-items.staff td {
  clear: both;
  margin: 0 0 0.5rem;
  padding: 0;
}
.result-items.staff td:empty {
  display: none;
}
.result-items.staff .criteria {
  font-weight: bold;
}
.result-items.staff .term::before {
  content: "‘";
}
.result-items.staff .term::after {
  content: "’";
}
.result-items.staff :is(.name, .email, .phone) {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.result-items.staff .name {
  font-weight: bold;
}
.result-items.staff :is(.email, .phone) {
  float: left;
  clear: none;
  margin-top: 0;
}
.result-items.staff :is(.email, .phone) a::before {
  padding-right: 0.5rem;
  color: #575757;
}
.result-items.staff .email {
  min-width: 35%;
  padding-right: 2rem;
}
.result-items.staff .email a::before {
  content: "\e02a";
  font-size: 0.9em;
}
.result-items.staff .phone a::before {
  content: "\e022";
}
.result-items.staff .role {
  padding-left: 1rem;
  text-indent: -1rem;
}
.result-items.staff .role ul {
  list-style: none;
  margin: 0;
}
.result-items.staff .role li {
  margin: 0;
  text-indent: -1rem;
}
.result-items.staff .role * {
  text-indent: 0;
}
.result-items.staff .role p {
  margin: 0.5 0;
}

.result-items.health-resources li {
  padding: 1rem 0.7142857143rem;
}
.result-items.health-resources h3 {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-weight: bold;
  border-bottom: solid 1px #c0c0c0;
  padding-bottom: 0.3rem;
  margin: 0 0 0.3rem;
}
.result-items.health-resources h3 .file-size {
  font-weight: normal;
}
.result-items.health-resources h3 .file-size::before {
  content: "(";
}
.result-items.health-resources h3 .file-size::after {
  content: ")";
}
.result-items.health-resources h3 .i-audio::before, .result-items.health-resources h3 .i-video::before {
  font-size: 1.4em;
  vertical-align: top;
}
.result-items.health-resources h3 i {
  margin-left: 0.3em;
}

.result-items.funding-opportunities .top a {
  float: right;
}
.result-items.funding-opportunities .bottom::before {
  content: "(";
}
.result-items.funding-opportunities .bottom::after {
  content: ")";
}

.result-items.research-materials p, .result-items.research-materials div:not(.top) {
  margin: 0.3rem 0;
  line-height: 1.5;
}
.result-items.research-materials p label, .result-items.research-materials p .label, .result-items.research-materials div:not(.top) label, .result-items.research-materials div:not(.top) .label {
  font-weight: bold;
}
.result-items.research-materials .bottom::before {
  content: "(";
}
.result-items.research-materials .bottom::after {
  content: ")";
}

.result-items.activity-codes :is(h3, h4) {
  margin: 0;
  font-weight: bold;
}
.result-items.activity-codes :is(p, div) {
  margin: 0.3rem 0;
  line-height: 1.75;
}

.result-items.itp-locations h3 {
  font-weight: bold;
  border-bottom: solid 1px #c0c0c0;
  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
}
.result-items.itp-locations label, .result-items.itp-locations .label {
  font-weight: bold;
}
.result-items.itp-locations li > div {
  margin: 0.3rem 0;
  line-height: 1.5;
}
@media screen and (min-width: 650px) {
  .result-items.itp-locations li > div .row {
    display: block;
  }
  .result-items.itp-locations li > div .row::after {
    content: "";
    display: block;
    clear: both;
  }
  .result-items.itp-locations li > div .row > * {
    display: block;
    float: left;
  }
  .result-items.itp-locations li > div .row > :nth-child(2n) {
    max-width: 7rem;
    margin-left: 1rem;
  }
  .result-items.itp-locations li > div .row > :nth-child(2n+1) {
    width: calc(100% - 8rem);
  }
}

.result-items.healthy-moments > li time {
  display: block;
  margin: 0.3em 0;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.result-items.healthy-moments .audio-pill {
  margin: 0.5rem 0;
}

.result-items.youtube .i-video {
  position: relative;
  top: 1px;
  color: #ff0000;
  font-size: 1.3em;
  vertical-align: middle;
  line-height: 1;
}

.result-items.meetings .bottom {
  margin-top: 1rem;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.result-items.meetings .bottom span {
  display: inline-block;
  line-height: 1.7;
}
.result-items.meetings .bottom span:not(:last-child) {
  margin-right: 2rem;
}
.result-items.meetings .date::before {
  content: "\e078";
  margin-right: 0.5em;
}
.result-items.meetings .location::before {
  content: "\e023";
  margin-right: 0.5em;
}
.result-items.meetings .webinar::before {
  content: "\e911";
  margin-right: 0.5em;
}

.result-items.news .top {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  padding-bottom: 0.75rem;
}
.result-items.news .top .news-type {
  margin-right: 0.7142857143rem;
}
.result-items.news h3 {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-weight: bold;
}

.stack-icon {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  font-style: normal;
}
.stack-icon::before, .stack-icon::after {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 50px;
}
.stack-icon::before {
  content: "\e050";
}
.stack-icon::after {
  content: "\e053";
  color: #fff;
}
.stack-icon.blank::after {
  font-size: 60px;
  top: -5px;
  left: -5px;
}
.stack-icon.blank::before {
  content: normal;
}
.stack-icon.square::before {
  content: "\e050";
}
.stack-icon.circle::before {
  content: "\e048";
}
.stack-icon.pentagon-arrow::before {
  content: "\e04f";
}
.stack-icon.hexagon::before {
  content: "\e04e";
}
.stack-icon.empty::after {
  content: normal;
}
.stack-icon.cells::after {
  content: "\e051";
}
.stack-icon.clipboard::after {
  content: "\e046";
}
.stack-icon.collaboration::after {
  content: "\e052";
}
.stack-icon.dna::after {
  content: "\e045";
}
.stack-icon.generic::after {
  content: "\e053";
}
.stack-icon.examine-report::after {
  content: "\e054";
}
.stack-icon.funding::after {
  content: "\e055";
}
.stack-icon.grant-apply::after {
  content: "\e056";
}
.stack-icon.grant-award::after {
  content: "\e057";
}
.stack-icon.grant-examine::after {
  content: "\e058";
}
.stack-icon.grant-fund::after {
  content: "\e059";
}
.stack-icon.grant-funding::after {
  content: "\e05a";
}
.stack-icon.grant-manage::after {
  content: "\e05b";
}
.stack-icon.grant-review::after {
  content: "\e05c";
}
.stack-icon.handshake::after {
  content: "\e05d";
}
.stack-icon.id-badge::after {
  content: "\e064";
}
.stack-icon.lightbulb::after {
  content: "\e063";
}
.stack-icon.medical-microscope::after {
  content: "\e062";
}
.stack-icon.molecule::after {
  content: "\e04a";
}
.stack-icon.mortar-pestle::after {
  content: "\e049";
}
.stack-icon.mortarboard::after {
  content: "\e061";
}
.stack-icon.mouse::after {
  content: "\e04c";
}
.stack-icon.new-investigator::after {
  content: "\e060";
}
.stack-icon.newspaper::after {
  content: "\e05f";
}
.stack-icon.node-cluster::after {
  content: "\e05e";
}
.stack-icon.people-discussing::after {
  content: "\e065";
}
.stack-icon.people-funding::after {
  content: "\e065";
}
.stack-icon.person-presenting::after {
  content: "\e066";
}
.stack-icon.robotic-arm::after {
  content: "\e04d";
}
.stack-icon.small-business::after {
  content: "\e067";
}
.stack-icon.test-tubes::after {
  content: "\e047";
}
.stack-icon.tools::after {
  content: "\e068";
}
.stack-icon.additional-reading::after {
  content: "\e06e";
}
.stack-icon.toolbox::after {
  content: "\e06f";
}
.stack-icon.weight-management::after {
  content: "\e070";
}
.stack-icon.school-responsibilities::after {
  content: "\e071";
}
.stack-icon.risk-factors::after {
  content: "\e072";
}
.stack-icon.checklists::after {
  content: "\e073";
}
.stack-icon.blood-screening::after {
  content: "\e074";
}
.stack-icon.diabetes::after {
  content: "\e075";
}
.stack-icon.download-file::after {
  content: "\e076";
}
.stack-icon.kidney-disease::after {
  content: "\e077";
}
.stack-icon.clinical-trials::after {
  content: "\e902";
}
.stack-icon.research-materials::after {
  content: "\e904";
}
.stack-icon.publications::after {
  content: "\e903";
}
.stack-icon.repository::after {
  content: "\e913";
}
.stack-icon.lab-software::after {
  content: "\e91a";
}
.stack-icon.lab-servers::after {
  content: "\e91b";
}
.stack-icon.pulse-programs::after {
  content: "\e91c";
}
.stack-icon.size-fill::after {
  content: "\e90c";
}
.stack-icon.standards::after {
  content: "\e91e";
}
.stack-icon.webinar::after {
  content: "\e921";
}
.stack-icon.green {
  --accent-fg-color: #5f8038;
}
.stack-icon.indigo {
  --accent-fg-color: #24325f;
}
.stack-icon.blue {
  --accent-fg-color: #1568af;
}
.stack-icon.orange {
  --accent-fg-color: #a76903;
}
.stack-icon.gray {
  --accent-fg-color: #808080;
}
.stack-icon.teal {
  --accent-fg-color: #45a9ba;
}
.stack-icon.mauve {
  --accent-fg-color: #bd899b;
}
.stack-icon.lavender {
  --accent-fg-color: #636e8f;
}
.stack-icon.teal-dark {
  --accent-fg-color: #307682;
}
.stack-icon.mauve-dark {
  --accent-fg-color: #a05972;
}
.stack-icon.lavender-dark {
  --accent-fg-color: #444b62;
}
.stack-icon.cyan {
  --accent-fg-color: #547988;
}
.stack-icon.dirblue {
  --accent-fg-color: #0072bc;
}
.stack-icon.sea {
  --accent-fg-color: #55b9ad;
}
.stack-icon.bondi {
  --accent-fg-color: #03a2c2;
}
.stack-icon.orchid {
  --accent-fg-color: #6f7da4;
}
.stack-icon.gold {
  --accent-fg-color: #e19a0f;
}
.stack-icon.wine {
  --accent-fg-color: #9e6c7d;
}
.stack-icon.empty.black::before, .stack-icon.blank.black::after {
  color: #000;
}
.stack-icon.pentagon-arrow::after {
  left: -1px;
}
.stack-icon.twitter.twitter.twitter::before {
  content: normal;
}
.stack-icon.twitter.twitter.twitter::after {
  content: "\e02b";
  color: #1d9bf0;
}
.stack-icon.facebook.facebook.facebook::before {
  content: normal;
}
.stack-icon.facebook.facebook.facebook::after {
  content: "\e01b";
  color: #1877f2;
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  .stack-icon {
    width: 42px;
    height: 42px;
  }
  .stack-icon::before, .stack-icon::after {
    font-size: 42px;
  }
  .stack-icon.blank::after {
    font-size: 50px;
    top: -4px;
    left: -4px;
  }
}

.stack-icon-heading {
  display: flex;
  margin: 2rem 0 1rem;
  align-items: center;
}
.stack-icon-heading .stack-icon {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 0.7142857143rem;
}
.stack-icon-heading > * {
  margin-top: 0;
  margin-bottom: 0;
}

.steps-list {
  counter-reset: steps-list;
}
.steps-list.letters > *::before {
  content: counter(steps-list, upper-alpha);
}
.steps-list.square > *::before {
  border-radius: 0.3571428571rem;
}
.steps-list.green {
  --accent-bg-color: #5f8038;
}
.steps-list.indigo {
  --accent-bg-color: #24325f;
}
.steps-list.blue {
  --accent-bg-color: #1568af;
}
.steps-list.orange {
  --accent-bg-color: #a76903;
}
.steps-list.gray {
  --accent-bg-color: #808080;
}
.steps-list.teal {
  --accent-bg-color: #45a9ba;
}
.steps-list.mauve {
  --accent-bg-color: #bd899b;
}
.steps-list.lavender {
  --accent-bg-color: #636e8f;
}
.steps-list.teal-dark {
  --accent-bg-color: #307682;
}
.steps-list.mauve-dark {
  --accent-bg-color: #a05972;
}
.steps-list.lavender-dark {
  --accent-bg-color: #444b62;
}
.steps-list.cyan {
  --accent-bg-color: #547988;
}
.steps-list.dirblue {
  --accent-bg-color: #0072bc;
}
.steps-list.sea {
  --accent-bg-color: #55b9ad;
}
.steps-list.bondi {
  --accent-bg-color: #03a2c2;
}
.steps-list.orchid {
  --accent-bg-color: #6f7da4;
}
.steps-list.gold {
  --accent-bg-color: #e19a0f;
}
.steps-list.wine {
  --accent-bg-color: #9e6c7d;
}
.steps-list.accent-blue-green {
  --accent-bg-color: #085863;
}
.steps-list.loose > * + * {
  margin-top: 1.5rem;
}
.steps-list.loose ul:not(.loose) > li, .steps-list.loose ol:not(.loose) > li {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
.steps-list > * {
  display: block;
  list-style: none;
  position: relative;
  margin: 1rem 0;
  padding: 0;
  min-height: 2.8571428571rem;
  padding-left: 3.5714285714rem;
}
.steps-list > *::before {
  width: 2.8571428571rem;
  height: 2.8571428571rem;
  font-size: 1.7142857143rem;
}
.steps-list > *::before {
  content: counter(steps-list);
  display: block;
  position: absolute;
  left: 0;
  border-radius: 50%;
  color: #fff;
  font-family: "Montserrat", "Montserrat-local", "Montserrat-fallback", Verdana, Helvetica, sans-serif;
  line-height: 1.6666666667;
  text-align: center;
  font-style: normal;
  counter-increment: steps-list;
}
.steps-list > * > .title {
  font-weight: bold;
}
.steps-list > * > .bigger {
  font-size: 1.1428571429rem;
}
@media screen and (min-width: 650px) {
  .steps-list.large-bullets > * {
    min-height: 3.5714285714rem;
    padding-left: 4.2857142857rem;
  }
  .steps-list.large-bullets > *::before {
    width: 3.5714285714rem;
    height: 3.5714285714rem;
    font-size: 2.1428571429rem;
  }
}

.thumbnail-list > * > a > *:not(img), .thumbnail-list > * > *:not(img):not(a):not(.stack-icon), .thumbnail-list > * > div > p {
  margin: 0 0 0.3rem;
  line-height: 1.4;
}

.stack-icon-blocks > *, .stack-icon-block, .thumbnail-list > * {
  display: block;
  list-style: none;
  position: relative;
  min-height: 3.5714285714rem;
  margin: 1rem 0;
  padding: 0 0 0 4.2857142857rem;
}
.stack-icon-blocks > * .stack-icon, .stack-icon-block .stack-icon, .thumbnail-list > * .stack-icon {
  display: block;
  position: absolute;
  left: 0;
}
.stack-icon-blocks > * a.detailed-download > *, .stack-icon-block a.detailed-download > *, .thumbnail-list > * a.detailed-download > * {
  margin-bottom: 0;
}

ul.thumbnail-list > li > ul, ul.thumbnail-list > li > ol, ul.stack-icon-blocks > li > ul, ul.stack-icon-blocks > li > ol, ul > .stack-icon-block > li > ul, ul > .stack-icon-block > li > ol, ol.thumbnail-list > li > ul, ol.thumbnail-list > li > ol, ol.stack-icon-blocks > li > ul, ol.stack-icon-blocks > li > ol, ol > .stack-icon-block > li > ul, ol > .stack-icon-block > li > ol {
  margin-left: 0;
}

.thumbnail-list > * > *:not(img):not(a):not(.stack-icon):last-child, .thumbnail-list > * > div > p:last-child {
  margin-bottom: 0;
}
.thumbnail-list > * > .title {
  font-weight: bold;
}
.thumbnail-list > * > a:last-child > *:not(img) {
  margin-bottom: 0;
}
.thumbnail-list > * img:first-of-type {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  max-width: 3.5714285714rem;
}
.thumbnail-list > * img:not(:first-of-type) {
  display: none;
}

.dk-accdn, .accordion {
  margin: 2rem 0;
}
h1 + .dk-accdn, h1 + .accordion {
  margin-top: 1rem;
}

h2 + .dk-accdn, h2 + .accordion {
  margin-top: 1rem;
}

h3 + .dk-accdn, h3 + .accordion {
  margin-top: 1rem;
}

h4 + .dk-accdn, h4 + .accordion {
  margin-top: 1rem;
}

h5 + .dk-accdn, h5 + .accordion {
  margin-top: 1rem;
}

.dk-accdn .acc-title, .accordion > h6:first-child, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]), .accordion > h5:first-child, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]), .accordion > h4:first-child, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]), .accordion > h3:first-child, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]), .accordion > h2:first-child, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]), .dk-accdn .acc-main, .accordion {
  border-width: 0;
  border-style: solid;
}

.dk-accdn .acc-main, .accordion {
  border-width: 1px;
  background-color: #fff;
}

.dk-accdn .acc-title button, .dk-accdn .acc-title.nobtn, .accordion > h6:first-child, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]), .accordion > h5:first-child, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]), .accordion > h4:first-child, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]), .accordion > h3:first-child, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]), .accordion > h2:first-child, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]) {
  display: block;
  position: relative;
  margin: 0;
  padding: 0.9rem 3.5rem 0.9rem 1rem;
  background-color: transparent;
  font: bold 1rem/normal "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  text-align: left;
  color: #333;
}

.dk-accdn .acc-title button:hover {
  background-color: rgb(216.5, 216.5, 216.5);
}
.dk-accdn .acc-title button::after {
  content: "\e002";
  display: block;
  position: absolute;
  top: 50%;
  right: 1rem;
  font: 2rem/1 "dki";
  transform: translateY(-50%);
}

.dk-accdn:not([data-ready]) .acc-title:target button, .dk-accdn .acc-title.open button {
  background-color: #f2f2f2;
}
.dk-accdn:not([data-ready]) .acc-title:target button:hover, .dk-accdn .acc-title.open button:hover {
  background-color: rgb(216.5, 216.5, 216.5);
}
.dk-accdn:not([data-ready]) .acc-title:target button::after, .dk-accdn .acc-title.open button::after {
  content: "\e003";
}

h5 + .accordion:not([data-no-expand-all]):not(.off), h4 + .accordion:not([data-no-expand-all]):not(.off), h3 + .accordion:not([data-no-expand-all]):not(.off), h2 + .accordion:not([data-no-expand-all]):not(.off), h1 + .accordion:not([data-no-expand-all]):not(.off) {
  margin-top: 3rem;
}

.accordion {
  padding: 0 1rem;
}
.accordion.accordion > * {
  display: none;
}
.accordion:not([data-no-expand-all]):not(.off) {
  margin-top: 4rem;
}
.accordion > h2:first-child {
  border-top: 0;
}
.accordion > h2:first-child, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]) {
  border-top-width: 1px;
  margin: 0 -1rem;
}
.accordion > h2:first-child:target, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]):target {
  background-color: #f2f2f2;
}
.accordion > h2:first-child:target ~ *, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]):target ~ * {
  display: block;
}
.accordion > h2:first-child:target ~ h2:not(.literal):not([data-literal]):not(:target) ~ :not(h2:not(.literal):not([data-literal])), .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]):target ~ h2:not(.literal):not([data-literal]):not(:target) ~ :not(h2:not(.literal):not([data-literal])) {
  display: none;
}
.accordion > h3:first-child {
  border-top: 0;
}
.accordion > h3:first-child, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]) {
  border-top-width: 1px;
  margin: 0 -1rem;
}
.accordion > h3:first-child:target, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]):target {
  background-color: #f2f2f2;
}
.accordion > h3:first-child:target ~ *, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]):target ~ * {
  display: block;
}
.accordion > h3:first-child:target ~ h3:not(.literal):not([data-literal]):not(:target) ~ :not(h3:not(.literal):not([data-literal])), .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]):target ~ h3:not(.literal):not([data-literal]):not(:target) ~ :not(h3:not(.literal):not([data-literal])) {
  display: none;
}
.accordion > h4:first-child {
  border-top: 0;
}
.accordion > h4:first-child, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]) {
  border-top-width: 1px;
  margin: 0 -1rem;
}
.accordion > h4:first-child:target, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]):target {
  background-color: #f2f2f2;
}
.accordion > h4:first-child:target ~ *, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]):target ~ * {
  display: block;
}
.accordion > h4:first-child:target ~ h4:not(.literal):not([data-literal]):not(:target) ~ :not(h4:not(.literal):not([data-literal])), .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]):target ~ h4:not(.literal):not([data-literal]):not(:target) ~ :not(h4:not(.literal):not([data-literal])) {
  display: none;
}
.accordion > h5:first-child {
  border-top: 0;
}
.accordion > h5:first-child, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]) {
  border-top-width: 1px;
  margin: 0 -1rem;
}
.accordion > h5:first-child:target, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]):target {
  background-color: #f2f2f2;
}
.accordion > h5:first-child:target ~ *, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]):target ~ * {
  display: block;
}
.accordion > h5:first-child:target ~ h5:not(.literal):not([data-literal]):not(:target) ~ :not(h5:not(.literal):not([data-literal])), .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]):target ~ h5:not(.literal):not([data-literal]):not(:target) ~ :not(h5:not(.literal):not([data-literal])) {
  display: none;
}
.accordion > h6:first-child {
  border-top: 0;
}
.accordion > h6:first-child, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]) {
  border-top-width: 1px;
  margin: 0 -1rem;
}
.accordion > h6:first-child:target, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]):target {
  background-color: #f2f2f2;
}
.accordion > h6:first-child:target ~ *, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]):target ~ * {
  display: block;
}
.accordion > h6:first-child:target ~ h6:not(.literal):not([data-literal]):not(:target) ~ :not(h6:not(.literal):not([data-literal])), .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]):target ~ h6:not(.literal):not([data-literal]):not(:target) ~ :not(h6:not(.literal):not([data-literal])) {
  display: none;
}

.dk-accdn:not([data-ready]) .acc-title:target + .acc-content, .dk-accdn .acc-title.open + .acc-content {
  display: block;
}

.dk-accdn .acc-ctrl {
  text-align: right;
  font: 1rem/1.5rem "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.dk-accdn .acc-ctrl button {
  color: #0072bc;
}
.dk-accdn .acc-ctrl button:hover {
  text-decoration: underline;
  color: #004b8d;
}
.dk-accdn .acc-ctrl + .acc-main {
  margin-top: 0.5rem;
}
.dk-accdn .acc-main {
  background-color: #fff;
}
.dk-accdn .acc-title {
  margin: 0;
  border-top-width: 1px;
}
.dk-accdn .acc-title:first-child {
  border: 0;
}
.dk-accdn .acc-title.nobtn {
  background-color: #f2f2f2;
}
.dk-accdn .acc-title.nobtn + .acc-content {
  display: block;
}
.dk-accdn .acc-title button {
  width: 100%;
  outline-offset: -0.2857142857rem;
}
.dk-accdn .acc-content {
  display: none;
  padding: 1rem;
}
.dk-accdn button {
  border: 0;
  padding: 0;
  background: transparent;
  appearance: none;
  text-align: left;
  font: inherit;
  cursor: pointer;
}

.dk-accordion-title {
  display: none;
}

.expandable:not(.ready) {
  position: relative;
}
@media screen and (max-width: 649px) {
  .expandable:not(.ready) > * {
    display: none;
  }
  .expandable:not(.ready)[data-collapse=full] > *, .expandable:not(.ready)[data-collapse=none] > * {
    display: block;
  }
}
@media screen and (min-width: 650px) {
  .expandable:not(.ready) > * {
    display: block;
  }
  .expandable:not(.ready)[data-collapse=full] > *, .expandable:not(.ready)[data-collapse=both] > * {
    display: none;
  }
}
.expandable:not(.ready)::before {
  content: "Show description";
  display: block;
  visibility: hidden;
  font: 1rem/1.8 "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  margin: 1rem 0;
}

.dk-expandable {
  position: relative;
  margin: 1rem 0;
  z-index: 1;
}
.dk-expandable .expandable-toggle {
  border: 0;
  padding: 0;
  background: transparent;
  color: #0072bc;
  font: 1rem/1.8 "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  cursor: pointer;
  appearance: none;
}
.dk-expandable .expandable-toggle:hover {
  color: #004b8d;
  text-decoration: underline;
}
.dk-expandable .expandable-toggle::after {
  content: "\e005";
  margin-left: 0.2857142857rem;
}
.dk-expandable .expandable-toggle::after {
  text-decoration: underline;
}
body .dk-expandable .expandable-toggle::after {
  text-decoration: none;
}

.dk-expandable .expandable-toggle[aria-expanded=true]::after {
  content: "\e007";
}
.dk-expandable .expandable {
  margin: 1rem 0;
}
.dk-expandable .expandable:focus {
  outline: none;
}
@media print {
  .dk-expandable .expandable-toggle {
    display: none;
  }
}

.box .fold, .foldable .fold {
  display: block;
  clear: both;
  cursor: pointer;
}
html:not(.editmode) .box .fold ~ :not(.unfold), html:not(.editmode) .foldable .fold ~ :not(.unfold) {
  display: none;
}

html:not(.editmode) .box .fold ~ .unfold ~ *, html:not(.editmode) .foldable .fold ~ .unfold ~ * {
  display: initial;
}
html:not(.editmode) .box .fold ~ .unfold ~ *.cta, html:not(.editmode) .foldable .fold ~ .unfold ~ *.cta {
  display: block;
}

.box .no-outline:focus, .foldable .no-outline:focus {
  outline: none;
}

.box .fold {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0;
  background-color: #f2f2f2;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1.25rem;
  text-align: center;
}
.box .fold:hover {
  background-color: rgb(216.5, 216.5, 216.5);
}

.dk-main-container .popup {
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
}
.dk-main-container .popup.no-sr {
  display: none;
}

.mfp-bg {
  opacity: 0.3;
}

.mfp-content {
  position: relative;
  padding: 3.5rem 1rem 1rem;
  background-color: #fff;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
}
.mfp-content > * {
  display: block;
}
.mfp-content > *::before {
  content: "Details";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2.5rem;
  line-height: 2.2;
  color: #fff;
  padding-left: 1rem;
  font-family: "Montserrat", "Montserrat-local", "Montserrat-fallback", Verdana, Helvetica, sans-serif;
  font-size: 1.1rem;
  background-image: linear-gradient(0deg, #213882, rgb(30.822, 52.304, 121.42) 72%);
}
.mfp-content > *[data-title]::before {
  content: attr(data-title);
}
.mfp-content > *.no-title:not([data-title])::before {
  content: "";
}
.mfp-content > *.dictionary:not([data-title])::before {
  content: "Dictionary";
}
.mfp-content > *.sample-message:not([data-title])::before {
  content: "Sample Message";
}
@media screen and (min-width: 650px) {
  .mfp-content.mfp-content {
    width: 40rem;
    max-width: 90vw;
  }
}
.mfp-content .mfp-close {
  color: #fff;
  opacity: 1;
  height: 2.5rem;
  width: 2.5rem;
  line-height: 1rem;
}
.mfp-content p {
  line-height: 1.8rem;
}
.mfp-content a, .mfp-content a:visited {
  color: #0072bc;
  text-decoration: none;
}
.mfp-content a:hover, .mfp-content a:active {
  color: #004b8d;
  text-decoration: underline;
}
.mfp-content a.print::before {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e025";
  font-size: 1.4em;
  vertical-align: middle;
  color: #575757;
  margin-right: 0.5rem;
}
.mfp-content a.button {
  text-decoration: none;
  color: #fff;
}
.mfp-content a.eld, .mfp-content i.eld {
  color: #426924;
  font-size: 0;
  text-indent: 5000rem;
}
.mfp-content a.eld::before, .mfp-content i.eld::before {
  font-size: 1rem;
  text-indent: 0;
}

.dk-share-buttons > * {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  font-family: "dki";
  line-height: 1;
  text-rendering: auto;
  text-transform: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border-style: solid;
  border-radius: 50%;
  background-color: #fff;
  font-size: 0;
  appearance: none;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  max-height: 2rem;
  border-width: 2px;
}
.dk-share-buttons > ::before {
  font-size: 1rem;
}
.dk-share-buttons > .shr-copy::before {
  content: "\e916";
}
.dk-share-buttons > .shr-email::before {
  content: "\e004";
}
.dk-share-buttons > .shr-facebook::before {
  content: "\e01c";
}
.dk-share-buttons > .shr-linkedin::before {
  content: "\f0e1";
}
.dk-share-buttons > .shr-more::before {
  content: "\e01a";
}
.dk-share-buttons > .shr-pinterest::before {
  content: "\f231";
}
.dk-share-buttons > .shr-print::before {
  content: "\e025";
}
.dk-share-buttons > .shr-reddit::before {
  content: "\f281";
}
.dk-share-buttons > .shr-twitter::before {
  content: "\e02b";
}
.dk-share-buttons > .shr-whatsapp::before {
  content: "\f232";
}
.dk-share-buttons > .shr-x::before {
  content: "\e922";
}
.dk-share-buttons > :hover {
  color: #fff;
  text-decoration: none;
}
.dk-share-buttons > ::before {
  display: block;
}

.dk-share-buttons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0 5px;
  margin: 0 0 0 0.5rem;
  transition: row-gap 250ms ease-in-out;
}
.dk-share-buttons .shr-more, .dk-share-buttons .shr-more:hover {
  transition: 100ms linear;
  transition-property: color, background-color;
}
.dk-share-buttons .shr-more::before {
  transition: transform 100ms linear;
}
.dk-share-buttons:not(.expanded) .shr-more.shr-more.shr-more ~ * {
  opacity: 0;
  max-height: 0;
  border-width: 0;
  visibility: hidden;
  transition: max-height 250ms ease-in-out 75ms, border-width 250ms ease-in-out, opacity 100ms linear, visibility 0s linear 325ms;
}
.dk-share-buttons.expanded {
  row-gap: 5px;
}
.dk-share-buttons.expanded .shr-more {
  color: #fff;
}
.dk-share-buttons.expanded .shr-more::before {
  transform: rotate(45deg);
}
.dk-share-buttons.expanded .shr-more ~ * {
  opacity: 1;
  visibility: visible;
  transition: max-height 250ms ease-in-out, border-width 250ms ease-in-out, opacity 100ms linear 225ms, visibility 0s linear;
}

.dk-share {
  display: flex;
  flex-wrap: wrap;
  min-height: 2rem;
  margin: 2rem 0;
  line-height: 2rem;
  visibility: hidden;
}
.dk-share.ready {
  visibility: visible;
}
.dk-share > :first-child {
  font: bold 1.2rem Open Sans, Open Sans-local, Open Sans-fallback, Verdana, Helvetica, sans-serif;
  display: block;
  height: 2rem;
  line-height: 2rem;
  margin: 0;
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  .dk-share:not(.no-resize) {
    min-height: 2.8571428571rem;
  }
  .dk-share:not(.no-resize) > :first-child {
    height: 2.8571428571rem;
    line-height: 2.8571428571rem;
  }
  .dk-share:not(.no-resize) .dk-share-buttons > * {
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    max-height: 2.8571428571rem;
    border-width: 2px;
  }
  .dk-share:not(.no-resize) .dk-share-buttons > *::before {
    font-size: 1.4285714286rem;
  }
}
@media screen and (max-width: 649px) {
  .dk-share {
    min-height: 2.8571428571rem;
  }
  .dk-share > :first-child {
    height: 2.8571428571rem;
    line-height: 2.8571428571rem;
  }
  .dk-share .dk-share-buttons > * {
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    max-height: 2.8571428571rem;
    border-width: 2px;
  }
  .dk-share .dk-share-buttons > *::before {
    font-size: 1.4285714286rem;
  }
}

.dk-top-scroll {
  margin-top: 1rem;
  overflow-x: scroll;
  overflow-y: hidden;
}
.dk-top-scroll.hidden {
  display: none;
}
.dk-top-scroll div {
  height: 0;
}

.dk-table-wrap {
  overflow: auto;
  margin: 1rem 0;
}
.dk-table-wrap > table {
  margin-bottom: 0;
}

.dk-top-scroll:not(.hidden) + .dk-table-wrap {
  margin-top: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  empty-cells: show;
  margin-bottom: 1rem;
}
@media screen and (max-width: 649px) {
  table.tablesaw-stack thead td, table.tablesaw-stack thead th {
    display: none;
  }
  table.tablesaw-stack tbody td, table.tablesaw-stack tbody th {
    clear: left;
    float: left;
    width: 100%;
  }
  table.tablesaw-stack td:empty, table.tablesaw-stack th:empty {
    display: none;
  }
  table.tablesaw-stack td .tablesaw-cell-label, table.tablesaw-stack th .tablesaw-cell-label {
    display: block;
    margin-bottom: 0.2857142857rem;
  }
  table.tablesaw-stack td .tablesaw-cell-label-top, table.tablesaw-stack th .tablesaw-cell-label-top {
    display: block;
    padding: 0.4285714286rem 0;
    margin: 0.4285714286rem 0;
  }
  table.tablesaw-stack td .tablesaw-cell-content, table.tablesaw-stack th .tablesaw-cell-content {
    display: block;
  }
  table.tablesaw-stack tr:nth-child(odd) {
    background-color: #fff;
  }
  table.tablesaw-stack tr:not(:last-child) {
    border-bottom: 1px solid rgb(216.5, 216.5, 216.5);
  }
  table.tablesaw-stack tr td, table.tablesaw-stack tr th {
    border: 0;
  }
  table.tablesaw-stack tr td:first-child, table.tablesaw-stack tr th:first-child {
    padding-top: 1.4285714286rem;
  }
  table.tablesaw-stack tr td:last-child, table.tablesaw-stack tr th:last-child {
    padding-bottom: 1.4285714286rem;
  }
}
table caption {
  caption-side: bottom;
  text-align: left;
  margin-top: 0.4rem;
}
table td, table th {
  border: 1px solid rgb(216.5, 216.5, 216.5);
  padding: 0.2857142857rem 0.8571428571rem;
  background-color: #fff;
  text-align: left;
  font-weight: normal;
  color: #575757;
}
table td.tablesaw-cell-hidden,
table td .tablesaw-cell-label, table th.tablesaw-cell-hidden,
table th .tablesaw-cell-label {
  display: none;
}
table thead th, table thead td {
  background-color: #1568af;
  color: #fff;
  font-weight: bold;
}
table[class*=accent-] thead th, table[class*=accent-] thead td {
  background-color: var(--accent-bg-color, #1568af);
}
table:not(.no-stripes) tbody tr:nth-child(odd) td, table:not(.no-stripes) tbody tr:nth-child(odd) th {
  background-color: #f2f2f2;
}

.tablesaw-bar {
  clear: both;
}
.tablesaw-bar.tablesaw-mode-swipe.tablesaw-all-cols-visible {
  display: none;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance {
  float: right;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance a.tablesaw-nav-btn {
  width: 2.8571428571rem;
  height: 2.8571428571rem;
  margin: 0 0 0 0.2857142857rem;
  border: 1px solid #c0c0c0;
  border-radius: 0.2857142857rem;
  text-decoration: none;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance a.tablesaw-nav-btn:hover {
  background-color: #f5f5f5;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance a.tablesaw-nav-btn.disabled {
  opacity: 0.35;
  cursor: default;
  pointer-events: none;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance a.tablesaw-nav-btn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  border: 0;
  transform: translateX(-50%) translateY(-50%);
  font-size: 1.7142857143rem;
  text-indent: 0;
  color: #5b584b;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance a.tablesaw-nav-btn.left::before {
  content: "\e029";
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance a.tablesaw-nav-btn.right::before {
  content: "\e006";
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance-dots {
  list-style: none;
  height: 2.8571428571rem;
  margin: 0 1rem;
  line-height: 2.8571428571rem;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance-dots li {
  display: inline;
  margin-left: 0.5rem;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance-dots li i {
  width: 0.5rem;
  height: 0.5rem;
  background: #000;
  border-radius: 50%;
  display: inline-block;
}
.tablesaw-bar.tablesaw-mode-swipe .tablesaw-advance-dots li.tablesaw-advance-dots-hide i {
  background: #999;
}

.dk-tabs {
  margin: 1rem 0;
}
.dk-tabs.dk-scrollable {
  padding-top: 0.0714285714rem;
  background-color: rgb(218.7073170732, 219.2926829268, 219.2926829268);
  border-top-left-radius: 0.2857142857rem;
  border-top-right-radius: 0.2857142857rem;
  border-width: 1px;
  border-style: solid;
}
.dk-tabs.dk-scrollable div.scroll_tab_inner span:not([class*=_finisher]):not(.tab_selected):not([aria-selected=true]) {
  border-right: 1px solid #c1c2c2;
  border-left: 0;
  border-top-color: rgb(218.7073170732, 219.2926829268, 219.2926829268);
  border-radius: 0;
  margin-right: 0;
}
.dk-tabs .scroll_tab_inner i {
  font-style: normal;
}
.dk-tabs .scroll_tabs_container {
  margin: 0;
  position: relative;
  top: 0.0714285714rem;
}
.dk-tabs .scroll_tabs_container div:is(.scroll_tab_left_button, .scroll_tab_right_button) {
  background-color: rgb(218.7073170732, 219.2926829268, 219.2926829268);
  height: calc(100% - 1px);
  z-index: 10;
  position: absolute;
}
.dk-tabs .scroll_tabs_container div:is(.scroll_tab_left_button, .scroll_tab_right_button)::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 0;
  font-size: 1.2857142857rem;
}
.dk-tabs .scroll_tabs_container div:is(.scroll_tab_left_button, .scroll_tab_right_button):not(.scroll_arrow_disabled)::before {
  color: #000;
}
.dk-tabs .scroll_tabs_container div.scroll_tab_left_button {
  box-shadow: 0.3571428571rem 0 0.2857142857rem -0.1428571429rem #999;
  border-radius: 0.2857142857rem 0 0 0.2857142857rem;
}
.dk-tabs .scroll_tabs_container div.scroll_tab_left_button::before {
  content: "\e029";
}
.dk-tabs .scroll_tabs_container div.scroll_tab_right_button {
  box-shadow: -0.3571428571rem 0 0.2857142857rem -0.1428571429rem #999;
  border-radius: 0 0.2857142857rem 0.2857142857rem 0;
}
.dk-tabs .scroll_tabs_container div.scroll_tab_right_button::before {
  content: "\e006";
}
.dk-tabs .scroll_tabs_container div.scroll_tab_inner span:not([class*=_finisher]) {
  background-color: #f2f2f2;
  height: 100%;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  border-width: 1px 0.0357142857rem;
  border-style: solid;
  border-top-left-radius: 0.2857142857rem;
  border-top-right-radius: 0.2857142857rem;
  margin-right: 0.0714285714rem;
}
.dk-tabs .scroll_tabs_container div.scroll_tab_inner span:not([class*=_finisher]).tab_selected, .dk-tabs .scroll_tabs_container div.scroll_tab_inner span:not([class*=_finisher])[aria-selected=true] {
  box-shadow: inset 0 1.4285714286rem 0 -1.2142857143rem #5f8038;
  box-shadow: inset 0 1.4285714286rem 0 -1.2142857143rem var(--accent-border-color, #5f8038);
  background-color: #fff;
  border-bottom: 1px solid #fff;
}
.dk-tabs .scroll_tabs_container div.scroll_tab_inner span:not([class*=_finisher]).tab_selected.scroll_tab_over, .dk-tabs .scroll_tabs_container div.scroll_tab_inner span:not([class*=_finisher])[aria-selected=true].scroll_tab_over {
  background-color: #fff;
  cursor: default;
}
.dk-tabs .scroll_tabs_container div.scroll_tab_inner span:not([class*=_finisher]).scroll_tab_over {
  background-color: rgb(217.8, 217.8, 217.8);
}
.dk-tabs .dk-tabs-content > div {
  background-color: #fff;
  height: 100%;
  margin: 0;
  padding: 1.4285714286rem;
  border-style: solid;
  border-width: 1px 0 0;
}
.dk-tabs .dk-tabs-content > div {
  display: block;
}
.dk-tabs .dk-tabs-content > div::after {
  content: "";
  display: block;
  clear: both;
}
.dk-tabs .dk-tabs-content > div {
  display: none;
}
.dk-tabs .dk-tabs-content > div.show {
  display: block;
}
.dk-tabs:not(.dk-scrollable) .dk-tabs-content > div {
  border-width: 1px;
}
.dk-tabs li {
  margin: 0;
  list-style-position: initial;
}
.dk-tabs :is(ul, ol) {
  margin-left: 1rem;
}

.usa-banner {
  font-size: 0.8571428571rem;
  padding-bottom: 0;
  line-height: 1.6;
  background-color: #f2f2f2;
  font-family: "Montserrat", "Montserrat-local", "Montserrat-fallback", Verdana, Helvetica, sans-serif;
}
@media screen and (max-width: 649px) {
  .usa-banner {
    padding: 0 1rem;
  }
}

.usa-banner__header {
  position: relative;
  padding: 0.2857142857rem 0;
}
.usa-banner__header.usa-banner__header--expanded {
  padding-right: 3.2857142857rem;
}
.usa-banner__header.usa-banner__header--expanded .usa-banner__header-action {
  display: none;
}
@media screen and (max-width: 649px) {
  .usa-banner__header.usa-banner__header--expanded button::after {
    content: "\e012";
    display: block;
    position: absolute;
    top: 0.4285714286rem;
    right: 1.2857142857rem;
    font-size: 1.5rem;
    color: #333;
  }
}
@media screen and (max-width: 649px) {
  .usa-banner__header {
    padding: 0.5rem 0;
    min-height: 3rem;
  }
}

.usa-banner__inner {
  display: flex;
}
@media screen and (max-width: 649px) {
  .usa-banner__inner button {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 2rem);
    height: 100%;
    margin-left: -1rem;
  }
  .usa-banner__inner button .usa-banner__button-text {
    position: absolute;
    left: -999em;
    right: auto;
  }
}
@media print, screen and (min-width: 650px) {
  .usa-banner__inner .usa-banner__header-action {
    display: none;
  }
  .usa-banner__inner button {
    margin-top: -1px;
    margin-left: 0.5rem;
  }
  .usa-banner__inner button[aria-expanded=false]::after {
    content: "\e038";
  }
  .usa-banner__inner button[aria-expanded=true]::after {
    content: "\e037";
  }
}
.usa-banner__inner .usa-banner__header-action {
  color: #004b8d;
  text-decoration: underline;
}
.usa-banner__inner .usa-banner__header-action::after {
  content: "\e038";
  top: 0;
  margin-left: 5px;
  font-size: 0.8em;
  color: inherit;
}
.usa-banner__inner button {
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  font: inherit;
  color: #004b8d;
  cursor: pointer;
}
.usa-banner__inner button span {
  text-decoration: underline;
}
.usa-banner__inner button::after {
  top: 0;
  margin-left: 2px;
  font-size: 0.8em;
  color: inherit;
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  .usa-banner__inner button {
    margin-top: -2px;
  }
}
.usa-banner__inner p {
  margin: 0;
}

.usa-banner__inner-flag {
  flex: 0 1 auto;
  padding: 1px 7px 0 0;
}

.usa-banner__content {
  font-size: 1rem;
  padding: 1rem 0;
}
.usa-banner__content > div {
  display: flex;
  flex: 0 1 50%;
  align-items: flex-start;
}
.usa-banner__content > div > svg, .usa-banner__content > div > img {
  flex: 0 0 auto;
  width: 2.8571428571rem;
  height: 2.8571428571rem;
  margin-right: 0.5rem;
}
.usa-banner__content > div p {
  margin: 0;
}
@media print, screen and (min-width: 650px) {
  .usa-banner__content:not([hidden]) {
    display: flex;
  }
  .usa-banner__content > div:first-child {
    margin-right: 0.5rem;
  }
  .usa-banner__content > div:last-child {
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 649px) {
  .usa-banner__content > div + div {
    margin-top: 1rem;
  }
}

.usa-banner__lock-image {
  height: 1.5ex;
  width: 1.21875ex;
}

@media print {
  .site-wrap {
    display: flex;
    flex-direction: column;
  }
  .pt-blog.pt-blog .dk-pagination, .pt-blog.pt-blog article .subscribe-share, .pt-facet-search.pt-facet-search .fs-search, .pt-facet-search.pt-facet-search .fs-facets, .dk-facet-search .fs-search, .dk-facet-search .fs-facets, .mobile-swap.mobile-swap .swap-mobile, .dk-accdn .acc-ctrl, #scrollUp, #main-footer .footer-sitemap, #main-footer .sub-footer, #main-footer #contact .follow-us, .dk-main-container .dk-leftnav, #main-header .sticky-header #site-search, #main-header .sticky-header .mobile-toolbar, #main-header .sticky-header .primary-nav, #main-header .pre-header {
    display: none;
  }
  .pt-blog.pt-blog .dk-blog-rail section.subscribe, .pt-blog.pt-blog .dk-blog-rail section.dk-share, .pt-blog.pt-blog .dk-blog-rail section.search, .pt-blog.pt-blog .dk-blog-rail section.featured-posts, .pt-blog.pt-blog .dk-blog-rail section.tags, .pt-blog.pt-blog .dk-blog-rail section.archive, .dk-subscribe-panel, .no-print, .dk-share, figure .credit::before, figure .expand, .dk-breadcrumb-row {
    display: none !important;
  }
  #main-header {
    height: 125px !important;
  }
  #main-header .sticky-header {
    position: static !important;
  }
  #main-header.offscreen .site-logo {
    top: 2rem !important;
    width: 21.4285714286rem !important;
  }
  #main-header.offscreen .site-logo.dk-logo-full {
    visibility: visible !important;
  }
  #main-header.offscreen .site-logo.dk-logo-abbrev {
    visibility: hidden !important;
  }
  #main-header.offscreen .sticky-header {
    box-shadow: none !important;
  }
  .dk-main-container {
    box-shadow: none;
    padding: 0;
  }
  .dk-main-container .dk-content {
    padding: 0;
  }
  .dk-main-container .dk-content {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  .dk-main-container .dk-content:last-child {
    margin-right: 0;
  }
  .dk-main-container .dk-content h1 {
    margin-top: 0;
  }
  #main-footer #contact address {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 100%;
  }
  #main-footer #contact address:last-child {
    margin-right: 0;
  }
  .dk-accdn .acc-main, .dk-accdn .acc-title {
    border: 0 !important;
  }
  .dk-accdn .acc-title button {
    padding: 0 !important;
  }
  .dk-accdn .acc-title button::after {
    content: normal !important;
  }
  .dk-accdn .acc-content {
    display: block !important;
    padding: 1rem 0 1rem !important;
  }
  figure .credit {
    padding-top: 0.3571428571rem !important;
    color: #575757 !important;
    border-bottom: solid 1px #c1c2c2;
  }
  .steps-list.steps-list.steps-list > *::before, .callout {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .pt-blog.pt-blog .blog-title.blog-title, .pt-blog.pt-blog .blog-title.blog-title .blog-icon {
    color: #575757;
  }
  .pt-blog.pt-blog .blog-title.blog-title .blog-icon {
    border-color: #575757;
  }
  .pt-blog.pt-blog article {
    max-width: 100%;
  }
  .pt-blog.pt-blog article .related-posts {
    display: block;
  }
  .pt-blog.pt-blog article .related-posts::after {
    content: "";
    display: block;
    clear: both;
  }
  .pt-blog.pt-blog article .related-posts article {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 48.8211739883%;
  }
  .pt-blog.pt-blog article .related-posts article:last-child {
    margin-right: 0;
  }
  .pt-blog.pt-blog .main-image figcaption {
    text-align: left;
  }
}
main {
  display: block;
  font-family: "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
  color: #575757;
}
main hr {
  margin: 2rem 0;
  border: solid 1px #c1c2c2;
}
main br:is(.mobile, .m, .tablet, .t, .desktop, .d, .full, .f) {
  display: none;
}
@media screen and (max-width: 649px) {
  main br:is(.mobile, .m) {
    display: inline;
  }
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  main br:is(.tablet, .t, .full, .f) {
    display: inline;
  }
}
@media screen and (min-width: 1025px) {
  main br:is(.desktop, .d, .full, .f) {
    display: inline;
  }
}
main svg a {
  cursor: pointer;
}
@font-face {
  font-family: "dki";
  src: url("fonts/dki.woff2") format("woff2"), url("fonts/dki.ttf") format("truetype"), url("fonts/dki.woff") format("woff"), url("fonts/dki.svg#dki") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dki" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-arrow-left:before {
  content: "\e001";
}

.i-arrow-right:before {
  content: "\e000";
}

.i-arrow-up:before {
  content: "\e003";
}

.i-arrow-down:before {
  content: "\e002";
}

.i-chevron-left:before {
  content: "\e035";
}

.i-chevron-right:before {
  content: "\e036";
}

.i-chevron-up:before {
  content: "\e037";
}

.i-chevron-down:before {
  content: "\e038";
}

.i-triangle-left:before {
  content: "\e03f";
}

.i-triangle-right:before {
  content: "\e040";
}

.i-triangle-up:before {
  content: "\e041";
}

.i-triangle-down:before {
  content: "\e03e";
}

.i-play:before {
  content: "\e06a";
}

.i-pause:before {
  content: "\e06c";
}

.i-stop:before {
  content: "\e06d";
}

.i-exit:before {
  content: "\e03a";
}

.i-exit-nih:before {
  content: "\e03b";
}

.i-top-en:before {
  content: "\e03c";
}

.i-top-es:before {
  content: "\e03d";
}

.i-menu:before {
  content: "\e011";
}

.i-share:before {
  content: "\e039";
}

.i-address-book:before {
  content: "\e07b";
}

.i-blog:before {
  content: "\e07a";
}

.i-mobile:before {
  content: "\e022";
}

.i-video:before {
  content: "\e01d";
}

.i-audio:before {
  content: "\e042";
}

.i-shape-circle:before {
  content: "\e048";
}

.i-shape-hexagon:before {
  content: "\e04e";
}

.i-shape-pentagon-arrow:before {
  content: "\e04f";
}

.i-shape-square:before {
  content: "\e050";
}

.i-google-scholar-square:before {
  content: "\e918";
}

.i-orcid-square:before {
  content: "\e919";
}

.i-researchgate-square:before {
  content: "\e900";
}

.i-researchgate:before {
  content: "\e901";
}

.i-cells:before {
  content: "\e051";
}

.i-collaboration:before {
  content: "\e052";
}

.i-double-chevron:before {
  content: "\e053";
}

.i-examine-report:before {
  content: "\e054";
}

.i-funding:before {
  content: "\e055";
}

.i-grant-apply:before {
  content: "\e056";
}

.i-grant-award:before {
  content: "\e057";
}

.i-grant-examine:before {
  content: "\e058";
}

.i-grant-fund:before {
  content: "\e059";
}

.i-grant-funding:before {
  content: "\e05a";
}

.i-grant-manage:before {
  content: "\e05b";
}

.i-grant-review:before {
  content: "\e05c";
}

.i-handshake:before {
  content: "\e05d";
}

.i-node-cluster:before {
  content: "\e05e";
}

.i-newspaper:before {
  content: "\e05f";
}

.i-new-investigator:before {
  content: "\e060";
}

.i-mortarboard:before {
  content: "\e061";
}

.i-medical-microscope:before {
  content: "\e062";
}

.i-lightbulb:before {
  content: "\e063";
}

.i-id-badge:before {
  content: "\e064";
}

.i-person-presenting:before {
  content: "\e066";
}

.i-small-business:before {
  content: "\e067";
}

.i-tools:before {
  content: "\e068";
}

.i-dna:before {
  content: "\e045";
}

.i-test-tubes:before {
  content: "\e047";
}

.i-robotic-arm:before {
  content: "\e04d";
}

.i-clipboard:before {
  content: "\e046";
}

.i-molecule:before {
  content: "\e04a";
}

.i-mouse:before {
  content: "\e04c";
}

.i-mortar-pestle:before {
  content: "\e049";
}

.i-people-discussing:before {
  content: "\e065";
}

.i-additional-reading:before {
  content: "\e06e";
}

.i-toolbox:before {
  content: "\e06f";
}

.i-weight-management:before {
  content: "\e070";
}

.i-school-responsibilities:before {
  content: "\e071";
}

.i-risk-factors:before {
  content: "\e072";
}

.i-checklists:before {
  content: "\e073";
}

.i-blood-screening:before {
  content: "\e074";
}

.i-diabetes:before {
  content: "\e075";
}

.i-download-file:before {
  content: "\e076";
}

.i-kidney-disease:before {
  content: "\e077";
}

.i-clinical-trials:before {
  content: "\e902";
}

.i-publications:before {
  content: "\e903";
}

.i-research-materials:before {
  content: "\e904";
}

.i-repository:before {
  content: "\e913";
}

.i-lab-software:before {
  content: "\e91a";
}

.i-lab-servers:before {
  content: "\e91b";
}

.i-pulse-programs:before {
  content: "\e91c";
}

.i-standards:before {
  content: "\e91e";
}

.i-webinar-s:before {
  content: "\e921";
}

.i-pos-block-left:before {
  content: "\e905";
}

.i-pos-block-center:before {
  content: "\e906";
}

.i-pos-float-left:before {
  content: "\e907";
}

.i-pos-float-right:before {
  content: "\e908";
}

.i-size-small:before {
  content: "\e909";
}

.i-size-medium:before {
  content: "\e90a";
}

.i-size-large:before {
  content: "\e90b";
}

.i-size-fill:before {
  content: "\e90c";
}

.i-webinar:before {
  content: "\e911";
}

.i-chat-bubble:before {
  content: "\e912";
}

.i-live-chat-o:before {
  content: "\e914";
}

.i-live-chat:before {
  content: "\e915";
}

.i-facebook-c:before {
  content: "\e90d";
}

.i-twitter-c:before {
  content: "\e90e";
}

.i-youtube-c:before {
  content: "\e90f";
}

.i-instagram:before {
  content: "\e910";
}

.i-linkedin-c:before {
  content: "\e91f";
}

.i-x-social-c:before {
  content: "\e920";
}

.i-quote-open:before {
  content: "\e924";
}

.i-quote-close:before {
  content: "\e925";
}

.i-copy:before {
  content: "\e916";
}

.i-angle-left:before {
  content: "\e029";
}

.i-angle-right:before {
  content: "\e006";
}

.i-angle-up:before {
  content: "\e007";
}

.i-angle-down:before {
  content: "\e005";
}

.i-angle-double-left:before {
  content: "\e009";
}

.i-angle-double-right:before {
  content: "\e00a";
}

.i-angle-double-up:before {
  content: "\e00b";
}

.i-angle-double-down:before {
  content: "\e008";
}

.i-thick-left:before {
  content: "\e02f";
}

.i-thick-right:before {
  content: "\e030";
}

.i-thick-up:before {
  content: "\e031";
}

.i-thick-down:before {
  content: "\e02d";
}

.i-plus:before {
  content: "\e01a";
}

.i-minus:before {
  content: "\e019";
}

.i-times:before {
  content: "\e012";
}

.i-download:before {
  content: "\e034";
}

.i-print:before {
  content: "\e025";
}

.i-spinner:before {
  content: "\e06b";
}

.i-check:before {
  content: "\e043";
}

.i-exclamation-circle:before {
  content: "\e044";
}

.i-question-circle:before {
  content: "\e917";
}

.i-search:before {
  content: "\e00f";
}

.i-search-plus:before {
  content: "\e00e";
}

.i-search-minus:before {
  content: "\e010";
}

.i-clock-o:before {
  content: "\e020";
}

.i-map-marker:before {
  content: "\e023";
}

.i-calendar:before {
  content: "\e079";
}

.i-calendar-o:before {
  content: "\e078";
}

.i-tty:before {
  content: "\e021";
}

.i-envelope:before {
  content: "\e004";
}

.i-envelope-o:before {
  content: "\e02a";
}

.i-facebook:before {
  content: "\e01c";
}

.i-x-social:before {
  content: "\e922";
}

.i-twitter:before {
  content: "\e02b";
}

.i-linkedin:before {
  content: "\f0e1";
}

.i-rss:before {
  content: "\e024";
}

.i-envelope-square:before {
  content: "\e027";
}

.i-facebook-square:before {
  content: "\e01b";
}

.i-twitter-square:before {
  content: "\e01e";
}

.i-x-social-square:before {
  content: "\e923";
}

.i-linkedin-square:before {
  content: "\e07c";
}

.i-rss-square:before {
  content: "\e026";
}

.i-phone-square:before {
  content: "\e91d";
}

.i-user-medical:before {
  content: "\e033";
}

.i-gear:before {
  content: "\e032";
}

.i-whatsapp:before {
  content: "\f232";
}

.i-reddit:before {
  content: "\f281";
}

.i-pinterest:before {
  content: "\f231";
}

[data-icon]::before {
  content: attr(data-icon);
}

.i-fw {
  width: 1.2857142857em;
  text-align: center;
}

.i-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.i-2x {
  font-size: 2em;
}

.i-3x {
  font-size: 3em;
}

.i-4x {
  font-size: 4em;
}

.i-5x {
  font-size: 5em;
}

.i-150percent {
  font-size: 1.5em;
  margin-top: 0.125em;
}

svg.icon {
  display: inline-block;
  fill: currentColor;
  height: 1.5em;
  width: 1.5em;
  vertical-align: middle;
  position: relative;
}

.i-people-funding::before {
  content: "\e065";
}

.i-blog-icon::before {
  content: "\e07a";
}

.i-video.red {
  color: #ff0000;
}

.no-margin.no-margin.no-margin.no-margin {
  margin: 0;
}

.no-margin-top.no-margin-top.no-margin-top.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom.no-margin-bottom.no-margin-bottom.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-left.no-margin-left.no-margin-left.no-margin-left {
  margin-left: 0;
}

.no-margin-right.no-margin-right.no-margin-right.no-margin-right {
  margin-right: 0;
}

.no-padding.no-padding.no-padding.no-padding {
  padding: 0;
}

.no-padding-top.no-padding-top.no-padding-top.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom.no-padding-bottom.no-padding-bottom.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-left.no-padding-left.no-padding-left.no-padding-left {
  padding-left: 0;
}

.no-padding-right.no-padding-right.no-padding-right.no-padding-right {
  padding-right: 0;
}

.dk-breadcrumb-row {
  margin: 0;
  z-index: 1;
}
.dk-breadcrumb-row .dk-breadcrumbs {
  margin: 0.5rem 0;
}
.dk-breadcrumb-row .dk-breadcrumbs li {
  --page-breadcrumb-separator: " » ";
  font-family: "Droid Sans", "Droid Sans-local", "Droid Sans-fallback", "Trebuchet MS", Arial, Helvetica, sans-serif;
}
@media screen and (min-width: 650px) {
  .dk-breadcrumb-row + .dk-main-container {
    margin-top: 0;
  }
}

.banner-cta .button-ghost::after {
  padding-left: 0;
}

@media print {
  .dk-main-container .dk-content {
    padding: 0;
  }
  .dk-main-container .dk-content .dk-pagination form > span.current {
    color: #1568af;
  }
  .button-ghost {
    display: none;
  }
}
.ws-obesity.ws-obesity.ws-obesity .ws-niddk-only {
  display: none;
}

.usa-banner {
  font-family: "Droid Sans", "Droid Sans-local", "Droid Sans-fallback", "Trebuchet MS", Arial, Helvetica, sans-serif;
}

@media print, screen and (min-width: 650px) {
  .stripe-brand .dk-site-logo {
    width: 23.7857142857rem;
    height: 3.8571428571rem;
  }
}
@media screen and (max-width: 649px) {
  .stripe-brand .dk-brand-wrap {
    flex-wrap: wrap;
  }
  .stripe-brand .dk-site-logo {
    width: 11.8571428571rem;
    height: 100%;
    padding: 0.7857142857rem 0.5714285714rem;
  }
}
@media print {
  .stripe-brand {
    padding: 1rem 0;
  }
  .stripe-brand img:not(.desktop) {
    display: none;
  }
}
.stripe-brand .dk-site-mobile-toolbar button {
  margin: 0;
}

@media screen and (min-width: 650px) {
  .dk-site-nav {
    display: block;
    position: relative;
    width: 100%;
    z-index: 30;
    box-shadow: 0 0 0 transparent;
  }
  .dk-site-nav:not(.no-transition) {
    transition: box-shadow 250ms ease-out, background-position-y 250ms ease-out;
  }
  .dk-site-nav:not(.no-transition) .sn-sections, .dk-site-nav:not(.no-transition) .sn-section {
    transition: background-position-y 250ms ease-out;
  }
  .dk-site-nav:not(.no-transition) .sn-section-title > a {
    transition: color 250ms ease-out;
  }
  .dk-site-nav:not(.no-transition) .sn-section-title > a span {
    transition: text-shadow 250ms ease-out, font-size 250ms ease-out;
  }
  .dk-site-nav:not(.no-transition) .sn-section-title > a::after {
    transition: border-color 250ms ease-out;
  }
  .dk-site-nav, .dk-site-nav .sn-section, .dk-site-nav .sn-sections {
    background: #fff linear-gradient(180deg, rgb(30.822, 52.304, 121.42) 18%, #213882 25%, #fff 75%) 0% 100%/1px 16rem repeat-x;
  }
  .dk-site-nav .sn-sections {
    background-color: #d5d5d6;
    background-image: linear-gradient(180deg, rgb(22.44, 38.08, 88.4), rgb(104.04, 119.68, 170) 25%, #d5d5d6 75%);
  }
  .dk-site-nav .sn-section.current {
    background-color: #1365b1;
    background-image: linear-gradient(180deg, rgb(18.658, 99.182, 173.814) 7%, #1365b1 25%, #1365b1 77%, #fff 77%);
  }
  .dk-site-nav, .dk-site-nav .sn-section, .dk-site-nav .sn-sections {
    background-position-y: 0%;
  }
  .dk-site-nav .sn-section-title > a {
    color: #fff;
  }
  .dk-site-nav .sn-section-title > a span {
    text-shadow: 0.1071428571rem 0.1855714286rem 0.6428571429rem rgba(0, 0, 0, 0.24);
  }
  .dk-site-nav .sn-bar {
    display: flex;
    width: calc(100% + 2px);
    transform: translateX(-1px);
  }
  .dk-site-nav .sn-home {
    display: none;
  }
  .dk-site-nav .sn-sections-wrap {
    flex: 1 1 auto;
  }
  .dk-site-nav .sn-sections {
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px 0;
    font-family: "Montserrat", "Montserrat-local", "Montserrat-fallback", Verdana, Helvetica, sans-serif;
  }
  .dk-site-nav .sn-section {
    display: table-cell;
    vertical-align: top;
  }
  .dk-site-nav .sn-section.focused .sn-section-title > a, .dk-site-nav .sn-section:hover .sn-section-title > a {
    color: #333;
    background-color: rgba(255, 255, 255, 0.97);
  }
  .dk-site-nav .sn-section.focused .sn-section-title > a span, .dk-site-nav .sn-section:hover .sn-section-title > a span {
    text-shadow: none;
  }
  .dk-site-nav .sn-section.focused .sn-section-title > a::before, .dk-site-nav .sn-section:hover .sn-section-title > a::before {
    visibility: visible;
  }
  .dk-site-nav .sn-section.focused .sn-section-title > a::after, .dk-site-nav .sn-section:hover .sn-section-title > a::after {
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent transparent #575757;
    border-width: 0 0.4285714286rem 0.4285714286rem;
  }
  .dk-site-nav .sn-section.current {
    background-image: linear-gradient(180deg, rgb(18.658, 99.182, 173.814) 7%, #1365b1 25%, #1365b1 77%, #fff 77%);
  }
  .dk-site-nav .sn-section.current .sn-section-title > a::after {
    content: "";
  }
  .dk-site-nav .sn-section .sn-chk-expand, .dk-site-nav .sn-section .sn-lbl-expand {
    display: none;
  }
  .dk-site-nav .sn-section-title > a {
    display: block;
    position: relative;
    z-index: 31;
    min-width: 7.1428571429rem;
    margin-top: 0.2857142857rem;
    line-height: 3.7142857143rem;
    text-align: center;
  }
  .dk-site-nav .sn-section-title > a span {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 1.3rem;
    padding: 0 0.2142857143rem;
    line-height: 1.05em;
    vertical-align: middle;
  }
  .dk-site-nav .sn-section-title > a::before {
    content: "";
    position: absolute;
    visibility: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: -20px 0 40px -20px #000, 20px 0 40px -20px #000;
    clip: rect(-2rem, 100rem, 3.7142857143rem, -100rem);
  }
  .dk-site-nav .sn-section-title > a::after {
    border-style: solid;
    height: 0;
    width: 0;
    border-color: transparent transparent #dbdad3;
    border-width: 0 0.4285714286rem 0.4285714286rem;
    display: block;
    visibility: visible;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .dk-site-nav .sn-subsections {
    display: none;
  }
  .dk-site-nav:not(.no-transition) .sn-home {
    transition: width 250ms ease-out;
  }
  .dk-site-nav:not(.no-transition).stuck .sn-home {
    transition: width 400ms ease-out, min-width 400ms ease-out;
  }
  .dk-site-nav .sn-home {
    display: block;
    width: 0;
    min-width: 0;
    height: 4rem;
    overflow: hidden;
  }
  .dk-site-nav .sn-home .sn-section-title {
    display: none;
  }
  .dk-site-nav .sn-home a {
    display: block;
    width: 10.7142857143rem;
    height: 100%;
    margin: 0.2857142857rem 0.5rem 0 0;
    overflow: hidden;
  }
  .dk-site-nav .sn-home a.dk-logo {
    text-indent: -5000em;
    background: url(../images/site-logos.svg#abbrev) no-repeat 0 50%;
  }
  .dk-site-nav .sn-home a img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .dk-site-nav.stuck {
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 0 1px 0.7142857143rem #666;
  }
  .dk-site-nav.stuck .sn-section-title {
    display: block;
  }
  .dk-site-nav.stuck ~ .balloon {
    width: 100%;
    height: 4rem;
  }
  .dk-site-nav.stuck, .dk-site-nav.stuck .sn-section, .dk-site-nav.stuck .sn-sections {
    background-position-y: 100%;
  }
  .dk-site-nav.stuck .sn-section.current .sn-section-title > a::after {
    content: normal;
  }
  .dk-site-nav.stuck .sn-section .sn-section-title > a {
    color: #333;
  }
  .dk-site-nav.stuck .sn-section .sn-section-title > a span {
    font-size: 1rem;
    text-shadow: 0.1071428571rem 0.1855714286rem 0.6428571429rem transparent;
  }
  .dk-site-nav.stuck .sn-home {
    width: 20%;
    min-width: 11.2142857143rem;
  }
  .dk-site-nav.stuck .sn-subsections {
    background-color: #fff;
  }
  .dk-site-nav.dk-site-nav {
    background: #fff;
  }
  .dk-site-nav.dk-site-nav .sn-sections, .dk-site-nav.dk-site-nav .sn-section {
    transition: none;
  }
  .dk-site-nav .sn-section-title > a {
    margin-top: 0;
    font-family: "Droid Sans", "Droid Sans-local", "Droid Sans-fallback", "Trebuchet MS", Arial, Helvetica, sans-serif;
    font-size: 1.1314285714rem;
    line-height: 2.8571428571rem;
  }
  .dk-site-nav:not(.stuck) .sn-bar {
    width: 100%;
    transform: none;
  }
  .dk-site-nav:not(.stuck) .sn-sections {
    border-spacing: 0;
    border-collapse: separate;
    background: transparent;
  }
  .dk-site-nav:not(.stuck) .sn-section {
    background: #00518f linear-gradient(#2f78c0, #0f4071 70%);
    border-left: 1px solid #577ca2;
    border-right: 1px solid #122d4a;
  }
  .dk-site-nav:not(.stuck) .sn-section:first-child {
    border-left: 0;
  }
  .dk-site-nav:not(.stuck) .sn-section:first-child, .dk-site-nav:not(.stuck) .sn-section:first-child .sn-section-title > a {
    border-top-left-radius: 0.5rem;
  }
  .dk-site-nav:not(.stuck) .sn-section:last-child {
    border-right: 0;
  }
  .dk-site-nav:not(.stuck) .sn-section:last-child, .dk-site-nav:not(.stuck) .sn-section:last-child .sn-section-title > a {
    border-top-right-radius: 0.5rem;
  }
  .dk-site-nav:not(.stuck) .sn-section.focused, .dk-site-nav:not(.stuck) .sn-section.current {
    background: #00518f linear-gradient(#002c5b, #003972 70%);
  }
  .dk-site-nav:not(.stuck) .sn-section.current .sn-section-title > a::after {
    content: normal;
  }
  .dk-site-nav:not(.stuck) .sn-section.focused .sn-section-title > a, .dk-site-nav:not(.stuck) .sn-section:hover .sn-section-title > a {
    color: #fff;
    background-color: transparent;
  }
  .dk-site-nav:not(.stuck) .sn-section-title > a:hover {
    color: #fff;
    background: #00518f linear-gradient(#002c5b, #003972 70%);
  }
  .dk-site-nav:not(.stuck) .sn-section-title > a span {
    font-size: inherit;
  }
  .dk-site-nav:not(.no-transition).stuck .sn-home {
    transition: width 400ms ease-out;
  }
  .dk-site-nav .sn-home a {
    width: 10.2142857143rem;
    margin: 0 0.5rem 0 0;
  }
  .dk-site-nav .sn-home a.dk-logo {
    background: url(../images/obesity-logo-abbrev.svg) no-repeat 0 50%;
  }
  .dk-site-nav.stuck ~ .balloon,
  .dk-site-nav .sn-home {
    height: 2.8571428571rem;
  }
  .dk-site-nav.stuck .sn-home {
    width: 13.5714285714rem;
    min-width: 0;
  }
  .dk-site-nav.stuck .sn-section.current .sn-section-title {
    position: relative;
  }
  .dk-site-nav.stuck .sn-section.current .sn-section-title::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 41;
    border-top: 3px solid #20558a;
  }
  .dk-site-nav.stuck .sn-section-title > a:hover span {
    text-decoration: underline;
  }
  .dk-site-nav .sn-home a.dk-logo {
    display: block;
    position: relative;
    width: calc(100% - 10px);
    height: 2.8571428571rem;
    margin-right: 10px;
    background-image: none;
    overflow: hidden;
  }
  .dk-site-nav .sn-home a.dk-logo::before {
    content: normal;
  }
  .dk-site-nav .sn-home a.dk-logo .sn-logo-wrap {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 10.7142857143rem;
    height: 2.3171428571rem;
    padding: 0;
  }
  .dk-site-nav .sn-home a.dk-logo .sn-logo-wrap img {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: 650px) and (max-width: 1024px) {
  .dk-site-nav .sn-section-title > a span {
    vertical-align: baseline;
  }
}
@media screen and (max-width: 649px) {
  .dk-site-nav {
    position: fixed;
    left: 100%;
    top: 0;
    width: calc(100% - 2.8571428571rem);
    height: 100%;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: 111;
    transition: left 0.5s;
    list-style: none;
    background: #111;
    overflow-y: scroll;
    font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
    font-size: 1rem;
  }
  .site-wrap.pushed .dk-site-nav {
    left: 2.8571428571rem;
  }
  .site-wrap {
    right: 0;
    transition: right 0.5s;
  }
  .site-wrap.pushed {
    right: calc(100% - 2.8571428571rem);
    position: relative;
  }
  .site-wrap.pushed .page-mask {
    position: absolute;
    z-index: 110;
    background-color: rgba(51, 51, 51, 0.7);
    transition: background-color 0.25s;
  }
  .site-wrap.pushed .page-mask > .sn-close-nav {
    visibility: visible;
    top: 0;
    transition: top 0.25s 0.3s;
  }
  .site-wrap .page-mask > .sn-close-nav {
    display: block;
    position: fixed;
    visibility: hidden;
    top: -2.8571428571rem;
    left: 0;
    z-index: 50;
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    color: #ccc;
    background: #282828;
    border: 0;
    border-right: solid 1px #464646;
    transition: top 0.25s, visibility 0s 0.25s;
    text-align: center;
    appearance: none;
  }
  .site-wrap .page-mask > .sn-close-nav i {
    font-size: 1.4285714286rem;
    line-height: 2;
  }
  .dk-site-nav .sn-section .sn-chk-expand + .sn-section-title label, .dk-site-nav .sn-bar.sn-expandable > li > :first-child:not(div)::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3.2142857143rem;
  }
  .dk-site-nav .sn-bar {
    background: #282828;
  }
  .dk-site-nav .sn-bar.sn-expandable > li > :first-child:not(div) {
    width: calc(100% - 3.2142857143rem);
  }
  .dk-site-nav .sn-home, .dk-site-nav .sn-section {
    border-bottom: solid 1px #464646;
  }
  .dk-site-nav .sn-section-title {
    display: block;
    position: relative;
    line-height: 1.4em;
  }
  .dk-site-nav .sn-section-title a {
    display: block;
    padding: 1.0714285714rem 1.1428571429rem 1.0714285714rem 0.7857142857rem;
    border-left: solid 0.3571428571rem #282828;
    font-size: 1.1428571429rem;
  }
  .dk-site-nav .sn-section-title a:hover, .dk-site-nav .sn-section-title a:active {
    text-decoration: none;
    background: #242424;
  }
  .dk-site-nav .sn-section {
    position: relative;
  }
  .dk-site-nav .sn-section span {
    color: #fff;
  }
  .dk-site-nav .sn-section.current .sn-section-title > a {
    border-left-color: #ccc;
  }
  .dk-site-nav .sn-section .sn-chk-expand {
    position: absolute;
    top: -1000em;
    left: -1000em;
  }
  .dk-site-nav .sn-section .sn-chk-expand + .sn-section-title {
    border-bottom: 0 solid #464646;
    transition: border-bottom-width 0s 0.3s;
  }
  .dk-site-nav .sn-section .sn-chk-expand + .sn-section-title a {
    width: calc(100% - 3.2142857143rem);
  }
  .dk-site-nav .sn-section .sn-chk-expand + .sn-section-title label {
    border: 0;
    background: #313131;
    font-size: 0;
    text-indent: 100em;
    cursor: pointer;
  }
  .dk-site-nav .sn-section .sn-chk-expand + .sn-section-title label::before, .dk-site-nav .sn-section .sn-chk-expand + .sn-section-title label::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.1428571429rem;
    height: 0.2857142857rem;
    transform: translate(-50%);
    border-radius: 2px;
    background-color: #ccc;
  }
  .dk-site-nav .sn-section .sn-chk-expand + .sn-section-title label::after {
    transform: translate(-50%) rotate(90deg);
    opacity: 1;
    transition: all 0.3s;
  }
  .dk-site-nav .sn-section .sn-chk-expand:checked + .sn-section-title {
    border-bottom-width: 1px;
    transition: border-bottom-width 0s;
  }
  .dk-site-nav .sn-section .sn-chk-expand:checked + .sn-section-title label::after {
    transform: translate(-50%);
    opacity: 0;
  }
  .dk-site-nav .sn-section .sn-chk-expand ~ .sn-subsections {
    display: flex;
    overflow: hidden;
  }
  .dk-site-nav .sn-section .sn-chk-expand ~ .sn-subsections::after {
    content: "";
    height: 0;
    transition: height 0.3s ease;
    max-height: 50px;
  }
  .dk-site-nav .sn-section .sn-chk-expand ~ .sn-subsections .sn-collapsible {
    margin-bottom: -2000px;
    transition: margin-bottom 0.3s cubic-bezier(1, 0, 1, 1), visibility 0s 0.3s, max-height 0s 0.3s;
    visibility: hidden;
    max-height: 0;
  }
  .dk-site-nav .sn-section .sn-chk-expand:checked ~ .sn-subsections::after {
    height: 50px;
    transition: height 0.3s ease, max-height 0s 0.3s ease;
    max-height: 0;
  }
  .dk-site-nav .sn-section .sn-chk-expand:checked ~ .sn-subsections .sn-collapsible {
    transition: margin-bottom 0.3s cubic-bezier(0, 0, 0, 1);
    margin-bottom: 0;
    visibility: visible;
    max-height: 1000000px;
  }
  .dk-site-nav .sn-section.sn-collapsed > ul {
    display: none;
  }
  .dk-site-nav .sn-section.sn-collapsed button::before {
    content: "\e01a";
  }
  .dk-site-nav .sn-collapsible {
    width: 100%;
  }
  .dk-site-nav .sn-collapsible > div > div {
    border-bottom: 1px solid #464646;
  }
  .dk-site-nav .sn-collapsible > div:last-child > div:last-child, .dk-site-nav .sn-collapsible > .sn-subgroup > div:last-child {
    border-bottom: 0;
  }
  .dk-site-nav .sn-subsections {
    background: #313131;
  }
  .dk-site-nav .sn-subsections a {
    display: block;
    padding: 0.8571428571rem 0.5rem;
    padding-left: 1.75rem;
    font-size: 0.9285714286rem;
    line-height: 1.4;
  }
  .dk-site-nav a {
    text-decoration: none;
    color: #f5f5f5;
  }
  .dk-site-nav .sn-subgroup {
    padding: 0;
    margin-left: 1rem;
    border-left: solid 0.75rem #282828;
    border-bottom: 0;
  }
  .dk-site-nav .sn-subgroup > :first-child {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8em;
    background: #282828;
    line-height: 1.5em;
    padding: 0.5714285714rem 0;
  }
  .dk-site-nav .sn-subgroup > :not(:first-child) {
    margin-left: 0.5rem;
  }
  .dk-site-nav .sn-subgroup > :not(:first-child) a {
    padding: 0.8571428571rem 0.5rem;
  }
  .dk-site-nav .sn-subgroup + div {
    border-top: solid 1px #464646;
  }
  .dk-site-nav .sn-home .sn-logo-wrap {
    display: none;
  }
}
@media print {
  .dk-site-nav {
    display: none;
  }
}

.site-header .dk-site-mobile-toolbar .mobile-site-search[aria-expanded=true],
.sr-refine .dk-site-mobile-toolbar .mobile-site-search[aria-expanded=true] {
  background-color: #f2f2f2;
}
.site-header .dk-site-search form,
.sr-refine .dk-site-search form {
  position: relative;
}
.site-header .dk-site-search input[type=text],
.sr-refine .dk-site-search input[type=text] {
  height: 2.5rem;
  width: 100%;
  padding: 2px 2.5rem 1px 0.8571428571rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.3571428571rem;
  background: transparent;
  font-size: 14.4px;
  line-height: 1.7;
  appearance: none;
}
.site-header .dk-site-search input[type=text] + button,
.sr-refine .dk-site-search input[type=text] + button {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: solid transparent;
  border-width: 1px 1px 1px 0;
  padding: 0;
  text-indent: 5000rem;
  background: transparent;
  appearance: none;
  font-size: 0;
  color: #818181;
  cursor: pointer;
}
.site-header .dk-site-search input[type=text] + button:hover,
.sr-refine .dk-site-search input[type=text] + button:hover {
  background-color: #f2f2f2;
  border-color: #bfbfbf;
  border-radius: 0 0.3571428571rem 0.3571428571rem 0;
}
.site-header .dk-site-search input[type=text] + button::before,
.sr-refine .dk-site-search input[type=text] + button::before {
  content: "\e00f";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.6rem;
  text-indent: 0;
  overflow: hidden;
}
@media screen and (min-width: 650px) {
  .site-header .dk-site-search,
  .sr-refine .dk-site-search {
    width: 19.6428571429rem;
  }
}
@media screen and (max-width: 649px) {
  .site-header .dk-site-search,
  .sr-refine .dk-site-search {
    display: none;
    width: 100%;
    border-top: 1px solid #c1c2c2;
    padding: 0.7rem 1rem;
  }
}
@media print {
  .site-header .dk-site-search,
  .sr-refine .dk-site-search {
    display: none;
  }
}

.sr-status {
  margin: 2rem 0;
}
.sr-status .sr-scope::before {
  content: "‘";
}
.sr-status .sr-scope::after {
  content: "’";
}
.sr-status ol {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: inherit;
}
.sr-status ol li {
  display: inline;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 1rem;
}
.sr-status ol li:not(:last-of-type)::after {
  content: ":";
}
.sr-status ol li:last-of-type::after {
  content: ".";
  font-weight: normal;
}

.sr-suggestion {
  margin: 1rem 0;
  font-weight: bold;
}
.sr-suggestion button {
  font: inherit;
  font-style: italic;
  display: inline;
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0 0.1428571429rem;
  line-height: 1;
  color: #0072bc;
  cursor: pointer;
}
.sr-suggestion button:hover, .sr-suggestion button:active {
  color: #004b8d;
  text-decoration: underline;
}

.sr-results {
  margin: 2rem 0;
  list-style: none;
}
.sr-results li {
  margin: 0;
}
.sr-results .sr-url {
  display: block;
  margin: 0.2rem 0;
  color: #5f8038;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
@media screen and (max-width: 649px) {
  .sr-results .sr-url {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.sr-results p {
  margin-top: 0;
  line-height: 1.7;
}

main .sr-results h2 {
  line-height: 1.2;
  margin-bottom: 0;
}
main .sr-results h2 .file-size {
  font-size: 1rem;
  font-weight: normal;
}
main .sr-results h2 i[class*=file-] {
  font-size: 1rem;
}

.sr-refine {
  background: #f6f5f5;
  border: 1px solid #c0c0c0;
  margin: 2rem 0;
  padding: 1.5rem;
}
@media screen and (min-width: 650px) {
  .sr-refine {
    max-width: 30.8571428571rem;
  }
}
@media print {
  .sr-refine {
    display: none;
  }
}
.sr-refine label {
  display: inline-block;
  margin: 0 1rem 1rem 0;
}
.sr-refine label.disabled {
  color: rgb(120.6, 120.6, 120.6);
  cursor: not-allowed;
}
.sr-refine label.disabled input {
  cursor: not-allowed;
}
.sr-refine .dk-site-search {
  position: relative;
  width: 100%;
}
@media screen and (max-width: 649px) {
  .sr-refine .dk-site-search {
    display: block;
    border-top: none;
    padding: 0;
  }
}
.sr-refine .dk-site-search input[type=text] {
  background-color: #fff;
}

.dk-facet-search .fs-facets {
  margin-top: 1rem;
}
.dk-facet-search .fs-link-facets > * {
  display: block;
  border: solid #c0c0c0;
  border-width: 0 1px 1px;
  background-color: #fbfbfb;
  line-height: 1.2;
  font-size: 12px;
  font-weight: 600;
}
.dk-facet-search .fs-link-facets > *:first-child {
  border-width: 1px;
}
.dk-facet-search .fs-link-facets > a, .dk-facet-search .fs-link-facets span {
  position: relative;
  min-height: 2.5rem;
  padding: 0.7rem 1rem 0.7rem 2.5714285714rem;
}
.dk-facet-search .fs-link-facets > a::before, .dk-facet-search .fs-link-facets > a::after, .dk-facet-search .fs-link-facets span::before, .dk-facet-search .fs-link-facets span::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0.7142857143rem;
}
.dk-facet-search .fs-link-facets > a::before, .dk-facet-search .fs-link-facets span::before {
  width: 1.1428571429rem;
  height: 1.1428571429rem;
  border: solid 1px #c0c0c0;
  border-radius: 50%;
  background-color: #fff;
  transform: translateY(-50%);
}
.dk-facet-search .fs-link-facets a, .dk-facet-search .fs-link-facets a:visited, .dk-facet-search .fs-link-facets a:hover {
  color: #575757;
  text-decoration: none;
}
.dk-facet-search .fs-link-facets a:hover {
  background-color: rgb(238.25, 238.25, 238.25);
}
.dk-facet-search .fs-link-facets span {
  display: block;
}
.dk-facet-search .fs-link-facets span::after {
  transform: translateX(50%) translateY(-50%);
  border-radius: 50%;
  background-color: #5f8038;
  width: 0.5714285714rem;
  height: 0.5714285714rem;
}
.dk-facet-search .fs-link-facets .tic-tacs {
  position: relative;
  margin: 0;
  padding: 0 0.5rem 0.5rem;
  font-size: inherit;
}
.dk-facet-search .fs-link-facets .tic-tacs:first-child {
  padding-top: 0.5rem;
}
@media screen and (min-width: 650px) and (max-width: 889px) {
  .dk-facet-search .fs-link-facets .tic-tacs {
    padding: 0.2142857143rem;
  }
}
.dk-facet-search .fs-link-facets .tic-tacs li {
  display: block;
  float: left;
  margin: 0.3571428571rem 0.2142857143rem;
}
.dk-facet-search .fs-link-facets .tic-tacs li a {
  display: block;
  border: solid 1px #c1c2c2;
  border-radius: 0.3571428571rem;
  padding: 0.2142857143rem 0.3571428571rem;
  background-color: #fff;
  line-height: 1;
  font-weight: 600;
  cursor: pointer;
}
.dk-facet-search .fs-link-facets .tic-tacs li a:hover {
  background-color: rgb(220.6576086957, 233.3913043478, 205.6086956522);
  color: #333;
}
.dk-facet-search .fs-link-facets .tic-tacs input:checked + label {
  border-color: #426924;
  background-color: #426924;
  color: #fff;
}
.dk-facet-search .fs-body {
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}
.dk-facet-search .fs-body {
  display: block;
}
.dk-facet-search .fs-body::after {
  content: "";
  display: block;
  clear: both;
}
@media screen and (min-width: 650px) {
  .dk-facet-search .fs-body {
    position: relative;
  }
  .dk-facet-search .fs-body .fs-facets {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 23.2317609825%;
  }
  .dk-facet-search .fs-body .fs-facets:last-child {
    margin-right: 0;
  }
  .dk-facet-search .fs-body .fs-results {
    display: block;
    float: left;
    margin-right: 2.3576520234%;
    width: 74.4105869942%;
  }
  .dk-facet-search .fs-body .fs-results:last-child {
    margin-right: 0;
  }
  .dk-facet-search .fs-body .fs-facet-slide {
    position: static;
  }
  .dk-facet-search .fs-body .fs-facet-slide.stuck {
    position: fixed;
  }
  .dk-facet-search .fs-body .fs-facet-slide.bottom {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
  }
}
@media print {
  .dk-facet-search .fs-body ol > li {
    padding: 0.7142857143rem 0;
  }
}

.result-items.funding-opportunities .bottom::before, .result-items.funding-opportunities .bottom::after {
  content: normal;
}
.result-items.meetings h4 {
  margin-top: 1rem;
  font-family: "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
}

.grid > .dk-flex-table {
  margin: 0;
}

.hero-unit {
  color: #fff;
  text-shadow: 0.0714285714rem 0.0714285714rem #333;
}
.hero-unit .inner {
  background: #527b8b url(../images/obesity-bg-hero.jpg) no-repeat 50%;
  background-size: cover;
}
.hero-unit .description {
  background-color: rgba(255, 255, 255, 0.15);
}
.hero-unit h1, .hero-unit .description {
  margin: 0;
  padding: 1rem 1.4285714286rem;
}
.hero-unit p {
  font-size: 1.2857142857rem;
}
.hero-unit .button-ghost {
  padding: 1rem 2rem;
}
@media screen and (min-width: 650px) {
  .hero-unit .description {
    display: table;
  }
  .hero-unit .description > * {
    display: table-cell;
    vertical-align: middle;
  }
  .hero-unit .description .left {
    padding-right: 2rem;
  }
  .hero-unit .description .right {
    min-width: 15.3571428571rem;
    text-align: right;
  }
  .hero-unit h1, .hero-unit .description {
    padding: 1.6rem 4rem;
  }
}
@media screen and (max-width: 649px) {
  .hero-unit .description .right {
    margin-top: 1rem;
    margin-bottom: 0.3rem;
    text-align: center;
  }
  .hero-unit .description p {
    font-size: 1.1428571429rem;
  }
}
@media print {
  .hero-unit {
    color: #333;
    text-shadow: none;
  }
  .hero-unit h1, .hero-unit .description {
    padding-left: 0;
    padding-right: 0;
  }
  .hero-unit .description .right {
    display: none;
  }
}

.home-gap {
  height: 2.5rem;
  margin: 0;
}