@use '../tools' as *; // Use site-specific tools

.usa-banner {
  font-family: $droid-sans;
}

.stripe-brand {
  @include desktop(true) {
    .dk-site-logo {
      width: rem(333);
      height: rem(54);
    }
  }

  @include mobile {
    .dk-brand-wrap {
      flex-wrap: wrap;
    }

    .dk-site-logo {
      width: rem(166);
      height: 100%;
      padding: rem(11) rem(8);
    }
  }

  @include print {
    padding: rem(14) 0;

    img:not(.desktop) {
      display: none;
    }
  }

  .dk-site-mobile-toolbar button {
    margin: 0;
  }
}
