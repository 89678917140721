@use 'sass:color';
@use 'sass:math';
@use '../tools' as *; // Use site-specific tools

.dk-facet-search {
  $bg-gray: $button-gray;
  $bg-ash: #fbfbfb;

  .fs-facets {
    margin-top: 1rem;
  }

  .fs-link-facets {
    $ipt-glyph-size: rem(16);

    & > * {
      display: block;
      border: solid $button-outline;
      border-width: 0 1px 1px;
      background-color: $bg-ash;
      line-height: 1.2;
      font-size: 12px;
      font-weight: $semibold;

      &:first-child {
        border-width: 1px;
      }
    }

    & > a, span {
      position: relative;
      min-height: rem(35);
      padding: 0.7rem 1rem 0.7rem rem(36);
      @extend %unselectable;

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: rem(10);
      }

      &::before {
        width: $ipt-glyph-size;
        height: $ipt-glyph-size;
        border: solid 1px $button-outline;
        border-radius: 50%;
        background-color: $white;
        transform: translateY(-50%);
      }
    }

    a {
      &, &:visited, &:hover {
        color: $text-general;
        text-decoration: none;
      }

      &:hover {
        background-color: color.adjust($bg-ash, $lightness: -5%);
      }
    }

    span {
      display: block;

      &::after {
        transform: translateX(50%) translateY(-50%);
        border-radius: 50%;
        background-color: $default-accent-color;
        width: math.div($ipt-glyph-size, 2);
        height: math.div($ipt-glyph-size, 2);
      }
    }

    .tic-tacs {
      @extend %clearfix;
      position: relative;
      margin: 0;
      padding: 0 0.5rem 0.5rem;
      font-size: inherit;

      &:first-child {
        padding-top: 0.5rem;
      }

      @include breakpoint($min: $tablet-min, $max: $variable-layout-max) {
        padding: rem(3);
      }

      li {
        display: block;
        float: left;
        margin: rem(5) rem(3);

        a {
          display: block;
          border: solid 1px $divider-stroke;
          border-radius: rem(5);
          padding: rem(3) rem(5);
          background-color: $white;
          line-height: 1;
          font-weight: $semibold;
          cursor: pointer;
          @extend %unselectable;

          &:hover {
            background-color: color.adjust($default-accent-color, $lightness: 50%);
            color: $text-dark;
          }
        }
      }

      input:checked + label {
        border-color: $text-green;
        background-color: $text-green;
        color: $white;
      }
    }
  }

  .fs-body {
    font-family: $open-sans;

    @include row;

    @include desktop {
      position: relative;

      .fs-facets {
        @include span-columns(3);
      }

      .fs-results {
        @include span-columns(9);
      }

      .fs-facet-slide {
        position: static;

        &.stuck {
          position: fixed;
        }

        &.bottom {
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
        }
      }
    }

    @include print {
      ol > li {
        padding: rem(10) 0;
      }
    }
  }
}

.result-items {
  &.funding-opportunities .bottom {
    &::before, &::after {
      content: normal;
    }
  }

  &.meetings {
    h4 {
      margin-top: 1rem;
      font-family: $open-sans;
    }
  }
}
