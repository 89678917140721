@use '../tools' as *; // Use site-specific tools

.grid > .dk-flex-table {
  margin: 0;
}

.hero-unit {
  color: $white;
  text-shadow: rem(1) rem(1) $dark-gray;

  .inner {
    background: #527b8b url(../images/obesity-bg-hero.jpg) no-repeat 50%;
    background-size: cover;

    &, * {
      @extend %remove-child-bookend-margins;
    }
  }

  .description {
    background-color: rgba($white, 0.15);
  }

  h1, .description {
    margin: 0;
    padding: 1rem rem(20);
  }

  p {
    font-size: rem(18);
  }

  .button-ghost {
    padding: 1rem 2rem;
  }

  @include desktop {
    .description {
      display: table;

      & > * {
        display: table-cell;
        vertical-align: middle;
      }

      .left {
        padding-right: 2rem;
      }

      .right {
        min-width: rem(215);
        text-align: right;
      }
    }

    h1, .description {
      padding: 1.6rem 4rem;
    }
  }

  @include mobile {
    .description {
      .right {
        margin-top: 1rem;
        margin-bottom: 0.3rem;
        text-align: center;
      }

      p {
        font-size: rem(16);
      }
    }
  }

  @include print {
    color: $dark-gray;
    text-shadow: none;

    h1, .description {
      padding-left: 0;
      padding-right: 0;
    }

    .description {
      .right { display: none; }
    }
  }
}

.home-gap {
  height: 2.5rem;
  margin: 0;
}
