@use 'sass:color';
@use 'sass:math';
@use '../tools' as *; // Use site-specific tools

.site-header,
.sr-refine {
  .dk-site-mobile-toolbar .mobile-site-search[aria-expanded='true'] {
    background-color: $bg-gray;
  }

  .dk-site-search {
    form {
      position: relative;
    }

    input[type='text'] {
      height: rem(35);
      width: 100%;
      padding: 2px rem(35) 1px rem(12);
      border: 1px solid #bfbfbf;
      border-radius: rem(5);
      background: transparent;
      font-size: 14.4px;
      line-height: 1.7;
      appearance: none;

      & + button {
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        width: rem(35);
        height: rem(35);
        border: solid transparent;
        border-width: 1px 1px 1px 0;
        padding: 0;
        text-indent: 5000rem;
        background: transparent;
        appearance: none;
        font-size: 0;
        color: #818181;
        cursor: pointer;

        &:hover {
          background-color: $bg-gray;
          border-color: #bfbfbf;
          border-radius: 0 rem(5) rem(5) 0;
        }

        &::before {
          @extend %icon-base;
          content: $i-search;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.6rem;
          text-indent: 0;
          overflow: hidden;
        }
      }
    }

    @include desktop {
      width: rem(275);
    }

    @include mobile {
      display: none;
      width: 100%;
      border-top: 1px solid $divider-stroke;
      padding: 0.7rem 1rem;
    }

    @include print { display: none; }
  }
}

.sr-status {
  margin: 2rem 0;

  .sr-scope {
    &::before {
      content: '‘';
    }
    &::after {
      content: '’';
    }
  }

  ol {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: inherit;

    li {
      display: inline;
      margin: 0;
      padding: 0;
      font: {
        weight: bold;
        size: 1rem;
      }

      &:not(:last-of-type)::after {
        content: ':';
      }

      &:last-of-type::after {
        content: '.';
        font-weight: normal;
      }
    }
  }
}

.sr-suggestion {
  margin: 1rem 0;
  font-weight: bold;

  button {
    font: inherit {
      style: italic;
    }
    display: inline;
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0 rem(2);
    line-height: 1;
    color: $link-standard;
    cursor: pointer;

    &:hover,
    &:active {
      color: $link-hover;
      text-decoration: underline;
    }
  }
}

.sr-results {
  margin: 2rem 0;
  list-style: none;

  li {
    margin: 0;
  }

  .sr-url {
    display: block;
    margin: 0.2rem 0;
    color: $green;
    font-family: $open-sans;

    @include mobile {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  p {
    margin-top: 0;
    line-height: 1.7;
  }
}

main .sr-results h2 {
  @extend %size-h3;
  line-height: 1.2;
  margin-bottom: 0;

  .file-size {
    font-size: 1rem;
    font-weight: normal;
  }

  i[class*="file-"] {
    font-size: 1rem;
  }
}

.sr-refine {
  background: $bg-ash; //#f8f8f8;
  border: 1px solid $button-outline;
  margin: 2rem 0;
  padding: 1.5rem;

  @include desktop {
    max-width: rem(432);
  }

  @include print { display: none; }

  label {
    display: inline-block;
    margin: 0 1rem 1rem 0;

    &.disabled {
      color: color.mix(#fff, $text-general, 20%);
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    }
  }

  .dk-site-search {
    position: relative;
    width: 100%;

    @include mobile {
      display: block;
      border-top: none;
      padding: 0;
    }

    input[type='text'] {
      background-color: $white;
    }
  }
}
